import './App.css'
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import Sidebarstaff from './components/staffadmin/sidebar/Sidebarstaff';
import Navbar from './components/Navbar';
import Navbarstaff from './components/staffadmin/sidebar/Navbarstaff';
import Dashboard from './components/pages/Dashboard';
import Role from './components/pages/Role';
import Addrole from './components/pages/Addrole';
import User from './components/pages/User';
import Course from './components/pages/Course';
import Student from './components/pages/Student';
import Studyingstudents from './components/pages/Studyingstudent';
import Completed from './components/pages/Completed';

import Staff from './components/pages/Staff';
import Addstaff from './components/pages/Addstaff';
import Updatestaff from './components/pages/Updatestaff';
import Attendance from './components/pages/Attendance';
import Assettype from './components/pages/Assettype';
import Assetlist from './components/pages/Assetlist';
import Expensetype from './components/pages/Expensetype';
import Expenselist from './components/pages/Expenselist';
import Incometype from './components/pages/Incometype';
import Incomelist from './components/pages/Incomelist';
import Designation from './components/pages/Designation';
import Login from './components/login/Login';
import Invoice from './components/pages/Invoice';
import Leave from './components/pages/Leave';

// Sraff admin

import Task from '././components/staffadmin/pages/Task';
import Stafflogin from './components/staffadmin/stafflogin/Stafflogin';
import Profile from './components/staffadmin/pages/Profile';
import Attendancestaff from './components/staffadmin/pages/Attendancestaff';
import Leavestaff from './components/staffadmin/pages/Leavestaff';
import Schedule from './components/pages/schedule';
import Addinvoice from './components/pages/Addinvoice';
import Print from './components/pages/Print';
import Footer from './components/footer/Footer';
import ProtectedRoute from './components/pages/Helper';
import Editrole from './components/pages/Editrole';
import Branch from './components/pages/Branch';
import Addstudent from './components/pages/Addstudent';
import Updatestudents from './components/pages/Updatestudents';
import Updateinvoice from './components/pages/Updateinvoice';
import StudentInfo from './components/pages/StudentInfo';
import Followups from './components/pages/Followups';
import Walkin from './components/pages/Walkin';

import Studentdairy from './components/pages/Studentdairy';
import DailyReport from './components/pages/DailyReport';
import AttendanceReport from './components/pages/AttendanceReport';
import EnquiryReport from './components/pages/EnquiryReport';
import Leadhistroy from './components/pages/Leadhistroy';
import Registration from './components/pages/Registration';




const App = () => {
  return (
    <Router>
      <Routes>
        {/* user admin */}
        <Route path="/dashboard" element={<ProtectedRoute><Sidebar><Dashboard /></Sidebar></ProtectedRoute>} />
        <Route path="/role" element={<ProtectedRoute><Sidebar><Role /></Sidebar></ProtectedRoute>} />
        <Route path="/addrole" element={<Sidebar><Addrole /></Sidebar>} />
        <Route path="/user" element={<Sidebar><User /> <Footer /></Sidebar>} />
        <Route path="/branch" element={<Sidebar><Branch /><Footer /></Sidebar>} />
        <Route path="/course" element={<Sidebar><Course /><Footer /></Sidebar>} />
        <Route path="/student" element={<Sidebar><Student /><Footer /></Sidebar>} />
        <Route path="/studyingstudents" element={<Sidebar><Studyingstudents /><Footer /></Sidebar>} />
        <Route path="/completed" element={<Sidebar><Completed /><Footer /></Sidebar>} />
        <Route path="/addstudent" element={<Sidebar><Addstudent /><Footer /></Sidebar>} />
        <Route path="/updatestudents/:id" element={<Sidebar><Updatestudents /> <Footer /></Sidebar>} />
        <Route path="/staff" element={<Sidebar><Staff /><Footer /></Sidebar>} />
        <Route path="/addstaff" element={<Sidebar><Addstaff /></Sidebar>} />
        <Route path="/Studentdairy" element={<Sidebar><Studentdairy /></Sidebar>} />
        <Route path="/Studentinfo/:id" element={<Sidebar>< StudentInfo /></Sidebar>} />
        <Route path="/Followups" element={<Sidebar>< Followups /></Sidebar>} />
        <Route path="/Walkin" element={<Sidebar>< Walkin /></Sidebar>} />
        <Route path="/Registration/:id" element={<Sidebar>< Registration /></Sidebar>} />
        <Route path="/updatestaff/:id" element={<Sidebar><Updatestaff /></Sidebar>} />
        <Route path="/Leadhistroy/:_id" element={<Sidebar>< Leadhistroy/></Sidebar>} />
        <Route path="/attendance" element={<Sidebar><Attendance /><Footer /></Sidebar>} />
        <Route path="/assettype" element={<Sidebar><Assettype /><Footer /></Sidebar>} />
        <Route path="/assetlist" element={<Sidebar><Assetlist /><Footer /></Sidebar>} />
        <Route path="/expensetype" element={<Sidebar><Expensetype /><Footer /></Sidebar>} />
        <Route path="/expenselist" element={<Sidebar><Expenselist /><Footer /></Sidebar>} />
        <Route path="/incometype" element={<Sidebar><Incometype /><Footer /></Sidebar>} />
        <Route path="/incomelist" element={<Sidebar><Incomelist /><Footer /></Sidebar>} />
        <Route path="/designation" element={<Sidebar><Designation /><Footer /></Sidebar>} />
        <Route path="/invoice" element={<Sidebar><Invoice /><Footer /></Sidebar>} />
        <Route path="/dailyreport" element={<Sidebar>  <DailyReport /><Footer /></Sidebar>} />
        <Route path="/AttendanceReport" element={<Sidebar>  <AttendanceReport/> <Footer /></Sidebar>} />
        <Route path="/Enquiryreport" element={<Sidebar>  <EnquiryReport /><Footer /></Sidebar>} />
        <Route path="/leave" element={<Sidebar><Leave /><Footer /></Sidebar>} />
        <Route path="/schedule" element={<Sidebar><Schedule /><Footer /></Sidebar>} />
        <Route path="/Addinvoice" element={<Sidebar><Addinvoice /></Sidebar>} />
        <Route path="/updateinvoice/:id" element={<Sidebar><Updateinvoice /></Sidebar>} />
        <Route path="/Print/:id" element={<Print />} />
        {/* <Route path="/admin" element={<Login />} /> */}
        <Route path="/" element={<Login />} />
        <Route path="/edit" element={<Sidebar><Editrole /></Sidebar>} />

        {/* staffadmin */}
        <Route path="/leavestaff" element={<ProtectedRoute> <Sidebarstaff><Leavestaff /><Footer /></Sidebarstaff></ProtectedRoute>} />
        <Route path="/task" element={<ProtectedRoute><Sidebarstaff><Task /></Sidebarstaff><Footer /></ProtectedRoute>} />
        <Route path="/profile/:id" element={<ProtectedRoute> <Sidebarstaff><Profile /><Footer /></Sidebarstaff></ProtectedRoute>} />
        <Route path="/attendancestaff" element={<ProtectedRoute> <Sidebarstaff><Attendancestaff /><Footer /></Sidebarstaff></ProtectedRoute>} />
        {/* <Route path="/" element={<Stafflogin />} /> */}
        <Route path="/stafflogin" element={<Stafflogin />} />
      </Routes>

    </Router>

  );
}

export default App;
