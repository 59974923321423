import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiUrl from '../../environment/Api';
import '../../assets/css/Addstudent.css';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
const steps = ['Step 1: Personal Details', 'Step 2: Address Details', 'Step 3: Confirmation'];


function Registration() {
  const [responseDatas, setResponseDatas] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [responseOldData, setResponseOldData] = useState([]);
  const [branch, setbranch] = useState([]);


  const navigate = useNavigate();
  const handlecancel = () => {
    navigate("/student");
  };

  const { id } = useParams()



  useEffect(() => {
    fetchData();
  }, []);
  console.log("id is there" + id)
  const [courseData, getCourseData] = useState([])
  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + `/leads/${id}`);
      const responsecourse = await axios.get(apiUrl + `/course`);
      getCourseData(responsecourse.data)
      console.log("Response from server:", response.data);
      if (response && response.data) {
        const data = response.data;
        const filteredData = data.filter(item => item.status !== 'Converted');
        setResponseOldData(filteredData[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchDatas();
    fetchDatased();

  }, []);


  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  console.log("data is there", responseData)
  // const [addData, setAddData] = useState({



  //   branch_id: '',
  //   selectcourse: '',
  //   student_name: '',
  //   father_name: '',
  //   timings: '',
  //   admission_date: formatDate(new Date()),
  //   dateofbirth: '',
  //   qualification: '',
  //   selectcourse: '',
  //   assign_staff: '',
  //   Source_type: '',
  //   notes: '',
  //   status: '',
  //   total_fees: '',
  //   paid: '',
  //   balance: '',
  //   student_type: '',
  //   source_type: '',
  //   location: '',
  //   callback_date: '',
  //   converted_date: '',
  //   completed_date: '',
  //   completed: '',
  //   // converted_status: '',
  //   roll_no: '',
  //   photo_upload: '',
  //   id_proof: '',
  // });
  const [addData, setAddData] = useState({
    admission_date: '',
    qualification: '',
    duraction: '',
    batch_timings: '',
    fees: '',
    discount: '',
    student_type: '',
    payment_type: '',
    father_name: '',

    roll_no: '',
    photo_upload: null, // File input
    id_proof: null, // File input
    leads_id: '',
    //new field
    date_of_birth: "",
    trainer: "",


    mobile_no: "",
    email: "",
    Enquiry_date: "",
    Prefered_course: ""
  });

  // Use useEffect to set state when responseData is available
  useEffect(() => {
    if (responseOldData) {
      setAddData(prevState => ({
        ...prevState,
        branch_id: responseOldData.branch_id,
        student_name: responseOldData.student_name,
        Source_type: responseOldData.Source_type,
        assign_staff: responseOldData.assign_staff,
        mobile_no: responseOldData.mobile_no,
        email: responseOldData.email,
        Enquiry_date: responseOldData.Enquiry_date,
        Prefered_course: responseOldData.Prefered_course,
        leads_id: responseOldData._id
        // You can map other fields from responseData as needed
      }));
      console.log("Data is there", responseData);
    }
  }, [responseOldData]); // Trigger useEffect when responseData changes

  console.log("llolllll", addData)
  const handlesubmit = async (e) => {
    e.preventDefault();

    // const formData = {
    //   branch_id: addData.branch_id,
    //   student_name: addData.student_name,
    //   father_name: addData.father_name,
    //   qualification: addData.qualification,
    //   selectcourse: addData.selectcourse,
    //   timings: addData.timings,
    //   admission_date: addData.date,
    //   dateofbirth: addData.dateofbirth,
    //   assign_staff: addData.mobile_no,
    //   Source_type: addData.Source_type,
    //   notes: addData.notes,
    //   location: addData.location,
    //   student_type: addData.student_type,
    //   source_type: addData.source_type,
    //   status: addData.status,
    //   callback_date: addData.callback_date,
    //   converted_date: addData.converted_date,
    //   completed_date: addData.completed_date,
    //   completed: addData.completed,
    //   // converted_status: addData.converted_status,
    //   totalfees: '',
    //   paid: '',
    //   balance: '',
    //   roll_no: addData.roll_no,
    //   photo_upload: addData.photo_upload,
    //   id_proof: addData.id_proof,

    // }
    const formData = new FormData();
    formData.append('admission_date', addData.admission_date);
    formData.append('qualification', addData.qualification);
    formData.append('duraction', addData.duraction);
    formData.append('batch_timings', addData.batch_timings);
    formData.append('fees', addData.fees);
    formData.append('discount', addData.discount);
    formData.append('student_type', addData.student_type);
    formData.append('payment_type', addData.payment_type);
    formData.append('roll_no', addData.roll_no);
    formData.append('leads_id', id.toString().trim());
    formData.append('father_name', addData.father_name);
    formData.append('date_of_birth', addData.date_of_birth);
    formData.append('trainer', addData.trainer);

    if (addData.photo_upload) formData.append('photo_upload', addData.photo_upload);
    if (addData.id_proof) formData.append('id_proof', addData.id_proof);



    const leadsdata = {

      branch_id: addData.branch_id,
      student_name: addData.student_name,
      Source_type: addData.Source_type,
      assign_staff: addData.assign_staff,
      mobile_no: addData.mobile_no,
      email: addData.email,
      Enquiry_date: addData.Enquiry_date,
      Prefered_course: addData.Prefered_course

    }

    console.log("formdata", leadsdata)

    try {
      const response = await axios.post(`${apiUrl}/student`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const responseleads = await axios.put(`${apiUrl}/leads/${addData.leads_id}`, leadsdata);
      console.log("Updated:", response, responseleads);


      toast.success("added successfully!");

      setAddData({
        // branch_id: '',
        // selectcourse: '',
        // student_name: '',
        // father_name: '',
        // timings: '',
        // admission_date: formatDate(new Date()),
        // dateofbirth: '',
        // qualification: '',
        // assign_staff: '',
        // Source_type: '',
        // notes: '',
        // status: '',
        // total_fees: '',
        // paid: '',
        // balance: '',
        // student_type: '',
        // source_type: '',
        // location: '',
        // callback_date: '',
        // // converted_status: '',
        // completed: '',
        // roll_no: '',
        // photo_upload: null,
        // id_proof: null,

        admission_date: '',
        qualification: '',
        duraction: '',
        batch_timings: '',
        fees: '',
        discount: '',
        student_type: '',
        father_name: "",
        payment_type: '',
        date_of_birth: "",
        roll_no: '',
        photo_upload: null, // File input
        id_proof: null, // File input
        leads_id: "",
        trainer: ""
      });
      fetchData();
      navigate("/Studyingstudents")

    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };





  const fetchDatas = async () => {
    try {
      const response = await axios.get(apiUrl + "/leads");


      if (response && response.data) {
        const data = response.data;

        setResponseDatas(data);
        console.log("Response from servers63s3s:", data);
      } else {
        console.error("No data received from server.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };


  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    console.log("the select status", selectedStatus)
    setAddData({ ...addData, status: selectedStatus });

    if (selectedStatus === "Callback") {
      setShowCallbackDate(true);
    }
    else if (selectedStatus === "Converted") {
      setShowCallbackDate(true);
    } else {
      setShowCallbackDate(false);
      setAddData(prevData => ({
        ...prevData,
        callback_date: '',
        converted_date: '',
      }));
    }
  };


  const [studentId, setSutudentId] = useState([])
  const [stafftId, setStaffId] = useState([])
  const fetchDatased = async () => {
    try {
      const response = await axios.get(apiUrl + "/Branch");
      const responsestudent = await axios.get(apiUrl + "/student");
      const responsestaff = await axios.get(apiUrl + "/staff");
      console.log("Response from server:", response.data);
      setbranch(response.data);
      setSutudentId(responsestudent.data)
      setStaffId(responsestaff.data)
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };


  const [showCallbackDate, setShowCallbackDate] = useState(false);


  const [referaltype, setreferaltype] = useState('');


  const handlereferal = (e) => {
    console.log(e.target.value)

    setreferaltype(e.target.value);
  }


  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  // Helper function to format ISO date to YYYY-MM-DD
  const formatDatefitching = (dateString) => {
    if (!dateString) return "";
    return dateString.split("T")[0];  // This will return only the date part (yyyy-mm-dd)
  };

  // Function to handle course fetching
  function handelecoursefetch(e) {
    const selectedCourseId = e.target.value;

    console.log(selectedCourseId, "Selected Course ID");

    // Use find() to get the matching course object
    const selectedCourse = courseData.find(item => item._id == selectedCourseId);

    console.log('Selected Course:', selectedCourse);

    if (selectedCourse) {
      // Update addData state with selected course details
      setAddData({
        ...addData,  // Retain other addData values
        Prefered_course: selectedCourse.course_name,  // Set the course name
        fees: selectedCourse.fees,                    // Set the fees
        discount: selectedCourse.discount,            // Set the discount
        duration: selectedCourse.duration             // Set the duration
      });
    } else {
      console.error('Course not found');
    }
  }
  return (

    <div className='style' style={{ marginTop: '-30px' }}>

      <div className='insermodal'>

        <div >

          <h3>Registration</h3>
          {/* <form onSubmit={handlesubmit}> */}
          {/* Other input fields */}
          <div className='row mt-5 '>
            {activeStep === 0 && (
              <div className='col-lg-12 ' style={{ marginTop: '-25px' }}>
                <div className='card  p-2 pb-0'>
                  <h4 className='mt-1 mb-3'> step 1</h4>
                  <div className="form-group">
                    <div className='row'>
                      <div className='col-lg-6'>

                        <div className="floating-label mt-1">
                          <label className="form-label">
                            <b></b>
                          </label>
                          <select
                            className="form-select"
                            placeholder=''
                            value={addData.branch_id}
                            onChange={(e) => setAddData({ ...addData, branch_id: e.target.value })}

                            required
                          >
                            <option value="">Select Branch </option>
                            {branch.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item.branch_name}
                              </option>
                            ))}
                          </select>
                          <label className={addData.branch_id ? "filled" : ""}>

                          </label>
                        </div>

                      </div>

                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <input className="form-control" type="text" placeholder='' value={addData.father_name} onChange={(e) => setAddData({ ...addData, father_name: e.target.value })} />
                          <label className={addData.father_name ? "filled" : ""}>
                            Father Name
                          </label>
                        </div>

                      </div>

                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData.student_name} // Controlled input
                            onChange={(e) => setAddData({ ...addData, student_name: e.target.value })} // Handle onChange event
                            required
                          />
                          {/* Conditionally apply the "filled" class to the label if student_name exists */}
                          <label className={addData.student_name ? "filled" : ""}>
                            Student Name
                          </label>

                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <input className="form-control" type="text" placeholder='' value={addData.qualification} onChange={(e) => setAddData({ ...addData, qualification: e.target.value })} />
                          <label className={addData.qualification ? "filled" : ""}>
                            Qualification
                          </label>
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="number"
                            maxLength={10}
                            value={addData.mobile_no}
                            onChange={(e) => setAddData({ ...addData, mobile_no: e.target.value })} // Store the file object
                            required
                          />
                          <label className={addData.mobile_no ? "filled" : ""}>
                            Mobile No
                          </label>
                        </div>
                      </div>



                      <div className='col-lg-6'>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="date"
                            value={addData.date_of_birth || ""}
                            onChange={(e) => setAddData({ ...addData, date_of_birth: e.target.value })} // Store the file object
                            required
                          />
                          <label className={addData.date_of_birth ? "filled" : ""}>
                            date_of_birth
                          </label>
                        </div>
                      </div>


                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="email"
                            value={addData.email}
                            onChange={(e) => setAddData({ ...addData, email: e.target.value })} // Store the file object
                            required
                          />
                          <label className={addData.email ? "filled" : ""}>
                            Email
                          </label>
                        </div>

                      </div>



                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <select
                            className="form-control"
                            onChange={(e) => setAddData({ ...addData, student_type: e.target.value })} required>
                            <option value=""> Select Student Type</option>
                            <option value="Studying">Studying</option>
                            <option value="Working">Working</option>
                            <option value="Jobseeking">Jobseeking</option>

                          </select>
                          <label className={addData.student_type ? "filled" : ""}>
                          </label>
                        </div>
                      </div>
                      {/* 
                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <select
                            className="form-control"
                            onChange={(e) => setAddData({ ...addData, assign_staff: e.target.value })}
                            disabled
                            value={addData.assign_staff || ''} // Ensure value is linked to state and handle empty value
                          >
                            <option value=''>Select staff</option>
                            {stafftId.map((data, index) => (
                              <option key={index} value={data._id}>
                                {data.name} - {data.empid}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>



                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <input className="form-control" type="email" placeholder='' disabled value={addData.Source_type} onChange={(e) => setAddData({ ...addData, Source_type: e.target.value })} required />
                          <label className={addData.Source_type ? "filled" : ""}>
                            Source Type
                          </label>
                        </div>
                      </div> */}
                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="file"
                            onChange={(e) => setAddData({ ...addData, photo_upload: e.target.files[0] })} // Store the file object
                            required
                          />
                          <label className={addData.photo_upload ? "filled" : ""}>
                            Photo Upload
                          </label>
                        </div>
                      </div>
                      <div className='col-lg-6'>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="file"
                            onChange={(e) => setAddData({ ...addData, id_proof: e.target.files[0] })} // Store the file object
                            required
                          />
                          <label className={addData.id_proof ? "filled" : ""}>
                            ID Proof
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* ////////////////////////////////////////////////// */}
            {activeStep === 1 && (

              <div className='col-lg-12' style={{ marginTop: '-25px' }}>

                <div className='card p-2 pb-0'>
                  <div className="form-group">
                    <div className='row'>
                      <h4 className='mt-2 mb-4'> step 2</h4>


                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <input className="form-control" type="text" placeholder='' value={addData.roll_no} onChange={(e) => setAddData({ ...addData, roll_no: e.target.value })} />
                          <label className={addData.roll_no ? "filled" : ""}>
                            Roll Number</label>
                        </div>

                      </div>
                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <input className="form-control" type="text" disabled placeholder='' value={addData.fees} onChange={(e) => setAddData({ ...addData, fees: e.target.value })} />
                          <label className={addData.fees ? "filled" : ""}>
                            fees
                          </label>
                        </div>
                      </div>

                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <select
                            className="form-select"
                            value={addData.Prefered_course || ""}
                            onChange={(e) => handelecoursefetch(e)}
                            required
                          >
                            <option disabled value="">Select Course</option> {/* Ensure value is empty string for default */}
                            {courseData.map((item, index) => (
                              <option key={index} value={item._id}>{item.course_name}</option>
                            ))}
                          </select>
                          <label className={addData.Prefered_course ? "filled" : ""}>
                            Preferred Course
                          </label>
                        </div>
                      </div>


                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <input className="form-control" type="text" disabled placeholder='' value={addData.discount} onChange={(e) => setAddData({ ...addData, discount: e.target.value })} />
                          <label className={addData.discount ? "filled" : ""}>
                            Discount
                          </label>
                        </div>
                      </div>
                      <div className='col-lg-6'>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="date"
                            value={addData.Enquiry_date ? formatDatefitching(addData.Enquiry_date) : ""}  // Format the date correctly
                            onChange={(e) => setAddData({ ...addData, Enquiry_date: e.target.value })}  // Update the date as entered by the user
                            required
                          />
                          <label className={addData.Enquiry_date ? "filled" : ""}>
                            Enquiry date
                          </label>
                        </div>
                      </div>






                      <div className='col-lg-6'>

                        <div className="floating-label">
                          {/* <select
    className="form-control"
    onChange={(e) => setAddData({ ...addData, duraction: e.target.value })} required>
    <option value=""> Duration</option>

    <option value="1Month">1Month</option>
    <option value="3Month">3month</option>
    <option value="6month">6Month</option>
    <option value="8Month">8Month</option>
    <option value="1year" >1Year</option>

  </select>
  <label className={addData.duraction ? "filled" : ""}>
    Duration
  </label> */}

                          <select
                            className="form-select"
                            value={addData.duration || ""}
                            // onChange={(e) => handelecoursefetcch(e)}
                            onChange={(e) => setAddData({ ...addData, duration: e.target.value })}
                            disabled
                            required
                          >
                            <option disabled value=""></option>  {/* Ensure value is empty string for default */}
                            {courseData.map((item, index) => (
                              <option value={item.duration}>{item.duration}</option>


                            ))}
                          </select>
                          <label className={addData.duration ? "filled" : ""}>
                            Duration                          </label>
                        </div>
                      </div>

                      <div className='col-lg-6'>

                        <div className="floating-label">
                          <input className="form-control" type="date" placeholder='' value={addData.admission_date} onChange={(e) => setAddData({ ...addData, admission_date: e.target.value })} required />
                          <label className={addData.admission_date ? "filled" : ""}>
                            Admission Date
                          </label>
                        </div>
                      </div>


                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <select
                            className="form-control"
                            onChange={(e) => setAddData({ ...addData, batch_timings: e.target.value })} required>
                            <option value="">Batch Timing</option>
                            <option value="10.00am-11.30am">10.00am - 11.30am</option>
                            <option value="11.30am-1.00pm">11.30am - 01.00pm</option>
                            <option value="04.30pm-06.00pm">04.30pm - 06.00pm</option>
                            <option value="06.00pm-07.30pm">06.00pm - 07.30pm</option>
                          </select>

                          <label className={addData.batch_timings ? "filled" : ""}>

                          </label>
                        </div>
                      </div>

                      <div className='col-lg-6'>
                        {/* <input className="form-control" type="email" placeholder='' value={addData.Source_type} onChange={(e) => setAddData({ ...addData, Source_type: e.target.value })} required />
                          <label className={addData.Source_type ? "filled" : ""}>
                            Source Type
                          </label> */}
                        {console.log(addData.Source_type, "addData.Source_type ---")}
                        <select
                          className="form-control"
                          value={addData.Source_type || ""}  // Dynamically link to state
                          onChange={(e) => setAddData({ ...addData, Source_type: e.target.value })}  // Update state on change
                        >
                          <option value="" disabled>Select Source Type</option>  {/* Placeholder option */}
                          <option value="Google Ad">Google Ad</option>
                          <option value="Linkedln">LinkedIn</option>
                          <option value="Facebook">Facebook</option>
                          <option value="Naukri">Naukri</option>
                          <option value="Referral">Referral</option>
                          <option value="Online">Online</option>  {/* Corrected spelling and options */}
                        </select>

                      </div>
                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <select className="form-select" value={addData.payment_type} onChange={(e) => setAddData({ ...addData, payment_type: e.target.value })} required>
                            <option value="">Payment Type</option>
                            <option value="G-pay">G-pay</option>
                            <option value="Phone pay">Phone pay</option>
                            <option value="Cash">Cash</option>
                            <option value="Depite card">Depite card</option>

                          </select>
                          <label className={addData.payment_type ? "filled" : ""}>

                          </label>
                        </div>

                      </div>
                      <div className='col-lg-6'>
                        <div className="floating-label">
                          <select
                            className="form-control"
                            value={addData.trainer || ""}  // Ensure it starts with an empty string if not set
                            onChange={(e) => setAddData({ ...addData, trainer: e.target.value })}
                          >
                            <option value="" disabled>Select Trainer</option>  {/* Ensure value is empty for disabled option */}
                            {stafftId.map((data, index) => (
                              <option key={index} value={data.empid}>
                                {data.name} - {data.empid}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>)}
          </div>

          {/* <div className="row mt-5 d-flex justify-content-end mb-5">
            <div className="col-lg-6 text-center d-flex justify-content-start">
              <button className='Cancelbut' variant="secondary" onClick={handlecancel}>Cancel</button>
            </div>
            <div className="col-lg-6 text-center d-flex justify-content-end">
              <button className='Submitbut' type="submit" variant="primary" onClick={handlesubmit} >Submit</button>
            </div>
          </div> */}
          {/* </form> */}
        </div>

      </div>


      <div style={{ marginTop: '20px' }}>
        {activeStep === steps.length - 1 ? (
          <Modal show={true} >

            <div className="row mt-5  mb-5">
              <div className='text-center mt-3 mb-3'>
                <input type="checkbox" id="agree" required />
                <label htmlFor="agree " className='mx-2'>I Agree to all conditions</label>
              </div>

              <div className="col-lg-6 text-center d-flex justify-content-end">
                <button className='Cancelbut ' variant="secondary" onClick={handlecancel}>Cancel</button>
              </div>
              <div className="col-lg-6 text-center d-flex justify-content-start">
                <button className='Submitbut' type="submit" variant="primary" onClick={handlesubmit} >Submit</button>
              </div>
              <div className='text-center mt-3'>
                <Button
                  variant="contained"
                  style={{ backgroundColor: 'blue', alignItems: "center", marginRight: '10px', color: "white", width: "20%" }} // Inline style for background color
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >

                  Back
                </Button>
              </div>
            </div>
          </Modal>

        ) : (
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: 'gray', marginRight: '10px', color: "white" }} // Inline style for background color
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>

            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          </>
        )}
      </div>

    </div >
  )
}

export default Registration;
