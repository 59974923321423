import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import apiUrl from "../../environment/Api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Staff() {
  const [responseData, setResponseData] = useState([]);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const [role_id, setroleid] = useState('');
  const [previllages, setprevillages] = useState({})


  const navigate = useNavigate()
  const handleNewButtonClick = () => {
    if (previllages.add || previllages.all) {
      navigate('/addstaff')
    } else {
      toast.error("You don't have the permission to add");
    }

  }

  const handleUpdate = (_id, data) => {
    if (previllages.edit || previllages.all) {

    navigate(`/updatestaff/${_id}`)
  }else{
    toast.error("You don't have the permission to update");
  }

}





  const [addData, setAddData] = useState({


    branch_id: "",
    empid: "",
    name: "",
    fathername: "",
    mobile: "",
    dateofbirth: "",
    interview_date: "",
    join_date: "",
    address: "",
    salary: "",
    designation: "",
    qualification: "",
    notes: "",
    timings: "",
    status: "",
    relive_date: "",
    documents: "",

  });

  const [updateData, setUpdateData] = useState({


    branch_id: "",
    empid: "",
    name: "",
    fathername: "",
    mobile: "",
    dateofbirth: "",
    interview_date: "",
    join_date: "",
    address: "",
    salary: "",
    designation: "",
    qualification: "",
    notes: "",
    timings: "",
    status: "",
    relive_date: "",
    documents: "",


  });

  useEffect(() => {
    fetchData();
    gettoken();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + "/staff");
      console.log("Response from server:", response.data);
      setResponseData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };


  const gettoken = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(apiUrl + "/get_detail", { "token": token });
      console.log("token detail from server:", response.data);


      setroleid(response.data.detail.rolename);

      localStorage.setItem('role_id', response.data.detail.rolename);

      const id = response.data.detail.rolename

      if (id) {
        try {
          const response = await axios.get(apiUrl + `/role_2/${id}`);


          const modules = response.data.modules;

          const filtered_access = modules.filter(item => item.name == "Branch")


          setprevillages(filtered_access[0].actions);



        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  }







  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {

        branch_id: addData.branch_id,
        empid: addData.empid,
        name: addData.name,
        fathername: addData.fathername,
        mobile: addData.mobile,
        dateofbirth: addData.dateofbirth,
        interview_date: addData.interview_date,
        join_date: addData.join_date,
        address: addData.address,
        salary: addData.salary,
        designation: addData.designation,
        qualification: addData.qualification,
        notes: addData.notes,
        timings: addData.timings,
        status: addData.status,
        relive_date: addData.relive_date,
        documents: addData.documents,


      }

      const response = await axios.post(apiUrl + "/staff", formData);
      console.log("Updated:", response);

      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };




  const [updateid, setUpdateId] = useState("");


  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {



        branch_id: updateData.branch_id,
        empid: updateData.empid,
        name: updateData.name,
        fathername: updateData.fathername,
        mobile: updateData.mobile,
        dateofbirth: updateData.dateofbirth,
        interview_date: updateData.interview_date,
        join_date: updateData.join_date,
        address: updateData.address,
        salary: updateData.salary,
        designation: updateData.designation,
        qualification: updateData.qualification,
        notes: updateData.notes,
        timings: updateData.timings,
        status: updateData.status,
        relive_date: updateData.relive_date,
        documents: updateData.documents,
      };

      console.log("the update submit data", formData);
      const response = await axios.put(apiUrl + `/staff/${updateid}`, formData);
      console.log("response for update", response);
      setUpdateShowModal(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(apiUrl + `/staff/${getdeleteid}`);
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };




  const handleCloseModal = () => {
    setDeleteShowModals(false);
  }

  const [getdeleteid, setdeleteid] = useState('')
  const [getdeletename, setdeletename] = useState('')
  const handleOpenModal = (id, name) => {
    if(previllages.all || previllages.delete){
    console.log(" the id", id)
    console.log(" the name", name)
    setdeleteid(id);
    setdeletename(name);
    setDeleteShowModals(true);
  }else{
    toast.error("You don't have the permission to delete");
  }
}

  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Search
  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };
  // the pagination  end ---------------------------------------------------------------------
  return (
    <div className='top'>
      <div className="d-flex">
        <h4 className='mt-2'>Staff </h4>
        <div className="mx-3">
          <button
            className="btn add"
            onClick={handleNewButtonClick}
          >
            Add
          </button>
        </div>
      </div>




      {/* ------------ search and per page ----------------------------*/}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className='form-label text-dark mt-2'>Show : </label>
            <div>
              <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='50'>50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">

            <div className="w-full flex-1">
              <form>
                <div className="relative">

                  <input
                    className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* --------------------------------end------------------------------------------- */}
      <div className="card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S No.</th>
                <th>Branch</th>
                <th>Employee Id</th>
                <th>Name</th>
                <th>Mobile No</th>
                <th>Designation</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.branch_id[0].branch_name}</td>
                  <td>{item.empid}</td>
                  <td>{item.name}</td>
                  <td>{item.mobile}</td>
                  <td>{item.designation[0].designation}</td>
                  {/* <td  ><p className={item.status === 'Active' ? 'active-row' : 'inactive-row'}>{item.status}</p></td> */}
                  {(item.status == "Active") ?
                    <td className='text-center hideres'>
                      <p className=' text-white green-label' >{item.status}</p>
                    </td>
                    : <td className='text-center hideres'  ><p className='text-white red-label'>{item.status}</p></td>
                  }
                  <td className="text-center">
                    <button
                      className="btn updelete mx-2"
                      onClick={() => handleUpdate(item._id, item)}
                    >
                      <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button className='btn delete'
                      onClick={() => handleOpenModal(item._id, item.empid)}
                    ><i class="fa-solid fa-trash-can"></i></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>


      <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update </Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {/* Other input fields */}
          <label className="form-label">
            <b>branch_id :</b>
          </label>
          <input className="form-control" type="text" value={addData.branch_id} onChange={(e) => setAddData({ ...addData, branch_id: e.target.value })} required />
          <label className="form-label">
            <b>empid :</b>
          </label>
          <input className="form-control" type="text" value={addData.empid} onChange={(e) => setAddData({ ...addData, empid: e.target.value })} required />
          <label className="form-label pt-2">
            <b>name :</b>
          </label>
          <input className="form-control" type="text" value={addData.name} onChange={(e) => setAddData({ ...addData, name: e.target.value })} required />
          <label className="form-label pt-2">
            <b>fathername :</b>
          </label>
          <input className="form-control" type="text" value={addData.fathername} onChange={(e) => setAddData({ ...addData, fathername: e.target.value })} required />
          <label className="form-label pt-2">
            <b>mobile :</b>
          </label>
          <input className="form-control" type="text" value={addData.mobile} onChange={(e) => setAddData({ ...addData, mobile: e.target.value })} required />
          <label className="form-label pt-2">
            <b>dateofbirth :</b>
          </label>
          <input className="form-control" type="date" value={addData.dateofbirth} onChange={(e) => setAddData({ ...addData, dateofbirth: e.target.value })} required />


          <label className="form-label pt-2">
            <b>salary :</b>
          </label>
          <input className="form-control" type="date" value={addData.salary} onChange={(e) => setAddData({ ...addData, salary: e.target.value })} required />

          <label className="form-label pt-2">
            <b>interviewdate :</b>
          </label>

          <input className="form-control" type="date" value={addData.interview_date} onChange={(e) => setAddData({ ...addData, interview_date: e.target.value })} required />
          <label className="form-label pt-2">
            <b>joindate :</b>
          </label>
          <input className="form-control" type="date" value={addData.join_date} onChange={(e) => setAddData({ ...addData, join_date: e.target.value })} required />
          <label className="form-label pt-2">
            <b>address :</b>
          </label>
          <input className="form-control" type="text" value={addData.address} onChange={(e) => setAddData({ ...addData, address: e.target.value })} required />
          <label className="form-label pt-2">
            <b>designation :</b>
          </label>
          <input className="form-control" type="text" value={addData.designation} onChange={(e) => setAddData({ ...addData, designation: e.target.value })} required />
          <label className="form-label pt-2">
            <b>qualification :</b>
          </label>
          <input className="form-control" type="text" value={addData.qualification} onChange={(e) => setAddData({ ...addData, qualification: e.target.value })} required />
          <label className="form-label pt-2">
            <b>notes :</b>
          </label>
          <input className="form-control" type="text" value={addData.notes} onChange={(e) => setAddData({ ...addData, notes: e.target.value })} required />
          <label className="form-label pt-2">
            <b>timings :</b>
          </label>
          <input className="form-control" type="text" value={addData.timings} onChange={(e) => setAddData({ ...addData, timings: e.target.value })} required />

          <label className="form-label pt-2">
            <b>relive Date :</b>
          </label>
          <input className="form-control" type="text" value={addData.relive_date} onChange={(e) => setAddData({ ...addData, relive_date: e.target.value })} required />
          <label className="form-label pt-2">
            <b>status :</b>
          </label>
          <select className="form-control" value={addData.status} onChange={(e) => setAddData({ ...addData, status: e.target.value })}>
            <option value="">Select status</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
            <option value="Relived">Relived</option>
          </select>


        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleupdatesubmit}>Add User</Button>
        </Modal.Footer>
      </Modal>




      {/* ----------------delete */}

      <Modal className="modal" show={deleteShowModals} onHide={handleCloseModal}>
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>

          <label className="form-label d-flex">
            <h5 className='mx-1'> Employee Id :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
        </Modal.Footer>
      </Modal>
      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">

          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li
                  className={` page-item ${currentPage === 1 ? "disabled" : ""
                    }`}
                >
                  <button className="page-link" onClick={goToPrevPage}>
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button className="page-link " onClick={goToNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">
                Page {currentPage} of {totalPages}
              </span>
            </div>
          </div>

        </div>
      </div>


      {/* ---------------------------------------end ---------------------------------- */}


    </div>
  );



}

export default Staff;
