import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api";

function AttendanceReport() {
  const { id } = useParams();
  const [filtered, setFiltered] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("staff");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const leadsResponse = await axios.get(apiUrl + "/attendance");
        setFiltered(leadsResponse.data);

        const uniqueStatuses = Array.from(
          new Set(leadsResponse.data.map((item) => item.followups_status))
        ).map((status) => ({ followups_status: status }));

        setStatuses(uniqueStatuses);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;

  const filteredData = filtered.filter((item) => {
    const isCourseMatch = selectedCourse
      ? item.Prefered_course === selectedCourse
      : true;
    const isStatusMatch = selectedStatus
      ? item.followups_status === selectedStatus
      : true;

    const itemEnquiryDate = new Date(item.Enquiry_date);
    const isFromDateMatch = fromDate
      ? itemEnquiryDate >= new Date(fromDate)
      : true;
    const isToDateMatch = toDate ? itemEnquiryDate <= new Date(toDate) : true;

    const isSearchMatch = searchTerm
      ? (item.student_name &&
          item.student_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.mobile_no && String(item.mobile_no).includes(searchTerm))
      : true;

    return (
      isCourseMatch &&
      isStatusMatch &&
      isFromDateMatch &&
      isToDateMatch &&
      isSearchMatch
    );
  });

  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const handleEntriesPerPageChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page when entries per page changes
  };

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleClearFilters = () => {
    setSelectedCourse("");
    setSelectedStatus("");
    setFromDate("");
    setToDate("");
    setSearchTerm("");
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const railwayToNormalTime = (railwayTime) => {
    if (!railwayTime) return "";
    const [hour, minute] = railwayTime.split(":");
    const parsedHour = parseInt(hour);
    const parsedMinute = parseInt(minute);
    if (parsedHour < 12) {
      return `${parsedHour}:${minute} AM`;
    } else if (parsedHour === 12) {
      return `${parsedHour}:${minute} PM`;
    } else {
      return `${parsedHour - 12}:${minute} PM`;
    }
  };

  return (
    <>
      <div className="container-fluid" style={{ backgroundColor: "#E8EFF9" }}>
        <div
          className="card p-2"
          style={{
            marginTop: "-80px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-6 pt-2">
              <h6 className="text-center">
                <b>Attendance Report</b>
              </h6>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 pt-2">
              <button
                className={`btn ${
                  activeTab === "staff" ? "bg-success" : "bg-secondary"
                }`}
                onClick={() => handleTabClick("staff")}
              >
                <i className="fa-solid fa-arrow-right"></i> Staff
              </button>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 pt-2">
              <button
                className={`btn ${
                  activeTab === "student" ? "bg-success" : "bg-secondary"
                }`}
                onClick={() => handleTabClick("student")}
              >
                <i className="fa-solid fa-arrow-right"></i> Student
              </button>
            </div>
          </div>
          <hr />
          <div className="row p-2">
            <div className="col-lg-2 col-md-3 col-sm-12 mx-3">
              <input
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
                className="form-control"
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-12">
              <input
                type="date"
                value={toDate}
                onChange={handleToDateChange}
                className="form-control"
              />
            </div>
            {/* <div className="col-lg-2 col-md-3 col-sm-12 mx-3">
            <select
              className="form-control"
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              <option value="">Status</option>
              {statuses.map((status) => (
                <option
                  key={status.followups_status}
                  value={status.followups_status}
                >
                  {status.followups_status}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12">
            <select
              className="form-control"
              value={selectedCourse}
              onChange={handleCourseChange}
            >
              <option value="">All Courses</option>
            </select>
          </div> */}
            <div className="col-lg-2 col-md-3 col-sm-12 d-flex align-items-end mx-3">
              <button
                className="btn border btn-danger"
                onClick={handleClearFilters}
              >
                <i className="fa-solid fa-xmark"></i> Clear
              </button>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-9 col-md-8 col-sm-12">
              <div>
                <div className="mt-3">
                  <p>
                    No. Of Data:
                    <select
                      className="form-control"
                      style={{ width: "50px" }}
                      value={entriesPerPage}
                      onChange={handleEntriesPerPageChange}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <input
                className="form-control border"
                type="text"
                placeholder="Search.."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <table className="table mt-2 table-bordered table-striped">
            <thead style={{ backgroundColor: "#d2e3fc" }}>
              <tr>
                <th>S.NO</th>
                <th>Branch</th>
                <th>Emp Id</th>
                <th>Name</th>
                <th>Date</th>
                <th>Log In</th>
                <th>Lunch Out</th>
                <th>Lunch In</th>
                <th>Log Out</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.length > 0 ? (
                currentEntries.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1 + (currentPage - 1) * entriesPerPage}</td>
                    <td>
                      {item.branch_id ? item.branch_id.branch_name : "N/A"}
                    </td>
                    <td>{item.empid}</td>
                    <td>{item.name}</td>
                    <td>{item.date}</td>
                    <td>{railwayToNormalTime(item.login || "")}</td>
                    <td>{railwayToNormalTime(item.lunch_out || "")}</td>
                    <td>{railwayToNormalTime(item.lunch_in || "")}</td>
                    <td>{railwayToNormalTime(item.logout || "")}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className="row">
            <div className="col-lg-4">
              <div className="dataTables_info" role="status" aria-live="polite">
                Showing {indexOfFirstEntry + 1} to{" "}
                {Math.min(indexOfLastEntry, filtered.length)} of{" "}
                {filtered.length} entries
              </div>
            </div>
            <div className="col-lg-5"></div>
            <div className="col-lg-3">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Previous
                    </a>
                  </li>
                  {[...Array(totalPages)].map((_, i) => (
                    <li
                      key={i}
                      className={`page-item ${
                        currentPage === i + 1 ? "active" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        onClick={() => handlePageChange(i + 1)}
                      >
                        {i + 1}
                      </a>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row" style={{ fontSize: "13px", fontWeight: "bold" }}>
          <div className="col-lg-9 col-md-9 col-sm-6">
            <p>
              Copyright ©<span className="text-danger"> SCIMITAR BILLING </span>{" "}
              2024
            </p>
          </div>

          <div className="col-lg-3 col-md-12 col-sm-6">
            <p className="text-danger">Developed by aalantechsoft</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AttendanceReport;
