import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api";
import { toast } from "react-toastify";

function Addstaff() {
    const [responseDatas, setResponseDatas] = useState([]);
    const [responseSchuduleData, setResponseSchuduleData] = useState([]);
    const [files, setFiles] = useState([]);
    const [branch, setbranch] = useState([]);
 


    const [addData, setAddData] = useState({

        branch_id: "",
        empid: "",
        name: "",
        fathername: "",
        mobile: "",
        dateofbirth: "",
        interview_date: "",
        join_date: "",
        address: "",
        salary: "",
        designation: "",
        qualification: "",
        notes: "",
        timings: "",
        status: "",
        relive_date: "",
        documents: "",

    });

    const navigate = useNavigate();
    const handlecancel = () => {
        navigate("/staff");
    };


    const handleFileChange = (e) => {
        setFiles(e.target.files);
    };


    //  designation/////////////////



    const handleAddSubmit = async (e) => {
        e.preventDefault();


        
        const formData = new FormData();
        formData.append("branch_id", addData.branch_id);
        formData.append("empid", addData.empid);
        formData.append("name", addData.name);
        formData.append("email",addData.email);
        formData.append("fathername", addData.fathername);
        formData.append("mobile", addData.mobile);
        formData.append("password", addData.password);
        formData.append("dateofbirth", addData.dateofbirth);
        formData.append("interviewdate", addData.interview_date);
        formData.append("joindate", addData.join_date);
        formData.append("salary", addData.salary);
        formData.append("address", addData.address);
        formData.append("department", addData.department);
        formData.append("designation", addData.designation);
        formData.append("qualification", addData.qualification);
        formData.append("notes", addData.notes);
        formData.append("timings", addData.timings);
        formData.append("status", addData.status);
        formData.append("relivedate", addData.relive_date);
        formData.append("documents", addData.documents);




        for (let i = 0; i < files.length; i++) {
            formData.append("documents", files[i]);
        }

        try {
            console.log("Added:", formData.values);
            const response = await axios.post(apiUrl + "/staff", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response) {
                if (response.status === 200) {
                    toast.success("Staff added successfully")
                    navigate('/Staff')
                }
            }


            fetchDatas();

        } catch (error) {
            toast.error("Staff added failed")
            console.error("Error adding data:", error.message);
        }
    };


    useEffect(() => {
        fetchDatas();
        fetchDataed();
        fetch();

    }, []);
    const fetchDatas = async () => {
        try {
            const response = await axios.get(apiUrl + "/designation");
            console.log("Response from server type:", response.data);
            setResponseDatas(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const fetchDataed = async () => {
        try {
            const response = await axios.get(apiUrl + "/Branch");
            console.log("Response from server:", response.data);
            setbranch(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };


    const fetch = async () => {
        try {
            const response = await axios.get(apiUrl + "/designation");
            console.log("Response from server:", response.data);
            setResponseDatas(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };


    // SCHUDULE/////////////////

    useEffect(() => {
        fetchData();

    }, []);
    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + "/schedule");
            console.log("Response from server type:", response.data);
            setResponseSchuduleData(response.data);
            console.log("schudule", response.data)
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };


    const [showReliveDate, setShowReliveDate] = useState(false);


    const handleStatusChange = (e) => {
        const selectedStatus = e.target.value;
        setAddData({ ...addData, status: selectedStatus });

        if (selectedStatus === "Relive") {
            setShowReliveDate(true);
        } else {
            setShowReliveDate(false);
            setAddData(prevData => ({
                ...prevData,
                relivedate: ''
            }));
        }
    };

    const convertToNormalTime = (railwayTime) => {
        const [hours, minutes] = railwayTime.split(':');
        const hour = parseInt(hours, 10);
        const minute = parseInt(minutes, 10);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        return `${hour12}:${minutes} ${ampm}`;
    };
    // console.log("timing", addData.timings)

    return (
        <>
       
            <div className="container top">
         
            <h4 className="mb-3">Add Staff </h4>
          
                <div className="row">
                    <div className="col-lg-4">
                  
                        <div className="form-group">


                        <div className="floating-label">
                            <label className="form-label">
                                <b></b>
                            </label>
                            <select
                                className="form-select"
                                value={addData.branch_id}
                                onChange={(e) => setAddData({ ...addData, branch_id: e.target.value })}
                                required
                            >
                                <option value="">Select Branch </option>
                                {branch.map((item, index) => (
                                    <option key={index} value={item._id}>
                                        {item.branch_name}
                                    </option>
                                ))}
                            </select>
                            <label className={addData.branch_id ? "filled" : ""}>

                            </label>
                        </div>



                        <div className="floating-label">
                                <input className="form-control" type="text" placeholder='' value={addData.name} onChange={(e) => setAddData({ ...addData, name: e.target.value })} required />
                                <label className={addData.name ? "filled" : ""}>
                                Name
                                </label>
                            </div>



                            <div className="floating-label">
                                <input className="form-control" type="text" placeholder='' value={addData.empid} onChange={(e) => setAddData({ ...addData, empid: e.target.value })} required />
                                <label className={addData.empid ? "filled" : ""}>
                                    Employee Id
                                </label>
                            </div>


                            <div className="floating-label">
                                <input className="form-control" type="email" placeholder='' value={addData.email} onChange={(e) => setAddData({ ...addData, email: e.target.value })} required />
                                <label className={addData.email ? "filled" : ""}>
                                Email
                                </label>
                            </div>


                            <div className="floating-label">
                                <input className="form-control" type="text" placeholder='' value={addData.mobile} onChange={(e) => setAddData({ ...addData, mobile: e.target.value })} required />
                                <label className={addData.mobile ? "filled" : ""}>
                                    Mobile
                                </label>
                            </div>




                            <div className="floating-label">
                                <select className="form-select" value={addData.department} onChange={(e) => setAddData({ ...addData, department: e.target.value })}>
                                    <option value="">Select Department</option>
                                    {responseDatas.map((item, index) => (
                                        <option key={index} value={item._id}>{item.department}</option>
                                    ))}
                                </select>
                                <label className={addData.department ? "filled" : ""}>
                                    Select Department
                                </label>

                            </div>



                            {/* <input className="form-control" type="text" value={addData.department} onChange={(e) => setAddData({ ...addData, department: e.target.value })} required /> */}


                            <div className="floating-label">
                                <input className="form-control" type="date" placeholder='' value={addData.dateofbirth} onChange={(e) => setAddData({ ...addData, dateofbirth: e.target.value })} required />
                                <label className={addData.dateofbirth ? "filled" : ""}>
                                    Date Of Birth
                                </label>
                            </div>


        


                        </div>
                    </div>
                    <div className="col-lg-4">
                        
                        <div className="form-group">

                        <div className="floating-label">
                                <input className="form-control" type="text" value={addData.notes} placeholder='' onChange={(e) => setAddData({ ...addData, notes: e.target.value })} required />
                                <label className={addData.notes ? "filled" : ""}>
                                    Notes
                                </label>
                            </div>



                        <div className="floating-label">
                                <input className="form-control" type="text" placeholder='' value={addData.salary} onChange={(e) => setAddData({ ...addData, salary: e.target.value })} required />
                                <label className={addData.salary ? "filled" : ""}>
                                salary
                                </label>
                            </div>

                           

                            <div className="floating-label">
                                <input className="form-control" type="text" placeholder='' value={addData.address} onChange={(e) => setAddData({ ...addData, address: e.target.value })} required />
                                <label className={addData.address ? "filled" : ""}>
                                    Address
                                </label>
                            </div>




                            <div className="floating-label">
                                <select className="form-select" value={addData.designation} onChange={(e) => setAddData({ ...addData, designation: e.target.value })}>
                                    <option value="">Select designation</option>
                                    {responseDatas.map((item, index) => (
                                        <option key={index} value={item._id}>{item.designation}</option>
                                    ))}
                                </select>
                                <label className={addData.designation ? "filled" : ""}>
                                    Select Designation
                                </label>

                            </div>



                            <div className="floating-label">

                                <input
                                    className="form-control"
                                    type="date"
                                    placeholder=''
                                    value={addData.interview_date}
                                    onChange={(e) =>
                                        setAddData({ ...addData, interview_date: e.target.value })
                                    }
                                    required
                                />
                                <label className={addData.interview_date ? "filled" : ""}>
                                    interview_date
                                </label>
                            </div>



                            <div className="floating-label">
                                <select
                                    className="form-control"
                                    placeholder=''
                                    value={addData.status}
                                    onChange={handleStatusChange}
                                >
                                    <option value="">Select status</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">In Active</option>
                                    <option value="Relive">Relive</option>
                                </select>
                                {showReliveDate && ( // Show the callback date field if 'Callback' status is selected
                                    <div className="form-group mt-2">
                                        <label className="form-label my-1"><b>Relive Date:</b></label>
                                        <input className="form-control" type="date" value={addData.relivedate} onChange={(e) => setAddData({ ...addData, relivedate: e.target.value })} required />
                                    </div>
                                )}
                                <label className={addData.status ? "filled" : ""}>

                                </label>
                            </div>



                            <div className="floating-label">
                                <select className="form-select" value={addData.timings} placeholder='' onChange={(e) => setAddData({ ...addData, timings: e.target.value })}>
                                    <option value="">Select Timing</option>
                                    {responseSchuduleData.map((item, index) => (
                                        <option key={index} value={`${item.start_time}-${item.end_time}`}>
                                            {convertToNormalTime(item.start_time)} - {convertToNormalTime(item.end_time)}
                                        </option>
                                    ))}
                                </select>
                                <label className={addData.timings ? "filled" : ""}>

                                </label>
                            </div>

                        </div>
                    </div>


                    <div className="col-lg-4">
                        <div className="form-group">




                            <div className="floating-label">
                                <input className="form-control" type="text" placeholder='' value={addData.fathername} onChange={(e) => setAddData({ ...addData, fathername: e.target.value })} required />
                                <label className={addData.fathername ? "filled" : ""}>
                                    Fathername
                                </label>
                            </div>


                            <div className="floating-label">
                                <input
                                    className="form-control"
                                    type="password"
                                    placeholder=''
                                    value={addData.password}
                                    onChange={(e) =>
                                        setAddData({ ...addData, password: e.target.value })
                                    }
                                    required
                                />
                                <label className={addData.password ? "filled" : ""}>
                                    Password
                                </label>
                            </div>




                            <div className="floating-label">
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={addData.qualification}
                                onChange={(e) =>
                                    setAddData({ ...addData, qualification: e.target.value })
                                }
                                required
                            />
                                <label className={addData.qualification ? "filled" : ""}>
                                Qualification
                                </label>
                            </div>


                            <div className="floating-label">
                            <input
                                className="form-control"
                                type="date"
                                placeholder=""
                                value={addData.join_date}
                                onChange={(e) =>
                                    setAddData({ ...addData, join_date: e.target.value })
                                }
                                required
                            />
                                <label className={addData.join_date ? "filled" : ""}>
                                Join date
                                </label>
                            </div>


                            <div className="floating-label">
                            <input className="form-control"   placeholder="" type="file" accept=".pdf, .doc, .docx, .jpg, .jpeg, .png" multiple onChange={handleFileChange} />
                                <label className={addData.documents ? "filled" : ""}>
                                Documents
                                </label>
                            </div>

                      
                        </div>
                    </div>
                </div>

                {/* card */}
            
                <div className="row mt-5 d-flex justify-content-end mb-5">
                    <div className="col-lg-6 text-center d-flex justify-content-start">
                        <button className="btn btn-secondary w-20 mx-3 " onClick={handlecancel}>Cancel</button>
                    </div>
                    <div className="col-lg-6 text-center d-flex justify-content-end">
                        <button className="btn btn-primary Submitbut w-20" onClick={handleAddSubmit}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>



        </>
    );
}

export default Addstaff;
