import React, { useEffect, useState } from 'react';
import '../../assets/css/Print.css'
import axios from 'axios';
import apiUrl from '../../environment/Api';
import { useParams } from 'react-router-dom';
import { ToWords } from 'to-words';

function Print() {
   const {id} = useParams();
   const [getdetailsdata, setdetailsdata] = useState(null);  // Initialize with null
   const [getlistdata, setlistdata] = useState([]);

   useEffect(() => {
      getinvoicedetails();
      getinvoicelist();
   }, []);


   useEffect(() => {
      if (getdetailsdata && getlistdata.length > 0) {
         window.print();
      }
   }, [getdetailsdata, getlistdata]);

   
   const getinvoicedetails = async () => {
      try {
         const response = await axios.get(apiUrl + `/invoice_details/${id}`);
         console.log("invoicedetails", response.data);
         setdetailsdata(response.data);
      } catch (error) {
         console.log(error);
      }
   };

   const getinvoicelist = async () => {
      try {
         const response = await axios.get(apiUrl + `/invoice_list/${id}`);
         console.log("invoicelist", response.data);
         setlistdata(response.data);
      } catch (error) {
         console.log(error);
      }
   };

   if (!getdetailsdata) {
      // Render a loading message or spinner until the data is fetched
      return <div>Loading...</div>;
   }


   // ////////////////////////////////

   


   const toWords = new ToWords({
           localeCode: 'en-IN',
           converterOptions: {
               currency: true,
               ignoreDecimal: false,
               ignoreZeroCurrency: false,
               doNotAddOnly: false,
               currencyOptions: {
                   name: 'Rupees' ,
                   plural: 'Rupees',
                   symbol:'₹' ,
                   fractionalUnit: {
                       name: 'Paisa' ,
                       plural: 'Paisa',
                       symbol: '',
                   },
               }
           }
       });
   
   
       let words = toWords.convert(getdetailsdata[0].paid);
   
   

   
   return (
      <>
       
         <div id="border">
            <div className="background">
               <div className="overall">
                  <div className="d-flex justify-content-between">
                     <div>
                        <div className="techsoft">
                           <p className="para">
                              <b className="alan" >AALAN TECHSOFT</b>
                              <br />
                              24/302 JH-TOWER
                              <br />
                              LIC COLONY Road
                              <br />
                              Salem, Tamilnadu-636004
                              <br />
                              Phone: (+91)9080108634
                              <br />
                              Email: aalantechsoft@gmail.com
                           </p>
                        </div>
                     </div>
                    
                     <div style={{ width: '280px' }}>
                     <p className='alan' style={{color:'white'}}>INVOICE</p>
                        <div className="card photo">
                           <img  src="" alt="Company Logo"></img>
                        </div>
                        <div className="data-2" style={{color:'white'}}>
                           <label className="voice">Invoice No: {getdetailsdata[0].invoice_no}</label>
                           <p className="line"></p>
                           <label className="voice">Date: {getdetailsdata[0].date}</label>
                           <p className="line"></p>
                           <label className="voice">Payment Type: {getdetailsdata[0].paytype}</label>
                           <p className="line"></p>
                        </div>
                     </div>
                  </div>
               </div>
               
               <div className="row">
                  <div className="d-flex justify-content-between">
                     <div>
                        <div className="detailss">
                           <h5 className="bill"><b>Bill To</b></h5>
                           <label>Name: {getdetailsdata[0].name}</label>
                           <p className="line"></p>
                           <label>Address:</label>
                           <p className="line"></p>
                           <label>Phone: {getdetailsdata[0].mobile}</label>
                           <p className="line"></p>
                           <label>Email: {getdetailsdata[0].email}</label>
                           <p className="line"></p>
                        </div>
                     </div>
                     {/* <div>
                        <div className="account"  style={{ width: '280px' }}>
                           <h5><b>Account Details</b></h5>
                           <label>Name:          {getdetailsdata[0].name}</label>
                           <p className="line"></p>
                           <label>Account no:</label>
                           <p className="line"></p>
                           <label>Bank:</label>
                           <p className="line"></p>
                           <label>Branch:</label>
                           <p className="line"></p>
                        </div>
                     </div> */}
                  </div>
                  <div className="col-lg-12">
                     <div className="card start">
                        <div className="table-responsive">
                           <table className="table table-bordered">
                              <thead className="thead-dark">
                                 <tr>
                                    <th>S.NO</th>
                                    <th>Description</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {getlistdata.map((item, index) => (
                                    <tr key={index}>
                                       <td>{index + 1}</td>
                                       <td>{item.description}</td>
                                       <td>{item.qty}</td>
                                       <td>{item.price}</td>
                                       <td>{item.total}</td>
                                    </tr>
                                 ))}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
                  <div className="d-flex justify-content-between">
                     <div>
                        <div className="amount">
                           <label>Amount in Words:</label>
                           <p className="line">{words}</p>
                           <label>Terms & Conditions:</label>
                           <p className="line"></p>
                        </div>
                     </div>
                     <div>
                        <div style={{ width: '280px' }} className="total">
                          <div className='d-flex'>
                          <p style={{width:"125px"}}>Subtotal</p>
                          <p>:  {getdetailsdata[0].subtotal}</p>
                          </div>
                           

                           <div  className='d-flex'>
                           <p style={{width:"125px"}}>Discount</p>
                           <p>: {getdetailsdata[0].discount}</p> 
                           </div>
                         
                         <div className='d-flex'>
                         <p style={{width:"125px"}}>GST </p>
                         <p>: {getdetailsdata[0].gst}</p>
                         </div>

                            <div className='d-flex'>
                            <p style={{width:"125px"}}><b>Net Total</b></p>
                            <p><b>: {getdetailsdata[0].nettotal}</b></p>
                            </div>
                          
                          <div className='d-flex'>
                          <p style={{width:"125px"}}>Paid  </p>
                          <p>: {getdetailsdata[0].paid}</p>
                          </div>

                           <div className='d-flex'>
                           <p style={{width:"125px"}}>Balance</p>
                           <p>: {getdetailsdata[0].balance}</p>
                           </div>
                          
                        </div>
                     </div>
                  </div>
                  <div>
                     <p className="text-center">
                        IF YOU HAVE ANY QUESTIONS CONCERNING THIS INVOICE CONTACT 9080108634 aalantechsoft@gmail.com
                     </p>
                     <p className="text-center">------THANK YOU FOR YOUR BUSINESS------</p>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default Print;
