import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import '../../assets/css/updatestaff.css'

function Updatestaff() {
  const { id } = useParams();
  const navigator = useNavigate();
  const [responseDatas, setResponseDatas] = useState([]);
  const [responseSchuduleData, setResponseSchuduleData] = useState([]);

  const [getdatas, setdatas] = useState({

    branch_id: "",
    empid: "",
    name: "",
    email: "",
    fathername: "",
    mobile: "",
    dateofbirth: "",
    interview_date: "",
    join_date: "",
    address: "",
    password:"",
    salary: "",
    designation: "",
    qualification: "",
    notes: "",
    timings: "",
    status: "",
    relive_date: "",
    documents: "",


  });

  // schudule get/////////////////////

  useEffect(() => {
    fetchDataSchudule();

  }, []);
  const fetchDataSchudule = async () => {
    try {
      const response = await axios.get(apiUrl + "/schedule");
      console.log("Response from server type:", response.data);
      setResponseSchuduleData(response.data);
      console.log("schudule", response.data)
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };




  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      console.log("hello",id);

      const response = await axios.get(apiUrl + `/staff/${id}`);
      console.log("update",response);


      setdatas(response.data[0]);

      const selectedStatus = response.data[0].status;

      if (selectedStatus === "Relive") {
        setShowReliveDate(true);
      } else {
        setShowReliveDate(false);
        // Also, clear the relive date if the status is not "Relive"
        setdatas((prevData) => ({
          ...prevData,
          relivedate: "",
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  //  designation/////////////////

  useEffect(() => {
    fetchDatas();
  }, []);
  const fetchDatas = async () => {
    try {
      const response = await axios.get(apiUrl + "/designation");
      console.log("Response from server type:", response.data);
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  
  const [updateData, setUpdateData] = useState([]);
  const [files, setFiles] = useState([]);



  // const handleupdate = async (e) => {

  //   console.log("tye update data ", getdatas);



  //   e.preventDefault();
  //   const formData = new FormData();





  //   formData.append("branch_id", getdatas.branch_id);
  //   formData.append("empid", getdatas.empid);
  //   formData.append("name", getdatas.name);
  //   formData.append("email", getdatas.email);
  //   formData.append("fathername", getdatas.fathername);
  //   formData.append("mobile", getdatas.mobile);
  //   formData.append("password", getdatas.password);
  //   formData.append("dateofbirth", getdatas.dateofbirth);
  //   formData.append("interview_date", getdatas.interview_date);
  //   formData.append("join_date", getdatas.join_date);
  //   formData.append("address", getdatas.address);
  //   formData.append("salary", getdatas.salary);
  //   formData.append("department", getdatas.department);
  //   formData.append("designation", getdatas.designation);
  //   formData.append("qualification", getdatas.qualification);
  //   formData.append("notes", getdatas.notes);
  //   formData.append("timings", getdatas.timings);
  //   formData.append("status", getdatas.status);
  //   formData.append("relivedate", getdatas.relive_date);

  //   for (let i = 0; i < files.length; i++) {
  //     formData.append("documents", files[i]);
  //   }
  //   try {
  //     console.log("formdata",formData)
  //     const updatedata = await axios.put(apiUrl + `/staff/${id}`, formData);
  //     console.log(" the updata ", updatedata);
  //     toast.success("Update successfully!");
  //     navigator("/staff");

  //   }

  //   catch (error) {
  //     toast.error("updateStaff failed")
  //     console.error("Error adding data:", error.message);
  //   }
  // }


  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('password', getdatas.password);
      formData.append('branch_id', getdatas.branch_id._id);
      formData.append('empid', getdatas.empid);
      formData.append('name', getdatas.name);
      formData.append('email', getdatas.email);
      formData.append('fathername', getdatas.fathername);
      formData.append('mobile', getdatas.mobile);
      formData.append('dateofbirth', getdatas.dateofbirth);
      formData.append('interview_date', getdatas.interview_date || '');
      formData.append('join_date', getdatas.join_date || '');
      formData.append('address', getdatas.address);
      formData.append('salary', getdatas.salary);
      formData.append('designation', getdatas.designation);
      formData.append('department', getdatas.department);
      formData.append('qualification', getdatas.qualification);
      formData.append('notes', getdatas.notes);
      formData.append('timings', getdatas.timings);
      formData.append('status', getdatas.status);
      formData.append('relive_date', getdatas.relive_date || '');

      for (const file of selectedFiles) {
        formData.append('documents', file);
      }

      const updatedata = await axios.put(`${apiUrl}/staff/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log("Updated data:", updatedata);
      toast.success("Update successfully!");
      navigator("/staff");
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

 


  const navigate = useNavigate();
  const handlecancel = () => {
    navigate("/staff");
  };


  const [selectedFiles, setSelectedFiles] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };
  





  const [showReliveDate, setShowReliveDate] = useState(false);

  const handleStatusChangeupdate = (e) => {
    const selectedStatus = e.target.value;
    setUpdateData({ ...updateData, status: selectedStatus });

    if (selectedStatus === "Relive") {
      setShowReliveDate(true);
    } else {
      setShowReliveDate(false);
      setUpdateData((prevData) => ({
        ...prevData,
        relivedate: "",
      }));
    }
  };


  const convertToNormalTime = (railwayTime) => {
    if (!railwayTime || !railwayTime.includes(':')) {
      console.error("Invalid time format:", railwayTime);
      return ''; // Return a default value or handle the error appropriately
    }

    const [hours, minutes] = railwayTime.split(':');
    const hour = parseInt(hours, 10);
    const minute = parseInt(minutes, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;

    return `${hour12}:${minutes} ${ampm}`;
  };

  return (
    <div className="updatestaff">
      <h3>Update Staff</h3>
    
      <div className="container mb-5">
      <div className="card">
        <div className="row">
          <div className="col-lg-4 mt-2">

            <label className="form-label">
              <b>Branch</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={getdatas.branch_id.branch_name}
              onChange={(e) => setdatas({ ...getdatas, branch_id: e.target.value })}
              required
            />


            <label className="form-label pt-2">
              <b>Employee Id :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={getdatas.empid}
              onChange={(e) => setdatas({ ...getdatas, empid: e.target.value })}
              required
            />





            <label className="form-label">
              <b>salary :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={getdatas.salary}
              onChange={(e) => setdatas({ ...getdatas, salary: e.target.value })}
              required
            />

            <label className="form-label pt-2">
              <b>Mobile :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={getdatas.mobile}
              onChange={(e) =>
                setdatas({ ...getdatas, mobile: e.target.value })
              }
              required
            />
            <label className="form-label mt-2">
              <b>Department :</b>
            </label>
            <select
              className="form-select"
              value={getdatas.department}
              onChange={(e) =>
                setdatas({ ...getdatas, department: e.target.value })
              }
            >
              {responseDatas.map((item, index) => (
                <option key={index} value={item._id}>
                  {item.department}
                </option>
              ))}
            </select>

            {/* <input
                            className="form-control"
                            type="text"
                            value={getdatas.department}
                            onChange={(e) =>
                                setdatas({ ...getdatas, department: e.target.value })
                            }
                            required
                        /> */}


            <label className="form-label pt-2">
              <b>Date Of Birth :</b>
            </label>

            <input
              className="form-control"
              type="date"
              value={
                getdatas.dateofbirth
                  ? getdatas.dateofbirth.substring(0, 10)
                  : ""
              }
              onChange={(e) =>
                setdatas({ ...getdatas, dateofbirth: e.target.value })
              }
              required
            />



            <label className="form-label pt-2">
              <b>Timing :</b>
            </label>
            {/* <input
              className="form-control"
              type="text"
              value={getdatas.timings}
              onChange={(e) =>
                setdatas({ ...getdatas, timings: e.target.value })
              }
              required
            /> */}
            <select className="form-select" value={getdatas.timings} onChange={(e) => setdatas({ ...getdatas, timings: e.target.value })}>

              {responseSchuduleData.map((item, index) => (
                <option key={index} value={`${item.start_time}-${item.end_time}`}>
                  {convertToNormalTime(item.start_time)} - {convertToNormalTime(item.end_time)}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-4">


            <label className="form-label pt-2">
              <b>Email :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={getdatas.email}
              onChange={(e) => setdatas({ ...getdatas, email: e.target.value })}
              required
            />

            <label className="form-label pt-2">
              <b>Name :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={getdatas.name}
              onChange={(e) => setdatas({ ...getdatas, name: e.target.value })}
              required
            />

            <label className="form-label pt-2">
              <b>Address :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={getdatas.address}
              onChange={(e) =>
                setdatas({ ...getdatas, address: e.target.value })
              }
              required
            />


            <label className="form-label pt-2">
              <b>Designation :</b>
            </label>
            <select
              className="form-select"
              value={getdatas.designation}
              onChange={(e) =>
                setdatas({ ...getdatas, designation: e.target.value })
              }
            >
              {responseDatas.map((item, index) => (
                <option key={index} value={item._id}>
                  {item.designation}
                </option>
              ))}
            </select>

            {/* <input
                            className="form-control"
                            type="text"
                            value={getdatas.designation}
                            onChange={(e) =>
                                setdatas({ ...getdatas, designation: e.target.value })
                            }
                            required
                        /> */}



            <label className="form-label pt-2">
              <b> Interview Date :</b>
            </label>

            <input
              className="form-control"
              type="date"
              value={
                getdatas.interview_date
                  ? getdatas.interview_date.substring(0, 10)
                  : ""
              }
              onChange={(e) =>
                setdatas({ ...getdatas, interview_date: e.target.value })
              }
              required
            />



            <label className="form-label pt-2">
              <b>Status :</b>
            </label>
            <select
              className="form-control"
              value={getdatas.status}
              onClick={handleStatusChangeupdate}
              onChange={(e) =>
                setdatas({ ...getdatas, status: e.target.value })
              }
              required
            >
              <option value="Active">Active</option>
              <option value="Inactive">In Active</option>
              <option value="Relive">Relive</option>
            </select>

            {showReliveDate && ( // Show the callback date field if 'Callback' status is selected
              <div className="form-group mt-2">
                <label className="form-label my-1">
                  <b>Relive Date:</b>
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={getdatas.relive_date}
                  onChange={(e) =>
                    setdatas({ ...getdatas, relive_date: e.target.value })
                  }
                  required
                />
              </div>
            )}


            <label className="form-label pt-2">
              <b>Notes :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={getdatas.notes}
              onChange={(e) => setdatas({ ...getdatas, notes: e.target.value })}
              required
            />



          </div>
          <div className="col-lg-4">
            <label className="form-label pt-2">
              <b>Father Name :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={getdatas.fathername}
              onChange={(e) =>
                setdatas({ ...getdatas, fathername: e.target.value })
              }
              required
            />

            <label className="form-label pt-2">
              <b>Password :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={getdatas.password}
              onChange={(e) =>
                setdatas({ ...getdatas, password: e.target.value })
              }
              required
            />
            <label className="form-label pt-2">
              <b>Qualification :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={getdatas.qualification}
              onChange={(e) =>
                setdatas({ ...getdatas, qualification: e.target.value })
              }
              required
            />
            <label className="form-label pt-2">
              <b>Join Date :</b>
            </label>
            <input
              className="form-control"
              type="date"
              value={
                getdatas.join_date ? getdatas.join_date.substring(0, 10) : ""
              }
              onChange={(e) =>
                setdatas({ ...getdatas, join_date: e.target.value })
              }
              required
            />

<label className="form-label pt-2">
        <b>Documents:</b>
      </label>
      <input
        className="form-control"
        type="file"
        accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
        multiple
        onChange={handleFileChange}
      />
  </div>
          </div>
        </div>

        <div className="row d-flex justify-content-end  mt-3">
          <div className="col-lg-6">
            <button
              className="btn btn-secondary w-20 mx-3 cancelbut"
              onClick={handlecancel}
            >
              Cancel
            </button>
          </div>
          <div className="col-lg-6 d-flex justify-content-end">
            <button
              className="btn btn-primary w-20 Submitbut"
              onClick={handleupdatesubmit}
            >
              Update
            </button>
          </div>
        </div>

    
      </div>
    </div>
  );
}

export default Updatestaff;
