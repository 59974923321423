import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import apiUrl from '../../environment/Api';
import { useNavigate } from "react-router-dom";
import '../../assets/css/Student.css';
import { toast } from 'react-toastify';

function Student() {
    const [responseData, setResponseData] = useState([]);
    const [responseDatas, setResponseDatas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isUpdateshowModal, setUpdateShowModal] = useState(false);
    const [isUpdateshowModal1, setUpdateShowModal1] = useState(false);
    const [Updateshow, setUpdateShow] = useState(false);
    const [deleteShowModals, setDeleteShowModals] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');


    const [role_id, setroleid] = useState('');
    const [previllages, setprevillages] = useState({})



    const navigate = useNavigate()
    const [studentPopUp, setStudentPopUp] = useState(false)




    const handleNewButtonClick = () => {
        if (previllages.add || previllages.all) {
            setShowModal(true);
        } else {
            toast.error("You don't have the permission");
        }

    };



    const updateinfo = (_id) => {
        navigate(`/Leadhistroy/${_id}`)
    }


    const handleUpdatednew = (_id, data) => {
        setUpdateId(_id)
        console.log(" the data to updata", data)
        setUpdateShow(true);
        setUpdateData({

            branch_id: UpdatedData.branch_id,
            student_name: UpdatedData.student_name,
            mobile_no: UpdatedData.mobile_no,
            email: UpdatedData.email,
            Source_type: UpdatedData.Source_type,
            referal_candidate: UpdatedData.referal_candidate,
            referal_candidate_name: UpdatedData.referal_candidate_name,
            Enquiry_date: UpdatedData.Enquiry_date,
            Perfered_course: UpdatedData.selectcourse,
        })
    }




    const [UpdatedData, setUpdatedData] = useState([]);

    const handleUpdated = async (_id, data) => {
        if (previllages.edit || previllages.all) {
            try {
                console.log("this", _id , data);

                // Fetch the updated data from the API
                const filterUpdateData = await axios.get(apiUrl + `/leads/${_id}`);
                console.log(filterUpdateData.data?.[0], "filterUpdateData___999");

                // Set the updated data to the state
                setUpdatedData(filterUpdateData.data?.[0]);

                // Show the update modal or component
                setUpdateShow(true);
            } catch (error) {
                console.error("Error fetching updated data:", error);
            }
        } else {

            console.log("You do not have the necessary privileges to edit this data.");

            toast.error("You don't have the permission to update");
        }
    };

    // current date//////////////////////////////
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };




    const [addData, setAddData] = useState({



        branch_id: '',
        selectcourse: '',
        student_name: '',
        father_name: '',
        timings: '',
        date: formatDate(new Date()),
        dateofbirth: '',
        qualification: '',
        mobile_no: '',
        email: '',
        notes: '',
        status: '',
        total_fees: '',
        paid: '',
        balance: '',
        student_type: '',
        source_type: '',
        location: '',
        callback_date: '',
        converted_status: '',
        completed: '',
        referal_candidate: "",
        referal_candidate_name: "",
    });



    const [updateData, setUpdateData] = useState({
        // coursename: '',
        student_name: '',
        // fathername: '',
        // timings: '',
        date: formatDate(new Date()),
        // dateofbirth: '',
        // qualification: '',
        mobile_no: '',
        // email: '',
        // notes: '',
        status: '',
        callback_date: '',
        // convertedstatus: '',
        // studenttype: '',
        // completed: '',
        roll_no: '',
        photo_upload: '',
        id_proof: '',
    })


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + "/leads");
            console.log("Response from serverrrr:", response.data);
            if (response && response.data) {
                const data = response.data;
                const filteredData = data.filter(item => item.status !== 'Converted');
                setResponseData(filteredData);
            }
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };



    useEffect(() => {
        fetchDatas();
        gettoken();
    }, []);


    const fetchDatas = async () => {
        try {
            const response = await axios.get(apiUrl + "/course");
            console.log("Response from server:", response.data);

            if (response && response.data) {
                const data = response.data;

                setResponseDatas(data);
            } else {
                console.error("No data received from server.");
            }
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };


    const gettoken = async () => {
        const token = localStorage.getItem('token');
        // console.log("the toekn in the localStorage is : ",token);
        try {
            const response = await axios.post(apiUrl + "/get_detail", { "token": token });
            console.log("token detail from server:", response.data);
            // setResponseData(response.data);

            console.log("The role id is : ", response.data.detail.rolename);
            setroleid(response.data.detail.rolename);
            console.log("the role_id is : ", role_id);

            localStorage.setItem('role_id', response.data.detail.rolename);

            const id = response.data.detail.rolename

            // getpages();

            if (id) {
                try {
                    const response = await axios.get(apiUrl + `/role_2/${id}`);

                    console.log("the reponse list in brach is : ", response.data.modules);

                    const modules = response.data.modules;

                    const filtered_access = modules.filter(item => item.name == "Branch")

                    console.log("the modules is : ", filtered_access[0].actions);

                    setprevillages(filtered_access[0].actions);

                    console.log("the assigned previllages is : ", previllages.add);

                } catch (error) {
                    console.error("Error fetching data:", error.message);
                }
            }

        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    }




    const handlesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {
                branch_id: addData.branch_id,
                student_name: addData.student_name,
                mobile_no: addData.mobile_no,
                email: addData.email,
                Source_type: addData.source_type,
                referal_candidate: addData.referal_candidate,
                referal_candidate_name: addData.referal_candidate_name,
                Enquiry_date: addData.date,
                Prefered_course: addData.selectcourse,
                followups_status: "New"

            }



            console.log("formData,OO:", formData);
            const response = await axios.post(apiUrl + "/leads", formData);

            console.log("the histroy begindsaqqed6666546", response)

            // const student_Id = response.data._id;
            // console.log("the histroy begindsaqqed", student_Id)

            // const formData1 = {


            //     student_Id: student_Id,
            //     Source_type: addData.source_type,
            //     Enquiry_date: addData.date,
            //     contact_type: " following update",
            //     assign_staff: null,
            //     notes: " following notes",
            //     status: " New"


            // }
            // if (response.status == 200) {
            //     const response1 = await axios.post(apiUrl + "/leadhistroy", formData1);
            //     console.log("the histroy begin", response1)
            // }


            setShowModal(false);

            toast.success("added successfully!");
            setAddData({
                branch_id: '',
                student_name: '',
                mobile_no: '',
                email: '',
                source_type: '',
                referal_candidate: "",
                referal_candidate_name: "",
                date: formatDate(new Date()),
                status: '',
                student_type: '',
                selectcourse: '',

            });
            fetchData();



        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };


    const [updateid, setUpdateId] = useState("");

    // const handleUpdate = (id, data) => {
    //     setUpdateId(id)
    //     console.log(" the data to updata", data)
    //     // setUpdateShowModal(true);
    //     setUpdateData({
    //         studentname: data.studentname,
    //         fathername: data.fathername,
    //         qualification: data.qualification,
    //         selectcourse: data.selectcourse,
    //         timings: data.timings,
    //         date: data.date,
    //         dateofbirth: data.dateofbirth,
    //         mobileno: data.mobileno,
    //         email: data.email,
    //         notes: data.notes,
    //         status: data.status,
    //         studenttype: data.studenttype,
    //         callbackdate: data.callbackdate,
    //         convertedstatus: data.convertedstatus

    //     })

    // }







    // ////////////////////////


    const updateshow = async (_id, data) => {
        setUpdateId(data._id)
        console.log("this", _id)
        const filterUpdateData = await axios.get(apiUrl + `/leads/${_id}`);

        // const filterUpdateData= responseData.filter((iteam,index)=>iteam._id === _id)

        console.log(filterUpdateData.data?.[0], "filterUpdateData___999")
        setUpdatedData(filterUpdateData.data?.[0])
        console.log(" the data to updata", data)
        setUpdateShowModal(true);



        setUpdateData({


            branch_id: data.branch_id,
            student_name: data.student_name,
            mobile_no: data.mobile_no,
            email: data.email,
            source_type: data.source_type,
            referal_candidate: data.referal_candidate,
            referal_candidate_name: data.referal_candidate_name,
            Enquiry_date: data.date,
            Perfered_course: data.selectcourse,
            followups_status: "new"
        });




    }



    // Helper function to format ISO date to YYYY-MM-DD
    const formatDatefitching = (isoDate) => {
        if (!isoDate) return ''; // Return an empty string if no date is provided
        const date = new Date(isoDate);
        return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    };

    const handleupdatesubmit = async (id) => {
        try {
            console.log(id, "idddss")
            const updateDatas = {

                branch_id: UpdatedData.branch_id,
                student_name: UpdatedData.student_name,
                mobile_no: UpdatedData.mobile_no,
                email: UpdatedData.email,
                Source_type: UpdatedData.Source_type,
                referal_candidate: UpdatedData.referal_candidate || "",
                referal_candidate_name: UpdatedData.referal_candidate_name || "",
                Enquiry_date: UpdatedData.Enquiry_date,
                Prefered_course: UpdatedData.Prefered_course,

                // branch_id: UpdatedData.branch_id,
                // student_name: UpdatedData.student_name,
                // mobile_no: UpdatedData.mobile_no,
                // email: UpdatedData.email,
                // source_type: UpdatedData.source_type,
                // referal_candidate: UpdatedData.referal_candidate,
                // referal_candidate_name: UpdatedData.referal_candidate_name,
                // Enquiry_date: UpdatedData.date,
                // Perfered_course: UpdatedData.selectcourse,
                // convertedstatus: updateData.convertedstatus,
                // studenttype: updateData.studenttype
            }
            // console.log("the update submit data", formData)
            // const response = await axios.put(apiUrl + `/student/${updateid}`, formData)
            // console.log("response for update", response)
            // setUpdateShow(false)
            // toast.success("Update successfully!");
            // fetchData();


            try {
                console.log("The console update  data as is : ", updateDatas, updateid)
                const response = await axios.put(apiUrl + `/leads/${id}`, updateDatas);
                console.log("the response : ", response);

                if (response.status == 200) {
                    fetchData();
                    toast.success("converted sucessfully!");
                    setUpdateShow(false);
                } else {
                    toast.success("failed to convert !");
                }
            } catch (error) {
                toast.success("error in convert !");
                console.log("Error fetching data:", error.message);
            }
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    }
    const [detailsData, setDetailsData] = useState({
        assign_staff: '',
        contact_type: '',
        followups_date: '',
        followups_time: ''
    })

    const handleupdatesubmitdetails = async (id) => {
        try {
            console.log(id, "ids===")
            const updateDatasDetails = {

                branch_id: UpdatedData.branch_id,
                student_name: UpdatedData.student_name,
                mobile_no: UpdatedData.mobile_no,
                email: UpdatedData.email,
                Source_type: UpdatedData.Source_type,
                referal_candidate: UpdatedData.referal_candidate || "",
                referal_candidate_name: UpdatedData.referal_candidate_name || "",
                Enquiry_date: UpdatedData.Enquiry_date,
                Prefered_course: UpdatedData.Prefered_course,
                assign_staff: UpdatedData.assign_staff,
                contact_type: UpdatedData.contact_type,
                followups_date: UpdatedData.followups_date,
                followups_time: UpdatedData.followups_time,
                followups_status: "New"
                // followups_time:detailsData.followups_time

                // branch_id: UpdatedData.branch_id,
                // student_name: UpdatedData.student_name,
                // mobile_no: UpdatedData.mobile_no,
                // email: UpdatedData.email,
                // source_type: UpdatedData.source_type,
                // referal_candidate: UpdatedData.referal_candidate,
                // referal_candidate_name: UpdatedData.referal_candidate_name,
                // Enquiry_date: UpdatedData.date,
                // Perfered_course: UpdatedData.selectcourse,
                // convertedstatus: updateData.convertedstatus,
                // studenttype: updateData.studenttype
            }
            // console.log("the update submit data", formData)
            // const response = await axios.put(apiUrl + `/student/${updateid}`, formData)
            // console.log("response for update", response)
            // setUpdateShow(false)
            // toast.success("Update successfully!");
            // fetchData();


            try {
                console.log("The console update  data as is : ", updateDatasDetails, updateid)
                const response = await axios.put(apiUrl + `/leads/${id}`, updateDatasDetails);
                console.log("the response : ", response);


                // const student_Id = response.data._id;
                // console.log("the histroy  update post", student_Id)
                // const formDataaa = {


                //     student_Id: student_Id,
                //     Source_type: '',
                //     Enquiry_date: '',
                //     contact_type: UpdatedData.contact_type,
                //     assign_staff: UpdatedData.assign_staff,
                //     notes: 'following notes  ',
                //     status: UpdatedData.followups_status,


                // }

                // if (response.status == 200) {
                //     const response12 = await axios.post(apiUrl + "/leadhistroy", formDataaa);
                //     console.log("the histroy begin", response12)
                // }



                if (response.status == 200) {
                    fetchData();
                    toast.success("converted sucessfully!");
                    setUpdateShowModal(false)
                } else {
                    toast.success("failed to convert !");
                }
            } catch (error) {
                toast.success("error in convert !");
                console.log("Error fetching data:", error.message);
            }
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    }



    useEffect(() => {
        fetchDatased()
    }, [])
    const [branch, setbranch] = useState([]);
    const [studentId, setSutudentId] = useState([])
    const [stafftId, setStaffId] = useState([])
    const fetchDatased = async () => {
        try {
            const response = await axios.get(apiUrl + "/Branch");
            const responsestudent = await axios.get(apiUrl + "/student");
            const responsestaff = await axios.get(apiUrl + "/staff");
            console.log("Response from server:", response.data);
            setbranch(response.data);
            setSutudentId(responsestudent.data)
            setStaffId(responsestaff.data)
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };
    const [referaltype, setreferaltype] = useState('');

    const handlereferal = (e) => {
        console.log(e.target.value)
        setAddData({ ...addData, referal_candidate: e.target.value })
        setreferaltype(e.target.value);
    }
    const [seduledStatus, setSeduledStatus] = useState('')

    function handleschuduleStatus(e) {
        console.log(e.target.value)
        setAddData({ ...addData, referal_candidate_name: e.target.value })
        setSeduledStatus(e.target.value)

    }
    const handleDelete = async (id) => {
        try {

            const response = await axios.delete(apiUrl + `/leads/${getdeleteid}`)
            console.log("delete data ", response)
            toast.error("Delete successfully!");
            setDeleteShowModals(false);
            fetchData();
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    };
    const handleCloseModal = () => {
        setDeleteShowModals(false);
    }

    const [getdeleteid, setdeleteid] = useState('')
    const [getdeletename, setdeletename] = useState('')
    const handleOpenModal = (_id, student_name) => {
        if(previllages.all || previllages.delete){
        console.log(" the id", _id)
        console.log(" the studentname", student_name)
        setdeleteid(_id);
        setdeletename(student_name);
        setDeleteShowModals(true);
    }else{
        toast.error("You don't have the permission");
    }
}

    const handleStatusChange = (e) => {
        const selectedStatus = e.target.value;
        setAddData({ ...addData, status: selectedStatus });

        if (selectedStatus === "Callback") {
            setShowCallbackDate(true);
        } else {
            setShowCallbackDate(false);
            setAddData(prevData => ({
                ...prevData,
                callbackdate: ''
            }));
        }
    };

    const handleStatusChangeupdate = (e) => {
        const selectedStatus = e.target.value;
        setUpdateData({ ...updateData, status: selectedStatus });

        if (selectedStatus === "Callback") {
            setShowCallbackDate(true);
        }
        else {
            setShowCallbackDate(false);
            setUpdateData(prevData => ({
                ...prevData,
                callbackdate: ''
            }));
        }
    };


    const [showCallbackDate, setShowCallbackDate] = useState(false);



    // the pagination ---------------------------------------------------------------------
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState("");
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Search
    const filtered = currentItems.filter((item) =>
        Object.values(item).some(
            (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (typeof value === "number" && value.toString().includes(searchQuery))
        )
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(responseData.length / itemsPerPage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage === totalPages ? prevPage : prevPage + 1
        );
    };

    const goToPrevPage = () => {
        setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
    };
    // the pagination  end ---------------------------------------------------------------------
    ///Add Data
    const [newupdatedata, setnewupdatedata] = useState([])

    const handleupdatesubmit2 = async (e) => {
        e.preventDefault();
        try {
            const formData = {

                branch_id: newupdatedata.branch_id,
                student_name: newupdatedata.student_name,
                mobile_no: newupdatedata.mobile_no,
                email: newupdatedata.email,
                Perfered_course: newupdatedata.selectcourse,
                source_type: newupdatedata.source_type,
                contact_type: newupdatedata.contact_type,
                assign_staff: newupdatedata.assign_staff,
                referal_candidate: newupdatedata.referal_candidate,
                referal_candidate_name: newupdatedata.referal_candidate_name,
                Enquiry_date: newupdatedata.date,



                //     branch_id:"66d00c5f0d2fcb921b0186da" ,
                //     student_name: "raja11212",
                //     mobile_no: "1234567890",
                //     email: "ss@gmail.com",
                //     Perfered_course:"COURSE123",
                //     source_type: "online",
                //     contact_type:"phone",
                //     assign_staff: "66e3ec1d94922b4dbdb7cf7f",
                //     referal_candidate: "66dff873b02d87691a573dc0",  // ObjectId for student or staff
                //  referal_candidate_name: "student"

            }
            console.log("the update submit data", formData)
            const response = await axios.put(apiUrl + `/leads/${updateid}`, formData)
            console.log("response for update", response)
            setUpdateShowModal(false)
            toast.success("Update successfully!");
            fetchData();
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    }


    return (
        <div className='top'>

            <div className='row'>
                <div className='col-lg-9 d-flex'>
                    <h5 className='mt-2'>Enquiry</h5>
                    <div className='mx-3'><button className='btn  add' onClick={handleNewButtonClick}>Add </button></div>
                </div>

            </div>





            {/* ------------ search and per page ----------------------------*/}

            <div className="mb-3 mt-3">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                        <label className='form-label text-dark mt-2'>Show : </label>
                        <div>
                            <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='20'>20</option>
                                <option value='50'>50</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-4 col-sm-1"></div>
                    <div className="col-lg-3 col-md-4 col-sm-7">

                        <div className="w-full flex-1">
                            <form>
                                <div className="relative">

                                    <input
                                        className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                                        placeholder="Search ..."
                                        type="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* --------------------------------end------------------------------------------- */}


            {/* Table */}
            <div className='card'>
                <div className="mt-1">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Mobile No</th>
                                <th>Course</th>
                                <th>Source Type</th>
                                <th>Enquiry Date</th>
                                <th>Status</th>
                                <th className='text-center'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtered
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.student_name}</td>
                                        <td>{item.mobile_no}</td>
                                        <td>{item.Prefered_course}</td>
                                        <td>{item.Source_type}</td>
                                        <td>{formatDatefitching(item.Enquiry_date)}</td>
                                        <td>{item.followups_status}

                                        </td>
                                        <td className='text-center'>
                                            <button className='btn updelete ' onClick={() => handleUpdated(item._id, item)}><i class="fa-solid fa-pen-to-square"></i></button>
                                            <button className='btn delete mx-1' onClick={() => handleOpenModal(item._id, item.student_name)}><i class="fa-solid fa-trash-can"></i></button>

                                            <button className='btn btn-info mx-1' onClick={() => updateshow(item._id, item)}>
                                                <i class="fa-solid fa-user-tag"></i></button>

                                            <button className='btn btn-info' onClick={() => updateinfo(item._id)} >
                                                <i class="fa-solid fa-circle-info"></i></button>

                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>


            {console.log(UpdatedData, "UpdatedData is----")}

            <Modal show={Updateshow} onHide={() => setUpdateShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Enquiry update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Other input fields */}
                    <div className='row'>
                        <div className='col-lg-12 '>
                            <div className=' p-2 pb-0'>
                                <div className="form-group">
                                    <div className='row'>

                                        <div className='col-lg-6'>
                                            <div className="floating-label mt-1">
                                                <label className="form-label">
                                                    <b></b>
                                                </label>
                                                <select
                                                    className="form-select"
                                                    placeholder=''
                                                    value={UpdatedData.branch_id}
                                                    onChange={(e) => setUpdatedData({ ...UpdatedData, branch_id: e.target.value })}
                                                    required
                                                >
                                                    <option value="">Select Branch </option>
                                                    {branch.map((item, index) => (
                                                        <option key={index} value={item._id}>
                                                            {item.branch_name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className={addData.branch_id ? "filled" : ""}>

                                                </label>
                                            </div>


                                            <div className="floating-label">
                                                <input className="form-control" type="text" placeholder='' value={UpdatedData.student_name} onChange={(e) => setUpdatedData({ ...UpdatedData, student_name: e.target.value })} required />
                                                <label className={addData.student_name ? "filled" : ""}>
                                                    Name
                                                </label>
                                            </div>



                                            <div className="floating-label">
                                                <input className="form-control" type="tel" placeholder='' value={UpdatedData.mobile_no} onChange={(e) => setUpdatedData({ ...UpdatedData, mobile_no: e.target.value })} required />
                                                <label className={addData.mobile_no ? "filled" : ""}>
                                                    Mobile No
                                                </label>
                                            </div>

                                            <div className="floating-label">
                                                <input className="form-control" type="email" placeholder='' value={UpdatedData.email} onChange={(e) => setUpdatedData({ ...UpdatedData, email: e.target.value })} required />
                                                <label className={addData.email ? "filled" : ""}>
                                                    Email
                                                </label>
                                            </div>



                                        </div>

                                        <div className='col-lg-6'>


                                            <select
                                                className="form-select"
                                                value={UpdatedData.Prefered_course || ""}
                                                onChange={(e) => setUpdatedData({ ...UpdatedData, Prefered_course: e.target.value })}
                                                required
                                            >
                                                <option value="">Select Course</option>  {/* Ensure value is empty string for default */}
                                                <option value="python">Python</option>    {/* Ensure each option has a value */}
                                                <option value="Full Stack Web Development">Full Stack Web Development</option>
                                            </select>
                                            <label className={UpdatedData.Prefered_course ? "filled" : ""}>

                                            </label>




                                            <div className="floating-label">
                                                <input className="form-control" type="date" placeholder='' value={UpdatedData.Enquiry_date ? formatDatefitching(UpdatedData.Enquiry_date) : ''} onChange={(e) => setUpdatedData({ ...UpdatedData, Enquiry_date: e.target.value })} required />
                                                <label className={UpdatedData.Enquiry_date ? "filled" : ""}>
                                                    Enquiry Date
                                                </label>
                                            </div>



                                            <select
                                                className="form-control"
                                                onChange={(e) => setUpdatedData({ ...UpdatedData, Source_type: e.target.value })}
                                                value={UpdatedData.Source_type || ""}  // Ensure it's linked to state
                                            >
                                                <option value="google Ad">Google Ad</option>
                                                <option value="LinkedIn">LinkedIn</option>
                                                <option value="Facebook">Facebook</option>
                                                <option value="Naukri">Naukri</option>
                                                <option value="Referal">Referral</option>
                                                <option value="Online">Online</option>  {/* Add this option */}
                                            </select>


                                            <div className='col-lg-12 mt-3'>
                                                <div className={`${UpdatedData.Source_type == "Referal" ? "floating-label" : "d-none"}`}>
                                                    <select className="form-control" value={UpdatedData.referal_candidate}
                                                        onChange={(e) => setUpdatedData({ ...UpdatedData, referal_candidate: e.target.value })}


                                                    >
                                                        <option value=''>Select Referal Type</option>
                                                        <option value='student'>Student</option>
                                                        <option value='staff'>Staff</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className='col-lg-12 mt-3'>
                                                {/* Student Referral Dropdown */}
                                                <div className={`${UpdatedData.referal_candidate === "student" && UpdatedData.Source_type === "Referal" ? "floating-label" : "d-none"}`}>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) => setUpdatedData({ ...UpdatedData, referal_candidate_name: e.target.value })}
                                                        value={UpdatedData.referal_candidate_name || ''} // Ensure value is linked to state and handle empty value
                                                    >
                                                        <option value=''>Select student</option>
                                                        {studentId.map((data, index) => (
                                                            <option key={index} value={data._id}>
                                                                {data.student_name} - {data.roll_no}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                {/* Staff Referral Dropdown */}
                                                <div className={`${UpdatedData.referal_candidate === "staff" && UpdatedData.Source_type === "Referal" ? "floating-label" : "d-none"}`}>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) => setUpdatedData({ ...UpdatedData, referal_candidate_name: e.target.value })}
                                                        value={UpdatedData.referal_candidate_name || ''} // Ensure value is linked to state and handle empty value
                                                    >
                                                        <option value=''>Select staff</option>
                                                        {stafftId.map((data, index) => (
                                                            <option key={index} value={data._id}>
                                                                {data.name} - {data.empid}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                        </div>










                                    </div>
                                </div>

                            </div>





                        </div>




                    </div>


                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setUpdateShow(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={() => handleupdatesubmit(UpdatedData._id)}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>






            {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className='insermodal'>
                <Modal className='addmodals' show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Enquiry</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handlesubmit}>
                        <Modal.Body>
                            {/* Other input fields */}
                            <div className='row'>
                                <div className='col-lg-12 '>
                                    <div className=' p-2 pb-0'>
                                        <div className="form-group">
                                            <div className='row'>

                                                <div className='col-lg-6'>
                                                    <div className="floating-label mt-1">
                                                        <label className="form-label">
                                                            <b></b>
                                                        </label>
                                                        <select
                                                            className="form-select"
                                                            placeholder=''
                                                            value={addData.branch_id}
                                                            onChange={(e) => setAddData({ ...addData, branch_id: e.target.value })}
                                                            required
                                                        >
                                                            <option value="">Select Branch </option>
                                                            {branch.map((item, index) => (
                                                                <option key={index} value={item._id}>
                                                                    {item.branch_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className={addData.branch_id ? "filled" : ""}>

                                                        </label>
                                                    </div>



                                                </div>


                                                <div className='col-lg-6'>
                                                    <div className="floating-label">
                                                        <select className="form-select" value={addData.selectcourse} onChange={(e) => setAddData({ ...addData, selectcourse: e.target.value })} required>
                                                            <option value="">Prefered Course</option>
                                                            {responseDatas.map((item, index) => (
                                                                <option key={index} value={item.course_name}>{item.course_name}</option>
                                                            ))}
                                                        </select>
                                                        <label className={addData.selectcourse ? "filled" : ""}>

                                                        </label>
                                                    </div>
                                                </div>


                                                <div className='col-lg-6'>

                                                    <div className="floating-label">
                                                        <input className="form-control" type="text" placeholder='' value={addData.student_name} onChange={(e) => setAddData({ ...addData, student_name: e.target.value })} required />
                                                        <label className={addData.student_name ? "filled" : ""}>
                                                            Student Name
                                                        </label>
                                                    </div>

                                                </div>



                                                <div className='col-lg-6'>
                                                    <div className="floating-label">
                                                        <input className="form-control" type="date" placeholder='' value={addData.date} onChange={(e) => setAddData({ ...addData, date: e.target.value })} required />
                                                        <label className={addData.date ? "filled" : ""}>
                                                            Enquiry Date
                                                        </label>
                                                    </div>
                                                </div>


                                                <div className='col-lg-6'>
                                                    <div className="floating-label">
                                                        <input className="form-control" type="tel" placeholder='' value={addData.mobile_no} onChange={(e) => setAddData({ ...addData, mobile_no: e.target.value })} required />
                                                        <label className={addData.mobile_no ? "filled" : ""}>
                                                            Mobile No
                                                        </label>
                                                    </div>
                                                </div>



                                                <div className='col-lg-6'>
                                                    <div className="floating-label">
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) => setAddData({ ...addData, source_type: e.target.value })} required>
                                                            <option value=""> Source Type</option>
                                                            <option value="google Ad">google Ad</option>
                                                            <option value="Linkedln">Linkedln</option>
                                                            <option value="Facebook">Facebook</option>
                                                            <option value="Naukri">Naukri</option>
                                                            <option value="Referal" >Referal</option>

                                                        </select>
                                                        <label className={addData.source_type ? "filled" : ""}>
                                                            Source Type
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className='col-lg-6'>
                                                    <div className="floating-label">
                                                        <input className="form-control" type="email" placeholder='' value={addData.email} onChange={(e) => setAddData({ ...addData, email: e.target.value })} required />
                                                        <label className={addData.email ? "filled" : ""}>
                                                            Email
                                                        </label>
                                                    </div>
                                                </div>


                                                <div className='col-lg-6'>
                                                    <div className={`${addData.source_type == "Referal" ? "floating-label" : "d-none"}`}>
                                                        <select className="form-control" onClick={handlereferal}>
                                                            <option value=''>Select Referal Type</option>
                                                            <option value='student'>Student</option>
                                                            <option value='staff'>Staff</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className='col-lg-6'>

                                                    <div className={`${referaltype == "student" && addData.source_type == "Referal" ? "floating-label" : "d-none"}`}>

                                                        <select className="form-control" onClick={handleschuduleStatus}>
                                                            <option value=''>Select student</option>

                                                            {
                                                                studentId.map((data, index) => (
                                                                    <option key={index} value={data._id}>
                                                                        {data.student_name} -{data.roll_no}
                                                                    </option>

                                                                ))}
                                                        </select>
                                                    </div>

                                                    <div className={`${referaltype == "staff" && addData.source_type == "Referal" ? "floating-label" : "d-none"}`}>
                                                        <select className="form-control" onClick={handleschuduleStatus}  >
                                                            <option value=''>Select Staff </option>
                                                            {
                                                                stafftId.map((data, index) => (
                                                                    <option key={index} value={data._id}>
                                                                        {data.name} -{data.empid}
                                                                    </option>

                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    {/* <div className="floating-label">
                                        <input className="form-control" type="text" placeholder='' value={addData.father_name} onChange={(e) => setAddData({ ...addData, father_name: e.target.value })} />
                                        <label className={addData.father_name ? "filled" : ""}>
                                            Father Name
                                        </label>
                                    </div> */}


                                    {/* <div className="floating-label">
                                            <input className="form-control" type="text" placeholder='' value={addData.qualification} onChange={(e) => setAddData({ ...addData, qualification: e.target.value })} />
                                            <label className={addData.qualification ? "filled" : ""}>
                                                Qualification
                                            </label>
                                        </div> */}



                                </div>




                            </div>


                        </Modal.Body>

                        <Modal.Footer>
                            <Button className='cancelbut' variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                            <Button className='submitbut' type="submit" variant="primary" >Submit</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>




            {/* ----------------update */}


            <Modal className='addmodals' show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> Update  Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Other input fields */}
                    <div className='row'>
                        <div className='col-lg-12 '>
                            <div className=' p-2 pb-0'>
                                <div className="form-group">
                                    <div className='row'>

                                        <div className='col-lg-6'>
                                            <div className="floating-label mt-1">
                                                <label className="form-label">
                                                    <b></b>
                                                </label>
                                                <select
                                                    className="form-select"
                                                    placeholder=''
                                                    value={UpdatedData.branch_id}
                                                    onChange={(e) => setUpdatedData({ ...UpdatedData, branch_id: e.target.value })}
                                                    required
                                                    disabled
                                                >
                                                    <option value="">Select Branch </option>
                                                    {branch.map((item, index) => (
                                                        <option key={index} value={item._id}>
                                                            {item.branch_name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className={addData.branch_id ? "filled" : ""}>

                                                </label>
                                            </div> </div>
                                        <div className='col-lg-6'>

                                            <div className="floating-label">
                                                <input className="form-control" type="text" placeholder='' disabled value={UpdatedData.student_name} onChange={(e) => setUpdatedData({ ...UpdatedData, student_name: e.target.value })} required />
                                                <label className={addData.student_name ? "filled" : ""}>
                                                    Name
                                                </label>
                                            </div>

                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="floating-label">
                                                <input className="form-control" type="tel" disabled placeholder='' value={UpdatedData.mobile_no} onChange={(e) => setUpdatedData({ ...UpdatedData, mobile_no: e.target.value })} required />
                                                <label className={addData.mobile_no ? "filled" : ""}>
                                                    Mobile No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="floating-label">
                                                <input className="form-control" type="email" disabled placeholder='' value={UpdatedData.email} onChange={(e) => setUpdatedData({ ...UpdatedData, email: e.target.value })} required />
                                                <label className={addData.email ? "filled" : ""}>
                                                    Email
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="floating-label">
                                                <select className="form-select" value={UpdatedData.Prefered_course} disabled onChange={(e) => setUpdatedData({ ...UpdatedData, selectcourse: e.target.value })} required>
                                                    {responseDatas.map((item, index) => (
                                                        <option key={index} value={item.course_name}>{item.course_name}</option>
                                                    ))}
                                                </select>
                                                <label className={addData.selectcourse ? "filled" : ""}>

                                                </label>
                                            </div>
                                        </div>

                                        <div className='col-lg-6'>
                                            <div className="floating-label">
                                                <input className="form-control" type="date" disabled placeholder='' value={UpdatedData.Enquiry_date ? formatDatefitching(UpdatedData.Enquiry_date) : ''} onChange={(e) => setUpdatedData({ ...UpdatedData, Enquiry_date: e.target.value })} required />
                                                <label className={UpdatedData.Enquiry_date ? "filled" : ""}>
                                                    Enquiry Date
                                                </label>
                                            </div>
                                        </div>
                                        <select
                                            className="form-control" disabled
                                            onChange={(e) => setUpdatedData({ ...UpdatedData, Source_type: e.target.value })}
                                            value={UpdatedData.Source_type || ""}  // Ensure it's linked to state
                                        >
                                            <option value="google Ad">Google Ad</option>
                                            <option value="LinkedIn">LinkedIn</option>
                                            <option value="Facebook">Facebook</option>
                                            <option value="Naukri">Naukri</option>
                                            <option value="Referal">Referral</option>
                                            <option value="Online">Online</option>  {/* Add this option */}
                                        </select>


                                        <div className='col-lg-6 mt-3'>
                                            <div className={`${UpdatedData.Source_type == "Referal" ? "floating-label" : "d-none"}`}>
                                                <select disabled className="form-control" value={UpdatedData.referal_candidate}
                                                    onChange={(e) => setUpdatedData({ ...UpdatedData, referal_candidate: e.target.value })}


                                                >
                                                    <option value=''>Select Referal Type</option>
                                                    <option value='student'>Student</option>
                                                    <option value='staff'>Staff</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 mt-3'>
                                            {/* Student Referral Dropdown */}
                                            <div className={`${UpdatedData.referal_candidate === "student" && UpdatedData.Source_type === "Referal" ? "floating-label" : "d-none"}`}>
                                                <select
                                                    className="form-control"
                                                    disabled
                                                    onChange={(e) => setUpdatedData({ ...UpdatedData, referal_candidate_name: e.target.value })}
                                                    value={UpdatedData.referal_candidate_name || ''} // Ensure value is linked to state and handle empty value
                                                >
                                                    <option value=''>Select student</option>
                                                    {studentId.map((data, index) => (
                                                        <option key={index} value={data._id}>
                                                            {data.student_name} - {data.roll_no}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {/* Staff Referral Dropdown */}
                                            <div className={`${UpdatedData.referal_candidate === "staff" && UpdatedData.Source_type === "Referal" ? "floating-label" : "d-none"}`}>
                                                <select
                                                    className="form-control"
                                                    disabled onChange={(e) => setUpdatedData({ ...UpdatedData, referal_candidate_name: e.target.value })}
                                                    value={UpdatedData.referal_candidate_name || ''} // Ensure value is linked to state and handle empty value
                                                >
                                                    <option value=''>Select staff</option>
                                                    {stafftId.map((data, index) => (
                                                        <option key={index} value={data._id}>
                                                            {data.name} - {data.empid}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </div>

                            {/* <div className="floating-label">
                                        <input className="form-control" type="text" placeholder='' value={addData.father_name} onChange={(e) => setAddData({ ...addData, father_name: e.target.value })} />
                                        <label className={addData.father_name ? "filled" : ""}>
                                            Father Name
                                        </label>
                                    </div> */}


                            {/* <div className="floating-label">
                                            <input className="form-control" type="text" placeholder='' value={addData.qualification} onChange={(e) => setAddData({ ...addData, qualification: e.target.value })} />
                                            <label className={addData.qualification ? "filled" : ""}>
                                                Qualification
                                            </label>
                                        </div> */}



                        </div>



                        <div className='col-lg-6 mt-3'>
                            <select
                                className="form-control"
                                onChange={(e) => setUpdatedData({ ...UpdatedData, assign_staff: e.target.value })}
                                value={UpdatedData.assign_staff || ''} // Ensure value is linked to state and handle empty value
                            >
                                <option value=''>Assign staff</option>
                                {stafftId.map((data, index) => (
                                    <option key={index} value={data._id}>
                                        {data.name} - {data.empid}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='col-lg-6 mt-3'>
                            <div className="floating-label">
                                <select
                                    className="form-control"
                                    onChange={(e) => setUpdatedData({ ...UpdatedData, contact_type: e.target.value })}
                                    value={UpdatedData.contact_type || ''} // Ensure value is linked to state and handle empty value

                                >
                                    <option value=''>Contact Type</option>
                                    <option value='phone'>Phone Call</option>
                                    <option value='email'>Email</option>
                                    <option value='whatsapp'>Whatsapp</option>
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className="floating-label">
                                <input className="form-control" type="date" placeholder='' value={UpdatedData.followups_date ? formatDatefitching(UpdatedData.followups_date) : ''}
                                    onChange={(e) => setUpdatedData({ ...UpdatedData, followups_date: e.target.value })}

                                    required />
                                <label className={UpdatedData.followups_date ? "filled" : ""}>
                                    Date
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className="floating-label">
                                <input className="form-control" type="time" placeholder='' value={UpdatedData.followups_time}
                                    onChange={(e) => setUpdatedData({ ...UpdatedData, followups_time: e.target.value })} required />
                                <label className={UpdatedData.followups_time ? "filled" : ""}>
                                    Time
                                </label>
                            </div>
                        </div>

                    </div>


                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={() => handleupdatesubmitdetails(UpdatedData._id)}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal >


            {/* ----------------delete */}

            {/* ////////////////////schedule update////////////////// */}





            {/* <Modal className='addmodals' show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> Update Student Detailsssss</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <div className='col-lg-6'>
                        <div className="floating-label mt-1">
                            <label className="form-label">
                                <b></b>
                            </label>
                            <select
                                className="form-select"
                                placeholder=''
                                value={addData.branch_id}
                                onChange={(e) => setAddData({ ...addData, branch_id: e.target.value })}
                                required
                            >
                                <option value="">Select Branch </option>
                                {branch.map((item, index) => (
                                    <option key={index} value={item._id}>
                                        {item.branch_name}
                                    </option>
                                ))}
                            </select>
                            <label className={addData.branch_id ? "filled" : ""}>

                            </label>
                        </div>
                    </div>
                    <div className='col-lg-6'>

                        <div className="floating-label">
                            <input className="form-control" type="text" placeholder='' value={addData.student_name} onChange={(e) => setAddData({ ...addData, student_name: e.target.value })} required />
                            <label className={addData.student_name ? "filled" : ""}>
                                Student Name
                            </label>
                        </div>

                    </div>
                    <div className='col-lg-6'>
                        <div className="floating-label">
                            <input className="form-control" type="tel" placeholder='' value={addData.mobile_no} onChange={(e) => setAddData({ ...addData, mobile_no: e.target.value })} required />
                            <label className={addData.mobile_no ? "filled" : ""}>
                                Mobile No
                            </label>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className="floating-label">
                            <input className="form-control" type="email" placeholder='' value={addData.email} onChange={(e) => setAddData({ ...addData, email: e.target.value })} required />
                            <label className={addData.email ? "filled" : ""}>
                                Email
                            </label>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className="floating-label">
                            <select className="form-select" value={addData.selectcourse} onChange={(e) => setAddData({ ...addData, selectcourse: e.target.value })} required>
                                <option value="">Prefered Course</option>
                                {responseDatas.map((item, index) => (
                                    <option key={index} value={item.course_name}>{item.course_name}</option>
                                ))}
                            </select>
                            <label className={addData.selectcourse ? "filled" : ""}>

                            </label>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className="floating-label">
                            <input className="form-control" type="date" placeholder='' value={addData.date} onChange={(e) => setAddData({ ...addData, date: e.target.value })} required />
                            <label className={addData.date ? "filled" : ""}>
                                Enquiry Date
                            </label>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className="floating-label">
                            <select
                                className="form-control"
                                onChange={(e) => setAddData({ ...addData, source_type: e.target.value })} required>
                                <option value=""> Source Type</option>
                                <option value="google Ad">google Ad</option>
                                <option value="Linkedln">Linkedln</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Naukri">Naukri</option>
                                <option value="Referal" >Referal</option>

                            </select>
                            <label className={addData.source_type ? "filled" : ""}>
                                Source Type
                            </label>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className={`${addData.source_type == "Referal" ? "floating-label" : "d-none"}`}>
                            <select className="form-control" onClick={handlereferal}>
                                <option value=''>Select Referal Type</option>
                                <option value='student'>Student</option>
                                <option value='staff'>Staff</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-6'>

                        <div className={`${referaltype == "student" && addData.source_type == "Referal" ? "floating-label" : "d-none"}`}>

                            <select className="form-control" onClick={handleschuduleStatus}>
                                <option value=''>Select student</option>

                                {
                                    studentId.map((data, index) => (
                                        <option key={index} value={data._id}>
                                            {data.student_name} -{data.roll_no}
                                        </option>

                                    ))}
                            </select>
                        </div>


                        <div className={`${referaltype == "staff" && addData.source_type == "Referal" ? "floating-label" : "d-none"}`}>
                            <select className="form-control" onClick={handleschuduleStatus}  >
                                <option value=''>Select Staff </option>
                                {
                                    stafftId.map((data, index) => (
                                        <option key={index} value={data._id}>
                                            {data.name} -{data.empid}
                                        </option>

                                    ))}
                            </select>
                        </div>
                    </div>




                </Modal.Body >

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' type='submit' variant="primary" >Update</Button>
                </Modal.Footer>
            </Modal >
 */}





            {/* ///////////////////////////////////////////// */}



            < Modal className="modal" show={deleteShowModals} onHide={handleCloseModal} >
                <Modal.Header>
                    <p>Are you sure you want to delete?</p>
                </Modal.Header>
                <Modal.Body>

                    <label className="form-label d-flex">
                        <h5 className='mx-1'> Student :</h5> <h5>{getdeletename} </h5>
                    </label>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
                </Modal.Footer>
            </Modal >



            {/* _------------------------------pagination button -------------------------------- */}
            < div className="mt-3 pb-5" >
                <div className="row">

                    <div className="col-lg-10 col-sm-12">
                        <nav className="d-flex float-right">
                            <ul className="pagination justify-content-center">
                                <li
                                    className={` page-item ${currentPage === 1 ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link" onClick={goToPrevPage}>
                                        Previous
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <li
                                        key={i}
                                        className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                                            }`}
                                    >
                                        <button
                                            className="page-link "
                                            onClick={() => paginate(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link " onClick={goToNextPage}>
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>{" "}
                    </div>
                    <div className="col-lg-2 col-sm-12 mt-1">
                        <div className="float-end">
                            <span className="text-dark ">
                                Page {currentPage} of {totalPages}
                            </span>
                        </div>
                    </div>

                </div>
            </div >


            {/* ---------------------------------------end ---------------------------------- */}



            {/*  
            <Modal show={isUpdateshowModal1} onHide={() => setUpdateShowModal1(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Leave</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='col-lg-6'>
                        <div className="floating-label mt-1">
                            <label className="form-label">
                                <b></b>
                            </label>
                            <select
                                className="form-select"
                                placeholder=''
                                value={addData.branch_id}
                                onChange={(e) => setAddData({ ...addData, branch_id: e.target.value })}
                                required
                            >
                                <option value="">Select Branch </option>
                                {branch.map((item, index) => (
                                    <option key={index} value={item._id}>
                                        {item.branch_name}
                                    </option>
                                ))}
                            </select>
                            <label className={addData.branch_id ? "filled" : ""}>

                            </label>
                        </div>
                    </div>
                    <div className='col-lg-6'>

                        <div className="floating-label">
                            <input className="form-control" type="text" placeholder='' value={addData.student_name} onChange={(e) => setAddData({ ...addData, student_name: e.target.value })} required />
                            <label className={addData.student_name ? "filled" : ""}>
                                Student Name
                            </label>
                        </div>

                    </div>
                    <div className='col-lg-6'>
                        <div className="floating-label">
                            <input className="form-control" type="tel" placeholder='' value={addData.mobile_no} onChange={(e) => setAddData({ ...addData, mobile_no: e.target.value })} required />
                            <label className={addData.mobile_no ? "filled" : ""}>
                                Mobile No
                            </label>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className="floating-label">
                            <input className="form-control" type="email" placeholder='' value={addData.email} onChange={(e) => setAddData({ ...addData, email: e.target.value })} required />
                            <label className={addData.email ? "filled" : ""}>
                                Email
                            </label>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className="floating-label">
                            <select className="form-select" value={addData.selectcourse} onChange={(e) => setAddData({ ...addData, selectcourse: e.target.value })} required>
                                <option value="">Prefered Course</option>
                                {responseDatas.map((item, index) => (
                                    <option key={index} value={item.course_name}>{item.course_name}</option>
                                ))}
                            </select>
                            <label className={addData.selectcourse ? "filled" : ""}>

                            </label>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className="floating-label">
                            <input className="form-control" type="date" placeholder='' value={addData.date} onChange={(e) => setAddData({ ...addData, date: e.target.value })} required />
                            <label className={addData.date ? "filled" : ""}>
                                Enquiry Date
                            </label>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className="floating-label">
                            <select
                                className="form-control"
                                onChange={(e) => setAddData({ ...addData, source_type: e.target.value })} required>
                                <option value=""> Source Type</option>
                                <option value="google Ad">google Ad</option>
                                <option value="Linkedln">Linkedln</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Naukri">Naukri</option>
                                <option value="Referal" >Referal</option>

                            </select>
                            <label className={addData.source_type ? "filled" : ""}>
                                Source Type
                            </label>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className={`${addData.source_type == "Referal" ? "floating-label" : "d-none"}`}>
                            <select className="form-control" onClick={handlereferal}>
                                <option value=''>Select Referal Type</option>
                                <option value='student'>Student</option>
                                <option value='staff'>Staff</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-6'>

                        <div className={`${referaltype == "student" && addData.source_type == "Referal" ? "floating-label" : "d-none"}`}>

                            <select className="form-control" onClick={handleschuduleStatus}>
                                <option value=''>Select student</option>

                                {
                                    studentId.map((data, index) => (
                                        <option key={index} value={data._id}>
                                            {data.student_name} -{data.roll_no}
                                        </option>

                                    ))}
                            </select>
                        </div>


                        <div className={`${referaltype == "staff" && addData.source_type == "Referal" ? "floating-label" : "d-none"}`}>
                            <select className="form-control" onClick={handleschuduleStatus}  >
                                <option value=''>Select Staff </option>
                                {
                                    stafftId.map((data, index) => (
                                        <option key={index} value={data._id}>
                                            {data.name} -{data.empid}
                                        </option>

                                    ))}
                            </select>
                        </div>
                    </div>

                   
                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handleupdatesubmit2}>Update</Button>
                </Modal.Footer>
            </Modal>  
            */}
        </div >





    )
}
export default Student;