import React, { useState, useEffect } from 'react';
import { FaTh, FaBars } from 'react-icons/fa';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Navbarstaff from '../../staffadmin/sidebar/Navbarstaff'; 
import '../../../assets/css/Sidebar.css';
import Footer from '../../footer/Footer';


const Sidebarstaff = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [dropdownStates, setDropdownStates] = useState({}); // State to manage dropdown visibility
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    const toggle = () => setIsOpen(!isOpen);

    const menuItem = [
        // {
        //     path: "/",
        //     name: "Login",
        //     icon: <i class="fa-solid fa-bars"></i>
        // },
        // {
        //     path: "/",
        //     name: "Dashboard",
        //     icon: <FaTh />
        // },
        {
            path: `/profile/${localStorage.getItem('id')}`,
            name: "Profile",
            icon: <i className="fa-solid fa-layer-group"></i>
        },
        {
            path: "/task",
            name: "Daily Task",
            icon: <i class="fa-regular fa-compass"></i>,
        },
        {
            path: "/leavestaff",
            name: "Leave Request",
         
            icon: <i className="fa-regular fa-pen-to-square"></i>,
            // Add subItems for dropdown
           
        },
        {
            path: "/attendancestaff",
            name: "Attendance",
            icon: <i className="fa-solid fa-user-tie"></i>,
           
        },
       
       
    ];

    const handleDropdownToggle = (index) => {
        setDropdownStates(prevState => {
            const newState = { ...prevState };
            Object.keys(newState).forEach(key => {
                if (key !== String(index)) {
                    newState[key] = false;
                }
            });

            newState[index] = !newState[index];
    
            return newState;
        });
    };

    const handleUserItemClick = () => {
        // Close all dropdowns when clicking on the "Role Name" item
        setDropdownStates({});
    };
  

    if (location.pathname === '/register' || location.pathname === '*' || location.pathname.startsWith('/invoice/invoiceprint/') || location.pathname.startsWith('/invoice/bill/')) {
        return <main style={{ padding: isOpen ? '20px' : '0px', marginTop: isOpen ? '0px' : '0px' }}>{children}</main>;
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }
  
    const handleLogout = () => {
        const token = localStorage.getItem('token');
if (token) {
   localStorage.removeItem('token');
   localStorage.removeItem('id');
   navigate('/');
   window.location.reload();
} else {
   localStorage.removeItem('token');
   localStorage.removeItem('id');
   navigate('/');
   window.location.reload();
}

};


    return (
        <>
              <Navbarstaff toggle={toggle} />
            <Modal className="modal" show={showModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <p>Are you sure you want to logout?</p>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleLogout}>Logout</Button>
                </Modal.Footer>
            </Modal>

            <div className="container-main">
                <div className="sidebar" style={{ position: 'fixed', top: '70px', bottom: 0, left: 0, width: isOpen ? "58px" : "250px", zIndex: 1000 }}>
                    {/* <div className="top_section">
                        <p style={{ display: isOpen ? "none" : "block" }} className="logo"><i className="fa-regular fa-circle-user sidebarusericon mx-1"></i> Admin</p>
                        <div style={{ marginLeft: isOpen ? "7px" : "105px" }} className="bars">
                            <FaBars onClick={toggle} />
                        </div>
                    </div> */}
                     <div className="top_section">
            <p
                style={{ display: isOpen ? "none" : "block" }}
                className={`logo mx-1 ${isOpen ? '' : 'd-flex'}`}
            >
                <i className="fa-regular fa-circle-user sidebarusericon mx-1 mt-1"></i>
                <div className='admincont'>Admin</div>
            </p>
            <div style={{ marginLeft: isOpen ? "7px" : "100px" }} className="bars">
                <FaBars onClick={toggle} />
            </div>
        </div>
                    {menuItem.map((item, index) => (
                        <div key={index}>
                            {item.subItems ? (
                                <>
                                    <div className="link" onClick={() => handleDropdownToggle(index)}>
                                        <div className="icon">{item.icon}</div>
                                        <div style={{ display: isOpen ? "none" : "flex" }} className="link_text">{item.name}</div>
                                        <div className="dropdown_icon">{dropdownStates[index] ? <i className="fa-solid fa-caret-up"></i> : <i className="fa-solid fa-caret-down"></i>}</div>
                                    </div>
                                    {dropdownStates[index] && (
                                        <div className="subMenu" style={{ marginLeft: isOpen ? '7px' : '30px' }}>
                                            {item.subItems.map((subItem, subIndex) => (
                                                <NavLink
                                                    key={subIndex}
                                                    to={subItem.path}
                                                    className="link"
                                                    activeClassName="active"
                                                    style={{ display: location.pathname === '/register' ? 'none' : 'flex' }}
                                                >
                                                    <div className="icon">{subItem.icon}</div>
                                                    <div style={{ display: isOpen ? "none" : "block" }} className="link_text">{subItem.name}</div>
                                                </NavLink>
                                            ))}
                                        </div>
                                    )}
                                </>
                            ) : (
                                <NavLink
                                    to={item.path}
                                    key={index}
                                    className="link"
                                    activeClassName="active"
                                    style={{ display: location.pathname === '/register' ? 'none' : 'flex' }}
                                    onClick={
                                        item.path === '/user' ? handleUserItemClick : 
                                        item.path === '/role' ? handleUserItemClick : null ||
                                        item.path === '/invoice' ? handleUserItemClick : null 
                                    }
                                >
                                    <div className="icon">{item.icon}</div>
                                    <div style={{ display: isOpen ? "none" : "block" }} className="link_text">{item.name}</div>
                                </NavLink>
                            )}
                        </div>
                    ))}
                    <div className="link" onClick={() => setShowModal(true)}>
                        <div className="icon"><i className="fa-solid fa-right-from-bracket"></i></div>
                        <div style={{ display: isOpen ? "none" : "block" }} className="link_text">Logout</div>
                    </div>
                </div>
                <main className={`main ${isOpen ? "isClose" : "isOpen"}`}>
                    {children}

                    <Footer/>
                </main>
            </div>

        </>
    );
};

export default Sidebarstaff;
