import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api";
import "../../assets/css/Studentinfo.css";

function StudentInfo() {
  const [activeTab, setActiveTab] = useState("profile");
  const [student, setStudent] = useState([]); // Initialize as null
  const [studentBranch, setStudentBranch] = useState(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const { id } = useParams();

  useEffect(() => {
    fetchDatas();
  }, [id]); // Fetch data whenever id changes

  const fetchDatas = async () => {
    try {
      console.log("fetchDatas called");
      console.log("id is: ", id);

      // Fetching student data
      const responseStudent = await axios.get(apiUrl + `/studingstudent/${id}`);
      console.log("from the server:", responseStudent.data);
      setStudent(responseStudent.data);

      // Fetching student branch data
      const responseBranch = await axios.get(
        apiUrl + `/get_student_branch/${id}`
      );
      console.log("branch data from the server:", responseBranch.data);
      setStudentBranch(responseBranch.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  // Log student data whenever it changes
  useEffect(() => {
    if (student) {
      console.log("The student data is: ", student);
    }
  }, [student]);

  return (
    <div className="marginup">
      {/* Breadcrumbs */}
      <div className="row">
        <div className="col-lg-11">
          <Link to="/dashboard" className="text-secondary dashBoard_text">
            <b>Dashboard / </b>
          </Link>
          <Link to="/student" className="text-secondary dashBoard_text">
            <b>Student Details / </b>
          </Link>
          <Link to="" className="text-danger dashBoard_text">
            <b>Profile</b>
          </Link>
        </div>
      </div>

      {/* Tabs */}
      <div className="row mt-2">
        <div className="col-lg-3">
          <h5>
            <b>Student Profile</b>
          </h5>
        </div>
        <div className="col-lg-4"></div>
        <div className="col-lg-5 ">
          <div className="row">
            <div className="col-lg-3">
              <div
                className={`card cardeds ${
                  activeTab === "profile" ? "bg-success" : "bg-danger"
                }`}
                onClick={() => handleTabClick("profile")}
              >
                <button className="btn text-white timeline">
                  <i className="fa-solid fa-user mx-1"></i> Profile
                </button>
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className={`card cardeds ${
                  activeTab === "fees" ? "bg-success" : "bg-secondary"
                }`}
                onClick={() => handleTabClick("fees")}
              >
                <button className="btn text-white timeline">
                  <i className="fa-solid fa-indian-rupee-sign mx-1"></i> Fees
                  Details
                </button>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className={`card cardeds ${
                  activeTab === "timeline" ? "bg-success" : "bg-secondary"
                }`}
                onClick={() => handleTabClick("timeline")}
              >
                <button className="btn text-white timeline">
                  <i className="fa-solid fa-hourglass-start mx-1"></i> Timeline
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Student Details */}
      <div className="row mt-2">
        <div className="col-lg-3 col-sm-3 col-md-3">
          <div className="card carddetails p-2">
            <div className="imgdetails">
              <img
                className="profileimg"
                src={require("../../assets/img/images.jpg")}
                alt="Profile"
              />
            </div>
            <div className="text-center mt-2 text-white">
              <h6 className="profile-details">
                <b>{student[0]?.leads_id[0].student_name || ""}</b>
              </h6>
              <h6 className="profile-details">
                {student[0]?.leads_id[0].mobile_no || ""}
              </h6>
              <h6 className="profile-details">{student[0]?.roll_no || ""}</h6>
              <h6 className="profile-details">
                {student[0]?.leads_id[0].Prefered_course || ""}
              </h6>
            </div>
          </div>
        </div>

        {/* Profile Information */}
        <div
          className={`${
            activeTab === "profile" ? "col-lg-6 col-sm-6 col-md-6" : "d-none"
          }`}
        >
          <div className="card cardborder p-1">
            <div className="row p-1">
              <div className="col-lg-6 col-md-6 col-sm-6 text-danger">
                <b>Profile Info</b>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 text-end editdetails">
                <b>+ Edit Details</b>
              </div>
            </div>
          </div>
          <div className="card cardborder">
            <div className="lightblue p-2">
              <div className="row p-1">
                <div className="col-lg-5">
                  <strong>Branch </strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {studentBranch?.branch_name || ""}
                </div>
              </div>
              <div className="row p-1">
                <div className="col-lg-5">
                  <strong>Father Name </strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student[0]?.father_name || ""}
                </div>
              </div>
              <div className="row mt-1 p-1">
                <div className="col-lg-5">
                  <strong>Qualification</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student[0]?.qualification || ""}
                </div>
              </div>
              <div className="row mt-1 p-1">
                <div className="col-lg-5">
                  <strong>Course</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student[0]?.leads_id[0].Prefered_course || ""}
                </div>
              </div>
              <div className="row mt-1 p-1">
                <div className="col-lg-5">
                  <strong>Admission Date</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student[0]?.admission_date || ""}
                </div>
              </div>
              <div className="row p-1">
                <div className="col-lg-5">
                  <strong>Date of Birth</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student[0]?.date_of_birth || ""}
                </div>
              </div>
              <div className="row p-1">
                <div className="col-lg-5">
                  <strong>Email</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student[0]?.leads_id[0].email || ""}
                </div>
              </div>
              <div className="row mt-1 p-1">
                <div className="col-lg-5">
                  <strong>Student Type</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student[0]?.student_type || ""}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Fees and Timeline tabs (Placeholder) */}
        <div
          className={`${
            activeTab === "fees" ? "col-lg-6 col-sm-6 col-md-6" : "d-none"
          }`}
        >
          <div className="row cardborder ">
            <div className="card cardborder p-1">
              <div className="row p-2">
                <div className="col-lg-6">
                  <b>Fees Details</b>
                </div>
              </div>
            </div>
            <div className="card cardborder">
              <table className="table table-striped responsive">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date </th>
                    <th>Paid</th>
                    <th>Pay Type</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          className={`${
            activeTab === "timeline" ? "col-lg-6 col-sm-6 col-md-6" : "d-none"
          }`}
        >
          <div className="row cardborder ">
            <div className="card cardborder p-1">
              <div className="row p-2">
                <div className="col-lg-6">
                  <b>Timeline</b>
                </div>
              </div>
            </div>
            <div className="card cardborder">
              <table className="table table-striped responsive">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date </th>
                    <th>Time In</th>
                    <th>Time Out</th>
                    <th>Topic</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-3 col-md-3">
          <div className="card carddetails p-1">
            <div className="row p-1">
              <div className="col-lg-12 text-danger">
                <b>Payment/Duration</b>
              </div>
            </div>
          </div>
          <div className="card carddetails details-data_overall p-1">
            <div className="row details-data">
              <div className="col-lg-6 mt-1">
                <b>Total Fees</b>
              </div>
              <div className="col-lg-6 text-secondary">
                {student[0]?.fees || ""}
              </div>
            </div>
            <div className="row mt-2 details-data">
              <div className="col-lg-6 mt-1">
                <b>Paid</b>
              </div>
              <div className="col-lg-6 text-secondary"></div>
            </div>
            <div className="row mt-2 details-data">
              <div className="col-lg-6 mt-1">
                <b>Balance</b>
              </div>
              <div className="col-lg-6 text-secondary"></div>
            </div>
            <div className="row mt-2 details-data">
              <div className="col-lg-6 mt-1">
                <b>Duration</b>
              </div>
              <div className="col-lg-6 text-secondary">1/45</div>
            </div>
          </div>
          <div className="col-lg-12 col-sm-12 col-md-12 mt-3">
            <div className="card cardborder ">
              <b className="text-danger p-1">Topics Covered</b>
            </div>
            <div className="card cardborder p-1 Completed-profile">
              Java Completed
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentInfo;
