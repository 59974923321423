import React from "react";
import "../../assets/css/DailyReport.css"; 

function DailyReport() {
    return (
      <>
        <div
          className="card p-2"
          style={{
            marginTop: "-80px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-6 pt-2">
              <h6 className="text-center">
                <b>Daily Report</b>
              </h6>
            </div>
          </div>
  
          <hr></hr>
          <div className="row p-3">
            <div className="col-lg-3 col-md-3 col-sm-12 text-center ">
              <div className="card text-center border-3">
                <h6 className="bg-secondary text-white py-1">Opening Balance</h6>
                <h3 className="">Rs. 06</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="card text-center border-3">
                <h6 className="bg-secondary text-white py-1">Today Sales</h6>
                <div className="row text-center">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <p className="">Cash</p>
                    <h5 className="">Rs. </h5>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <p className="">UPI</p>
                    <h5 className="">Rs. </h5>
                  </div>
                </div>
                {/* <h3 className="">Rs. 06</h3> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-5 col-sm-12">
              <div className="card text-center border-3">
                <h6 className="bg-secondary text-white py-1">
                  Today Events & Shoots
                </h6>
                <div className="row text-center">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <p className="">Cash</p>
                    <h5 className="">Rs. </h5>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <p className="">UPI</p>
                    <h5 className="">Rs. </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div className="row p-3">
            <div className="col-lg-3 col-md-3 col-sm-12 text-center ">
              <div className="card text-center border-3">
                <h6 className="bg-secondary text-white py-1">Closing Balance</h6>
                <h3 className="">Rs. 06</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="card text-center border-3">
                <h6 className="bg-secondary text-white py-1">Today Purchase</h6>
                <div className="row text-center">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <p className="">Cash</p>
                    <h5 className="">Rs. </h5>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <p className="">UPI</p>
                    <h5 className="">Rs. </h5>
                  </div>
                </div>
                {/* <h3 className="">Rs. 06</h3> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-5 col-sm-12">
              <div className="card text-center border-3">
                <h6 className="bg-secondary text-white py-1">Today Expense</h6>
                <div className="row text-center">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <p className="">Cash</p>
                    <h5 className="">Rs. </h5>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <p className="">UPI</p>
                    <h5 className="">Rs. </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="row text-center p-2">
            <div className="col-lg-4">
              <p className="">
                <b>Date</b>
              </p>
              <input type="date" className="form-control"></input>
            </div>
            <div className="col-lg-2">
              <p className="">
                <b>Click</b>
              </p>
              <button className="btn border">
                <i class="fa-solid fa-filter text-danger"></i>
              </button>
            </div>
          </div>
        </div>
  
        <div className="container mt-5 bg-white">
          <div className="row">
            <h6 className="text-center">
              <b>Sales Report</b>
            </h6>
          </div>
        </div>
  
        <div className="card-body p-3" style={{ backgroundColor: "#E8EFF9" }}>
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="">
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Copy
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  CSV
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Excel
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  PDF
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Print
                </button>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <input
                className="form-control border-3"
                placeholder="Search"
              ></input>
            </div>
          </div>
          <div className="row p-2">
            <div style={{ overflowX: "auto" }}>
              <table
                className="table table-border dataTable no-footer tableresponsive"
                id="datatable"
                width="100%"
                role="grid"
                aria-describedby="datatable_info"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr role="row">
                    <th
                      style={{ color: "black", width: "155px" }}
                      className="sorting_desc"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Order No: activate to sort column ascending"
                      aria-sort="descending"
                    >
                      Order No
                    </th>
                    <th
                      style={{ color: "black", width: "253px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Customer Name: activate to sort column ascending"
                    >
                      Customer Name
                    </th>
                    <th
                      style={{ color: "black", width: "126px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Mobile
                    </th>
                    <th
                      style={{ color: "black", width: "108px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Email: activate to sort column ascending"
                    >
                      Email
                    </th>
                    <th
                      style={{ color: "black", width: "95px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Date: activate to sort column ascending"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="odd">
                    <td
                      valign="top"
                      colSpan="5"
                      className="dataTables_empty text-center"
                    >
                      No data available in table
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className="dataTables_info"
                    id="datatable_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing 0 to 0 of 0 entries
                  </div>
                </div>
                <div className="col-lg-5"></div>
                <div className="col-lg-3">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="datatable_paginate"
                  >
                    <button className="btn border">
                      <a
                        className="text-dark "
                        aria-controls="datatable"
                        data-dt-idx="0"
                        tabIndex="-1"
                        id="datatable_previous"
                      >
                        Previous
                      </a>
                    </button>
                    <span> / </span>
                    <button className="btn border ">
                      <a
                        className="text-dark "
                        aria-controls="datatable"
                        data-dt-idx="1"
                        tabIndex="-1"
                        id="datatable_next"
                      >
                        Next
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className="container mt-5 bg-white">
          <div className="row">
            <h6 className="text-center">
              <b>Bookings Customer Report</b>
            </h6>
          </div>
        </div>
  
        <div className="card-body p-3" style={{ backgroundColor: "#E8EFF9" }}>
          <div className="row">
            <div className="col-lg-8">
              <div className="">
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Copy
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  CSV
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Excel
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  PDF
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Print
                </button>
              </div>
            </div>
            <div className="col-lg-4">
              <input
                className="form-control border-3"
                placeholder="Search"
              ></input>
            </div>
          </div>
          <div className="row p-2">
            <div style={{ overflowX: "auto" }}>
              <table
                className="table table-border dataTable no-footer"
                id="datatable"
                width="100%"
                role="grid"
                aria-describedby="datatable_info"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr role="row">
                    <th
                      style={{ color: "black", width: "155px" }}
                      className="sorting_desc"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Order No: activate to sort column ascending"
                      aria-sort="descending"
                    >
                      Order No
                    </th>
                    <th
                      style={{ color: "black", width: "253px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Customer Name: activate to sort column ascending"
                    >
                      Customer Name
                    </th>
                    <th
                      style={{ color: "black", width: "126px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Mobile
                    </th>
                    <th
                      style={{ color: "black", width: "108px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Email: activate to sort column ascending"
                    >
                      Email
                    </th>
                    <th
                      style={{ color: "black", width: "95px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Date: activate to sort column ascending"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="odd">
                    <td
                      valign="top"
                      colSpan="5"
                      className="dataTables_empty text-center"
                    >
                      No data available in table
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className="dataTables_info"
                    id="datatable_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing 0 to 0 of 0 entries
                  </div>
                </div>
                <div className="col-lg-5"></div>
                <div className="col-lg-3">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="datatable_paginate"
                  >
                    <button className="btn border">
                      <a
                        className="text-dark "
                        aria-controls="datatable"
                        data-dt-idx="0"
                        tabIndex="-1"
                        id="datatable_previous"
                      >
                        Previous
                      </a>
                    </button>
                    <span> / </span>
                    <button className="btn border ">
                      <a
                        className="text-dark "
                        aria-controls="datatable"
                        data-dt-idx="1"
                        tabIndex="-1"
                        id="datatable_next"
                      >
                        Next
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className="container mt-5 bg-white">
          <div className="row">
            <h6 className="text-center">
              <b>Purchase Report</b>
            </h6>
          </div>
        </div>
  
        <div className="card-body p-3" style={{ backgroundColor: "#E8EFF9" }}>
          <div className="row">
            <div className="col-lg-8">
              <div className="">
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Copy
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  CSV
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Excel
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  PDF
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Print
                </button>
              </div>
            </div>
            <div className="col-lg-4">
              <input
                className="form-control border-3"
                placeholder="Search"
              ></input>
            </div>
          </div>
          <div className="row p-2">
            <div style={{ overflowX: "auto" }}>
              <table
                className="table table-border dataTable no-footer"
                id="datatable"
                width="100%"
                role="grid"
                aria-describedby="datatable_info"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#0080ff" }} role="row">
                    <th
                      style={{ color: "black", width: "155px" }}
                      className="sorting_desc"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Order No: activate to sort column ascending"
                      aria-sort="descending"
                    >
                      Order No
                    </th>
                    <th
                      style={{ color: "black", width: "253px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Customer Name: activate to sort column ascending"
                    >
                      Customer Name
                    </th>
                    <th
                      style={{ color: "black", width: "126px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Mobile
                    </th>
                    <th
                      style={{ color: "black", width: "108px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Email: activate to sort column ascending"
                    >
                      Email
                    </th>
                    <th
                      style={{ color: "black", width: "95px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Date: activate to sort column ascending"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="odd">
                    <td
                      valign="top"
                      colSpan="5"
                      className="dataTables_empty text-center"
                    >
                      No data available in table
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className="dataTables_info"
                    id="datatable_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing 0 to 0 of 0 entries
                  </div>
                </div>
                <div className="col-lg-5"></div>
                <div className="col-lg-3">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="datatable_paginate"
                  >
                    <button className="btn border">
                      <a
                        className="text-dark "
                        aria-controls="datatable"
                        data-dt-idx="0"
                        tabIndex="-1"
                        id="datatable_previous"
                      >
                        Previous
                      </a>
                    </button>
                    <span> / </span>
                    <button className="btn border ">
                      <a
                        className="text-dark "
                        aria-controls="datatable"
                        data-dt-idx="1"
                        tabIndex="-1"
                        id="datatable_next"
                      >
                        Next
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className="container mt-5 bg-white">
          <div className="row">
            <h6 className="text-center">
              <b>Enquiry Report</b>
            </h6>
          </div>
        </div>
  
        <div className="card-body p-3" style={{ backgroundColor: "#E8EFF9" }}>
          <div className="row">
            <div className="col-lg-8">
              <div className="">
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Copy
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  CSV
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Excel
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  PDF
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Print
                </button>
              </div>
            </div>
            <div className="col-lg-4">
              <input
                className="form-control border-3"
                placeholder="Search"
              ></input>
            </div>
          </div>
          <div className="row p-2">
            <div style={{ overflowX: "auto" }}>
              <table
                className="table table-border dataTable no-footer"
                id="datatable"
                width="100%"
                role="grid"
                aria-describedby="datatable_info"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#0080ff" }} role="row">
                    <th
                      style={{ color: "black", width: "155px" }}
                      className="sorting_desc"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Order No: activate to sort column ascending"
                      aria-sort="descending"
                    >
                      Order No
                    </th>
                    <th
                      style={{ color: "black", width: "253px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Customer Name: activate to sort column ascending"
                    >
                      Customer Name
                    </th>
                    <th
                      style={{ color: "black", width: "126px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Mobile
                    </th>
                    <th
                      style={{ color: "black", width: "108px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Email: activate to sort column ascending"
                    >
                      Email
                    </th>
                    <th
                      style={{ color: "black", width: "95px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Date: activate to sort column ascending"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="odd">
                    <td
                      valign="top"
                      colSpan="5"
                      className="dataTables_empty text-center"
                    >
                      No data available in table
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className="dataTables_info"
                    id="datatable_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing 0 to 0 of 0 entries
                  </div>
                </div>
                <div className="col-lg-5"></div>
                <div className="col-lg-3">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="datatable_paginate"
                  >
                    <button className="btn border">
                      <a
                        className="text-dark "
                        aria-controls="datatable"
                        data-dt-idx="0"
                        tabIndex="-1"
                        id="datatable_previous"
                      >
                        Previous
                      </a>
                    </button>
                    <span> / </span>
                    <button className="btn border ">
                      <a
                        className="text-dark "
                        aria-controls="datatable"
                        data-dt-idx="1"
                        tabIndex="-1"
                        id="datatable_next"
                      >
                        Next
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className="container mt-5 bg-white">
          <div className="row">
            <h6 className="text-center">
              <b>Expense Report</b>
            </h6>
          </div>
        </div>
  
        <div className="card-body p-3" style={{ backgroundColor: "#E8EFF9" }}>
          <div className="row">
            <div className="col-lg-8">
              <div className="">
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Copy
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  CSV
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Excel
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  PDF
                </button>
                <button
                  className="btn border mx-1"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  Print
                </button>
              </div>
            </div>
            <div className="col-lg-4">
              <input
                className="form-control border-3"
                placeholder="Search"
              ></input>
            </div>
          </div>
          <div className="row p-2">
            <div style={{ overflowX: "auto" }}>
              <table
                className="table table-border dataTable no-footer"
                id="datatable"
                width="100%"
                role="grid"
                aria-describedby="datatable_info"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#0080ff" }} role="row">
                    <th
                      style={{ color: "black", width: "155px" }}
                      className="sorting_desc"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Order No: activate to sort column ascending"
                      aria-sort="descending"
                    >
                      Order No
                    </th>
                    <th
                      style={{ color: "black", width: "253px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Customer Name: activate to sort column ascending"
                    >
                      Customer Name
                    </th>
                    <th
                      style={{ color: "black", width: "126px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Mobile
                    </th>
                    <th
                      style={{ color: "black", width: "108px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Email: activate to sort column ascending"
                    >
                      Email
                    </th>
                    <th
                      style={{ color: "black", width: "95px" }}
                      className="sorting"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Date: activate to sort column ascending"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="odd">
                    <td
                      valign="top"
                      colSpan="5"
                      className="dataTables_empty text-center"
                    >
                      No data available in table
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className="dataTables_info"
                    id="datatable_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing 0 to 0 of 0 entries
                  </div>
                </div>
                <div className="col-lg-5"></div>
                <div className="col-lg-3">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="datatable_paginate"
                  >
                    <button className="btn border">
                      <a
                        className="text-dark "
                        aria-controls="datatable"
                        data-dt-idx="0"
                        tabIndex="-1"
                        id="datatable_previous"
                      >
                        Previous
                      </a>
                    </button>
                    <span> / </span>
                    <button className="btn border ">
                      <a
                        className="text-dark "
                        aria-controls="datatable"
                        data-dt-idx="1"
                        tabIndex="-1"
                        id="datatable_next"
                      >
                        Next
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className="container mt-5">
          <div className="row" style={{ fontSize: "13px", fontWeight: "bold" }}>
            <div className="col-lg-9 col-md-9 col-sm-6">
              <p className="">
                Copyright ©<span className="text-danger"> SCIMITAR BILLING </span>
                2024
              </p>
            </div>
  
            <div className="col-lg-3 col-md-12 col-sm-6">
              <p className="text-danger">Developed by aalantechsoft</p>
            </div>
          </div>
        </div>
      </>
    );
  }
  export default DailyReport;
  