import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api";
import { Modal, Button } from 'react-bootstrap';
import { toast } from "react-toastify";

function Roles() {

    const [showModals, setShowModals] = useState(false);
    const navigate = useNavigate();
    const [roleData, setRoleData] = useState({
        role: '',
        modules: [],
        privileges: []
    });

    //get roles data
    const [data, setData] = useState([])
    const getData = async () => {
        try {
            const response = await axios.get(apiUrl + `/role/student`);
            if (response) {
                if (response.status === 200) {
                    console.log("adminrole get", response.data)
                    setData(response.data)
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }


    const [values, setall] = useState([])

    const getall = async () => {
        try {
            // const response = await axios.get(apiUrl + '/allrole');
            const response = await axios.get(apiUrl + '/role_2');
            if (response) {
                if (response.status === 200) {
                    console.log("admin role get", response.data[0].id)
                    setall(response.data)
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }



    useEffect(() => {
        getData();
        getall();
        getpermission();
    }, [])


    const [permissionDatas, setpermissionDatas] = useState([])
    const getpermission = async () => {
        const admin_id = localStorage.getItem('admin_id')
        const page = "role"
        console.log("the admin_id", admin_id + "" + page)
        try {
            console.log("the admin_id123", admin_id + "" + page)
            console.log("the condition check:", admin_id + "the page:" + page)
            const response = await axios.get(apiUrl + `/permission/${admin_id}/${page}`);
            console.log("Response for the previllages123:", response.data.data[0]);
            setpermissionDatas(response.data.data[0]);
            if (response.data.data[0].all) {
                console.log("the all is true ")
            }
            if (!response.data.data[0].add) {
                console.log("the all is false ")
            }
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRoleData({
            ...roleData,
            [name]: value
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setRoleData({
            ...roleData,
            [name]: checked ? [...roleData[name], name] : roleData[name].filter(item => item !== name)
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // You can perform form submission logic here
        console.log('Submitted data:', roleData);
    };



    // delete popup
    const [isDeletePopUP, setisDeletePopup] = useState(false);
    const [deleteId, setdeleteId] = useState("");
    const [deleteName, setdeleteName] = useState("");

    const handleRoleDeletePopup = async (id, name) => {

        console.log("the delete ", permissionDatas.delete)
        if (permissionDatas.all || permissionDatas.delete) {
            console.log("the all ", permissionDatas.all)
            setdeleteId(id)
            setdeleteName(name)
            setShowModals(true);
            console.log("the", isDeletePopUP)
        } else {
            toast.warning(" you dont have permission")
        }
    }

    const handleCloseModal = () => {
        setShowModals(false);
    }

    const handleDelete = async (e) => {
        e.preventDefault();
        try {
            await axios.delete(apiUrl + `/role/${deleteId}`);

            setShowModals(false);
            getall();
            setdeleteId("");
            setdeleteName("")


        }
        catch (err) {

        }
    }




    const handleupdatepop = (id) => {
        console.log("IDDD", id)
        if (permissionDatas.all || permissionDatas.edit) {
            navigate(`/Editrole/${id}`)
        } else {
            toast.warning("you dont have permission")
        }
    }


    
     // the pagination ---------------------------------------------------------------------
     const [currentPage, setCurrentPage] = useState(1);
     const [itemsPerPage, setItemsPerPage] = useState(5);
     const [searchQuery, setSearchQuery] = useState("");
     // Pagination
     const indexOfLastItem = currentPage * itemsPerPage;
     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
     const currentItems = values.slice(indexOfFirstItem, indexOfLastItem);
 
     const paginate = (pageNumber) => setCurrentPage(pageNumber);
 
     // Search
     const filtered = currentItems.filter((item) =>
         Object.values(item).some(
             (value) =>
                 typeof value === "string" &&
                 value.toLowerCase().includes(searchQuery.toLowerCase()) ||
                 (typeof value === "number" && value.toString().includes(searchQuery))
         )
     );
 
     const handleSearchChange = (event) => {
         setSearchQuery(event.target.value);
         setCurrentPage(1);
     };
 
     const totalPages = Math.ceil(values.length / itemsPerPage);
 
     const goToNextPage = () => {
         setCurrentPage((prevPage) =>
             prevPage === totalPages ? prevPage : prevPage + 1
         );
     };
 
     const goToPrevPage = () => {
         setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
     };


    return (
        <div className='top'>
            {/*------------------------------ delete model----------------------------- */}
            {/* <Modal className="modal" isOpen={isDeletePopUP} onClose={handlePopupClose}>
                <h2>Are you sure you want to Delete?</h2>
                <p className="d-flex justify-content-center">Role Name :  <strong className="ml-3">{deleteName}</strong></p>
                <div className='d-flex justify-content-evenly'>
                    <button className='btn btn-secondary' onClick={handlePopupClose}>Back</button>
                    <button className='btn btn-danger' onClick={handleDeleteClick}>Delete</button>
                </div>
            </Modal> */}


            <Modal className="modal" show={showModals} onHide={handleCloseModal}>
                <Modal.Header>
                    <p>Are you sure you want to delete?</p>
                </Modal.Header>
                <Modal.Body>
                    <label className="form-label d-flex">
                        <h5 className='mx-1'> role Name :</h5> <h5>{deleteName} </h5>
                    </label>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
                        Back
                    </Button>
                    <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
                </Modal.Footer>
            </Modal>

            {/*------------------------------ delete model----------------------------- */}




            <div className='d-flex'>
                <h5 className="mt-2">Roles</h5>
                <div className='mx-3'><button className='btn btn-success add' onClick={() => navigate('/addrole')} >Add </button></div>
            </div>

 {/* ------------ search and per page ----------------------------*/}

 <div className="mb-3 mt-3">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                        <label className='form-label text-dark mt-2'>Show : </label>
                      <div>
                      <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
                            <option value='5'>5</option>
                            <option value='10'>10</option>
                            <option value='20'>20</option>
                            <option value='50'>50</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-4 col-sm-1"></div>
                    <div className="col-lg-3 col-md-4 col-sm-7">
                      
                        <div className="w-full flex-1">
                            <form>
                                <div className="relative">
                                
                                    <input
                                        className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                                        placeholder="Search ..."
                                        type="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* --------------------------------end------------------------------------------- */}

<div className="card">
            <div className="mt-2">
    <div className="table-responsive">
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>S.No</th>
                    <th>Role Name</th>
                    <th>Module</th>
                    <th className='text-center'>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    filtered.map((item, index) => (
                        <tr key={index}>
                            <th>{index + 1}</th>
                            <td>{item.rolename}</td>
                            <td>
                                {Array.isArray(item.modules) && item.modules.map((module, index) => (
                                    <span key={index}>{module.name}{index !== item.modules.length - 1 && ', '}</span>
                                ))}
                            </td>
                            <td>
                                <div className="d-flex">
                                    <button className="btn updelete mx-2 box" onClick={() => handleupdatepop(item.id)}>
                                        <i className="fa-solid fa-pen-to-square"></i>
                                    </button>
                                    <button className="btn delete box" onClick={() => handleRoleDeletePopup(item.id, item.rolename)}>
                                        <i className="fa-solid fa-trash-can"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </div>
</div>
</div>


            {/* _------------------------------pagination button -------------------------------- */}
<div className="mt-3 pb-5">
                <div className="row">
                    <div className="col-lg-10 col-sm-12">
                        <nav className="d-flex float-right">
                            <ul className="pagination justify-content-center">
                                <li
                                    className={` page-item ${currentPage === 1 ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link" onClick={goToPrevPage}>
                                        Previous
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <li
                                        key={i}
                                        className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                                            }`}
                                    >
                                        <button
                                            className="page-link "
                                            onClick={() => paginate(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link " onClick={goToNextPage}>
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>{" "}
                    </div>
                    <div className="col-lg-2 col-sm-12 mt-1">
                        <div className="float-end">
                            <span className="text-dark ">
                                Page {currentPage} of {totalPages}
                            </span>
                        </div>
                    </div>
                </div>
            </div>


            {/* ---------------------------------------end ---------------------------------- */}
        </div>

    )
}

export default Roles;