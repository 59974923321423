import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';
import apiUrl from "../../environment/Api";
import { toast } from 'react-toastify';
function Schedule() {

    const [responseData, setResponseData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isUpdateshowModal, setUpdateShowModal] = useState(false);
    const [showModals, setShowModals] = useState(false);
    const [branch, setbranch] = useState([]);

    const [role_id, setroleid] = useState('');
    const [previllages, setprevillages] = useState({})



    const [addData, setAddData] = useState({
        // start_time: req.body.start_time,
        // end_time: req.body.end_time,
        // hour: req.body.hour


        branch_id: '',
        start_time: '',
        end_time: '',
        hour: ''

    });


    const [updateData, setUpdateData] = useState({

        branch_id: '',
        start_time: '',
        end_time: '',
        hour: ''


    })


    // railwaytime /////////////
    // const convertToNormalTime = (railwayTime) => {

    const convertToNormalTime = (railwayTime) => {
        if (!railwayTime || typeof railwayTime !== 'string') {
            console.error("Invalid railway time format:", railwayTime);
            return 'Invalid Time'; // Return a fallback or placeholder value
        }
        const [hours, minutes] = railwayTime.split(':');
        const hour = parseInt(hours, 10);
        const minute = parseInt(minutes, 10);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        return `${hour12}:${minutes} ${ampm}`;
    };



    useEffect(() => {
        fetchData();
        fetchDataed();
        gettoken();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + "/schedule");
            console.log("Response from server:", response.data);
            setResponseData(response.data);
            console.log("schudule", response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };






    const fetchDataed = async () => {
        try {
            const response = await axios.get(apiUrl + "/Branch");
            console.log("Response from server:", response.data);
            setbranch(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };



    const gettoken = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(apiUrl + "/get_detail", { "token": token });
            console.log("token detail from server:", response.data);
     
       
            setroleid(response.data.detail.rolename);
         
            localStorage.setItem('role_id', response.data.detail.rolename);

            const id = response.data.detail.rolename

            // getpages();

            if (id) {
                try {
                    const response = await axios.get(apiUrl + `/role_2/${id}`);


                    const modules = response.data.modules;

                    const filtered_access = modules.filter(item => item.name == "Branch")

                   
                    setprevillages(filtered_access[0].actions);

                  

                } catch (error) {
                    console.error("Error fetching data:", error.message);
                }
            }

        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    }







    const convertToRailwayTime = (normalTime) => {
        const [time, period] = normalTime.split(' ');
        const [hour12, minute] = time.split(':');
        const hour = period === 'PM' ? parseInt(hour12, 10) + 12 : parseInt(hour12, 10);
        return `${hour}:${minute}`;
    };

    const handleNewButtonClick = () => {
        if (previllages.add || previllages.all) {
        setShowModal(true);
    }else{
        toast.error("You don't have the permission to add");
    }
}


    const handlesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {



                branch_id:addData.branch_id,
                start_time: convertToRailwayTime(addData.start_time),
                end_time: convertToRailwayTime(addData.end_time),
                hour:addData.hour


            }
            const response = await axios.post(apiUrl + "/schedule", formData);
            console.log("Updated:", response);
            setShowModal(false);
            toast.success("User added successfully!");
            setAddData({


                branch_id: '',
                start_time: '',
                end_time: '',
                hour: ''
            });
            fetchData();
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };





    const [updateid, setUpdateId] = useState("");

    const handleUpdate = (_id, data) => {
        if (previllages.edit || previllages.all) {
        setUpdateId(_id)
        console.log(" the data to updata", data)
        setUpdateShowModal(true);
        setUpdateData({

            branch_id: data.branch_id,
            start_time: data.start_time,
            end_time: data.end_time,
            hour: data.hour

        })
    }else{
        toast.error("You don't have the permission to update");
    }
}


    const handleupdatesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {
                branch_id: updateData.branch_id,
                start_time: updateData.start_time,
                end_time: updateData.end_time,
                hour: updateData.hour

            }
            console.log("the update submit data", formData)
            const response = await axios.put(apiUrl + `/schedule/${updateid}`, formData)
            console.log("response for update", response)
            setUpdateShowModal(false)
            toast.success("Update successfully!");
            fetchData();
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    }





    const handleCloseModal = () => {
        setShowModals(false);
    }

    const [getdeleteid, setdeleteid] = useState('')
    const [getdeletename, setdeletename] = useState('')
    const handleOpenModal = (_id, start_time) => {
         if(previllages.all || previllages.delete){
        console.log(" the id", _id)
        console.log(" the name", start_time)
        setdeleteid(_id);
        setdeletename(start_time);
        setShowModals(true);
    }else{
        toast.error("You don't have the permission to delete");
    }
}



    const handleDelete = async () => {
        try {
            await axios.delete(apiUrl + `/schedule/${getdeleteid}`);
            toast.error("Deleted successfully!");
            setShowModals(false);
            fetchData();
        } catch (error) {
            console.error("Error deleting role:", error.message);
        }
    }




    // the pagination ---------------------------------------------------------------------
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState("");
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Search
    const filtered = currentItems.filter((item) =>
        Object.values(item).some(
            (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (typeof value === "number" && value.toString().includes(searchQuery))
        )
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(responseData.length / itemsPerPage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage === totalPages ? prevPage : prevPage + 1
        );
    };

    const goToPrevPage = () => {
        setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
    };
    // the pagination  end ---------------------------------------------------------------------
    return (
        <div className='top'>
            <div className="d-flex">
                <h4 className='mt-2'>Schudule </h4>
                <div className="mx-3">
                    <button
                        className="btn  add"
                        onClick={handleNewButtonClick}
                    >
                        Add 
                    </button>
                </div>
            </div>




            {/* ------------ search and per page ----------------------------*/}

            <div className="mb-3 mt-3">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                        <label className='form-label text-dark mt-2'>Show : </label>
                        <div>
                            <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='20'>20</option>
                                <option value='50'>50</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-4 col-sm-1"></div>
                    <div className="col-lg-3 col-md-4 col-sm-7">

                        <div className="w-full flex-1">
                            <form>
                                <div className="relative">

                                    <input
                                        className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                                        placeholder="Search ..."
                                        type="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* --------------------------------end------------------------------------------- */}
            <div className="card">
                <div className="mt-1">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>S No.</th>
                                <th>Branch </th>
                                <th>Start time </th>
                                <th>End time</th>
                                <th>Hour</th>

                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtered.map((item, index) => (
                                <tr key={index}>

                                    <td>{index + 1}</td>
                                    <td>{item.branch_id.branch_name}</td>
                                    <td>{convertToNormalTime(item.start_time)}</td>
                                    <td>{convertToNormalTime(item.end_time)}</td>
                                    <td>{item.hour}</td>
                                    <td className="text-center">
                                        <button
                                            className="btn updelete mx-2"
                                            onClick={() => handleUpdate(item._id, item)}
                                        >

                                            <i class="fa-solid fa-pen-to-square"></i>
                                        </button>
                                        <button className='btn delete'
                                            onClick={() => handleOpenModal(item._id, item.start_time)}
                                        ><i class="fa-solid fa-trash-can"></i></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>



            {/* Modal for adding a new user */}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">




                      

                        <div className="floating-label">
                                    <label className="form-label">
                                        <b></b>
                                    </label>
                                    <select
                                        className="form-select"
                                        value={addData.branch_id}
                                        onChange={(e) => setAddData({ ...addData, branch_id: e.target.value })}
                                        required
                                    >
                                        <option value="">Select Branch </option>
                                        {branch.map((item, index) => (
                                            <option key={index} value={item._id}>
                                                {item.branch_name}
                                            </option>
                                        ))}
                                    </select>
                                    <label className={addData.branch_id ? "filled" : ""}>

                                    </label>
                                </div>



                        <div className="floating-label">
                            <input className="form-control" placeholder='' type="time" value={addData.start_time} onChange={(e) => setAddData({ ...addData, start_time: e.target.value })} required />
                            <label className={addData.start_time ? "filled" : ""}>
                                start_time
                            </label>
                        </div>

                        <div className="floating-label">
                            <input className="form-control" placeholder='' type="time" value={addData.end_time} onChange={(e) => setAddData({ ...addData, end_time: e.target.value })} required />
                            <label className={addData.end_time ? "filled" : ""}>
                                End_time
                            </label>
                        </div>


                        <div className="floating-label">
                            <input className="form-control" placeholder='' type="text" value={addData.hour} onChange={(e) => setAddData({ ...addData, hour: e.target.value })} required />
                            <label className={addData.hour ? "filled" : ""}>
                                Hour
                            </label>
                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handlesubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>




            {/* ----------------update */}


            <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">


                    <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={updateData.branch_id.branch_name} onChange={(e) => setUpdateData({ ...updateData, branch_id: e.target.value })} required />
                                    <label className={updateData.branch_id.branch_name ? "filled" : ""}>
                                        Branch 
                                    </label>
                                </div>


                        <div className="floating-label">
                            <input className="form-control" type="time" placeholder='' value={updateData.start_time} onChange={(e) => setUpdateData({ ...updateData, start_time: e.target.value })} required />
                            <label className={updateData.start_time ? "filled" : ""}>
                                start_time
                            </label>
                        </div>


                        <div className="floating-label">
                            <input className="form-control" type="time" placeholder='' value={updateData.end_time} onChange={(e) => setUpdateData({ ...updateData, end_time: e.target.value })} required />
                            <label className={updateData.end_time ? "filled" : ""}>
                                End_time
                            </label>
                        </div>


                        <div className="floating-label">
                            <input className="form-control" type="text" placeholder='' value={updateData.hour} onChange={(e) => setUpdateData({ ...updateData, hour: e.target.value })} required />
                            <label className={updateData.hour ? "filled" : ""}>
                                Hour
                            </label>
                        </div>


                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handleupdatesubmit}>Update</Button>
                </Modal.Footer>
            </Modal>


            {/* ----------------delete */}

            <Modal className="modal" show={showModals} onHide={handleCloseModal}>
                <Modal.Header>
                    <p>Are you sure you want to delete?</p>
                </Modal.Header>
                <Modal.Body>
                    <label className="form-label d-flex">
                        <h5 className='mx-1'> Login Time :</h5> <h5>{getdeletename} </h5>
                    </label>

                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
                </Modal.Footer>
            </Modal>


            {/* _------------------------------pagination button -------------------------------- */}
            <div className="mt-3 pb-5">
                <div className="row">
                    <div className="col-lg-10 col-sm-12">
                        <nav className="d-flex float-right">
                            <ul className="pagination justify-content-center">
                                <li
                                    className={` page-item ${currentPage === 1 ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link" onClick={goToPrevPage}>
                                        Previous
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <li
                                        key={i}
                                        className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                                            }`}
                                    >
                                        <button
                                            className="page-link "
                                            onClick={() => paginate(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link " onClick={goToNextPage}>
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>{" "}
                    </div>
                    <div className="col-lg-2 col-sm-12 mt-1">
                        <div className="float-end">
                            <span className="text-dark ">
                                Page {currentPage} of {totalPages}
                            </span>
                        </div>
                    </div>
                </div>
            </div>


            {/* ---------------------------------------end ---------------------------------- */}
        </div>
    )
} export default Schedule;