import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import apiUrl from '../../../environment/Api';
import { toast } from 'react-toastify';
function Leavestaff(){

    const [responseData, setResponseData] = useState([]);
    const [responseDatas, setResponseDatas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isUpdateshowModal, setUpdateShowModal] = useState(false);
    const [empid,setEmpid]=useState("")
    const [showModals, setShowModals] = useState(false);
    const [addData, setAddData] = useState({
        empid:localStorage.getItem("id"),
        date: '',
        reason: '',
        status: ''

    });


    const [updateData, setUpdateData] = useState({
        empid:localStorage.getItem("id"),
        date: '',
        reason: '',
        status: ''


    })




    useEffect(() => {
        fetchData();
        setEmpid(localStorage.getItem("id"))
    }, []);

    const fetchData = async () => {
        try {
            const localid = localStorage.getItem("id")
            console.log("localid",localid)
            const response = await axios.get(apiUrl + `/leave/${localid}`);
            console.log("leave data:", response.data);
            setResponseData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        fetchDatas();
    }, []);

    const fetchDatas = async () => {
        try {
            const response = await axios.get(apiUrl + "/staff");
            console.log("Response from server:", response.data);
            setResponseDatas(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };




    const handleNewButtonClick = () => {
        setShowModal(true);
    };

    const handlesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {
                empid: addData.empid,
                date: addData.date,
                reason: addData.reason,
                status: addData.status


            }
            const response = await axios.post(apiUrl + "/leave", formData);
            console.log("Updated:", response);
            setShowModal(false);
            toast.success("added successfully!");
            setAddData({
                empid:'',
                date: '',
                reason: '',
                status: ''
            });
            fetchData();
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };



    const [updateid, setUpdateId] = useState("");

    const handleUpdate = (id, data) => {
        setUpdateId(id)
        console.log(" the data to updata", data)
        setUpdateShowModal(true);
        setUpdateData({
            empid: data.empid,
            date: data.date,
            reason: data.reason,
            status: data.status
        })
    }


    const handleupdatesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {
                empid: updateData.empid,
                date: updateData.date,
                reason: updateData.reason,
                status: updateData.status
            }
            console.log("the update submit data", formData)
            const response = await axios.put(apiUrl + `/leave/${updateid}`, formData)
            console.log("response for update", response)
            setUpdateShowModal(false)
            toast.success("Update successfully!");
            fetchData();
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    }







    const handleCloseModal = () => {
        setShowModals(false);
    }
    const [getdeleteid , setdeleteid] = useState('')
    const [getdeletename , setdeletename] = useState('')
    const handleOpenModal =(id, date )=>{
        console.log(" the id", id)
        console.log(" the name", date)
        setdeleteid(id);
        setdeletename(date);
        setShowModals(true);
    }

    const handleDelete = async (id) => {
        try {
            await axios.delete(apiUrl + `/leave/${getdeleteid}`);
            toast.success("Deleted successfully!");
            setShowModals(false);
            fetchData();
        } catch (error) {
            console.error("Error deleting role:", error.message);
        }
    };




     // the pagination ---------------------------------------------------------------------
     const [currentPage, setCurrentPage] = useState(1);
     const [itemsPerPage, setItemsPerPage] = useState(5);
     const [searchQuery, setSearchQuery] = useState("");
     // Pagination
     const indexOfLastItem = currentPage * itemsPerPage;
     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
     const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);
 
     const paginate = (pageNumber) => setCurrentPage(pageNumber);
 
     // Search
     const filtered = currentItems.filter((item) =>
         Object.values(item).some(
             (value) =>
                 typeof value === "string" &&
                 value.toLowerCase().includes(searchQuery.toLowerCase()) ||
                 (typeof value === "number" && value.toString().includes(searchQuery))
         )
     );
 
     const handleSearchChange = (event) => {
         setSearchQuery(event.target.value);
         setCurrentPage(1);
     };
 
     const totalPages = Math.ceil(responseData.length / itemsPerPage);
 
     const goToNextPage = () => {
         setCurrentPage((prevPage) =>
             prevPage === totalPages ? prevPage : prevPage + 1
         );
     };
 
     const goToPrevPage = () => {
         setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
     };
     // the pagination  end ---------------------------------------------------------------------
    return(
        <>
        

        <div className='row'>
                <div className=' col-lg-9 d-flex'>
                    <h5>Leave Details</h5>
                    <div className='mx-3'><button className='btn btn-success add'  onClick={handleNewButtonClick}>Add <i class="fa-solid fa-plus"></i></button></div>
                </div>
            </div>



    {/* ------------ search and per page ----------------------------*/}

    <div className="mb-3 mt-3">
                <div className="row">
                <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                        <label className='form-label text-dark mt-2'>Show : </label>
                      <div>
                      <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
                            <option value='5'>5</option>
                            <option value='10'>10</option>
                            <option value='20'>20</option>
                            <option value='50'>50</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-4 col-sm-1"></div>
                    <div className="col-lg-3 col-md-4 col-sm-7">
                  
                        <div className="w-full flex-1">
                            <form>
                                <div className="relative">
                                
                                    <input
                                        className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                                        placeholder="Search ..."
                                        type="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* --------------------------------end------------------------------------------- */}


        {/* Table */}
            <div className="mt-2">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            {/* <th>Employee Id</th> */}
                            <th>Date</th>
                            <th>Reason</th>
                            <th>Status</th>
                            <th className='text-center'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {filtered.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                {/* <td>{item.empid}</td> */}
                                <td>{item.date}</td>
                                <td>{item.reason}</td>
                                <td>
                                    <p className={
                                        item.status === 'Approved' ? 'approved-row' :
                                            (item.status === 'Rejected' ? 'rejected-row' :
                                                (item.status === 'Pending' ? 'pending-row' : 'default-row'))
                                    }>
                                        {item.status}
                                    </p>
                                </td>

                                <td className='text-center'>
                                <button className='btn btn-primary mx-3' onClick={() => handleUpdate(item.id, item)}><i class="fa-solid fa-pen-to-square"></i></button>
                                    <button className='btn btn-danger'  onClick={() =>  handleOpenModal(item.id, item.date) }><i class="fa-solid fa-trash-can"></i></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>



 {/* Modal for adding a new user */}

 <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <label className="form-label">
                        <b>Select Employee Id :</b>
                    </label>
                    <input className="form-control" type="text" value={empid}  disabled /> */}

                    {/* Other input fields */}
                    <label className="form-label">
                        <b>Date :</b>
                    </label>
                    <input className="form-control" type="date" value={addData.date} onChange={(e) => setAddData({ ...addData, date: e.target.value })} required />
                    <label className="form-label pt-2">
                        <b>Reason :</b>
                    </label>
                    <input className="form-control" type="text" value={addData.reason} onChange={(e) => setAddData({ ...addData, reason: e.target.value })} required />
                    {/* <label className="form-label pt-2">
                        <b>Status :</b>
                    </label> */}
                    {/* <select className="form-control" value={addData.status} onChange={(e) => setAddData({ ...addData, status: e.target.value })}>
                        <option value="">Select status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </select> */}
                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handlesubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>


 {/* ----------------update */}


 <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Ubdate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <label className="form-label">
                        <b>Select Employee Id :</b>
                    </label>
                    <input className="form-control" type="text" value={empid}  disabled /> */}


                    <label className="form-label">
                        <b>Date :</b>
                    </label>
                    <input className="form-control" type="date" value={updateData.date} onChange={(e) => setUpdateData({ ...updateData, date: e.target.value })} required />
                    <label className="form-label pt-2">
                        <b>Reason :</b>
                    </label>
                    <input className="form-control" type="text" value={updateData.reason} onChange={(e) => setUpdateData({ ...updateData, reason: e.target.value })} required />
                    {/* <label className="form-label pt-2">
                        <b>Status :</b>
                    </label> */}
                    {/* <select className="form-control" value={updateData.status} onChange={(e) => setUpdateData({ ...updateData, status: e.target.value })}>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </select> */}
                </Modal.Body>

                <Modal.Footer>
                    <Button  className='cancelbut' variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
                    <Button  className='cancelbut' variant="primary" onClick={handleupdatesubmit}>Update</Button>
                </Modal.Footer>
            </Modal>


{/* ----------------delete */}

<Modal className="modal" show={showModals} onHide={handleCloseModal}>
                <Modal.Header>
                    <p>Are you sure you want to delete?</p>
                </Modal.Header>
                <Modal.Body>
                <label className="form-label d-flex">
                <h5 className='mx-1'>Date :</h5> <h5>{getdeletename} </h5>
                    </label>
                   
                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
                </Modal.Footer>
            </Modal>



{/* _------------------------------pagination button -------------------------------- */}
<div className="mt-3 pb-5">
                <div className="row">
                    <div className="col-lg-10 col-sm-12">
                        <nav className="d-flex float-right">
                            <ul className="pagination justify-content-center">
                                <li
                                    className={` page-item ${currentPage === 1 ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link" onClick={goToPrevPage}>
                                        Previous
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <li
                                        key={i}
                                        className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                                            }`}
                                    >
                                        <button
                                            className="page-link "
                                            onClick={() => paginate(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link " onClick={goToNextPage}>
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>{" "}
                    </div>
                    <div className="col-lg-2 col-sm-12 mt-1">
                        <div className="float-end">
                            <span className="text-dark ">
                                Page {currentPage} of {totalPages}
                            </span>
                        </div>
                    </div>
                </div>
            </div>


            {/* ---------------------------------------end ---------------------------------- */}
        </>
    )
}
export default Leavestaff;