import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import apiUrl from '../../environment/Api';
import { toast } from 'react-toastify';
import '../../assets/css/updelete.css';
import '../../assets/css/user.css';



function User() {

    const [responseData, setResponseData] = useState([]);
    const [responseDatas, setResponseDatas] = useState([]);
    const [getrole, setrole] = useState([])
    const [brachdata, setbrachdata] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isUpdateshowModal, setUpdateShowModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [showModals, setShowModals] = useState(false);

    const [addData, setAddData] = useState({
        branch_id: '',
        username: '',
        email: '',
        mobile: '',
        designation: '',
        password: '',
        status: '',
        role: ''



    });
    const [updateData, setUpdateData] = useState({
        branch_id: '',
        username: '',
        email: '',
        mobile: '',
        designation: '',
        password: '',
        status: '',
        role: ''
    })


    const [checkboxdata, setcheckboxdata] = useState('staff')


    const [empdata, setempdata] = useState([])

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + "/user");
            console.log("Response from server:", response.data);
            setResponseData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        fetchDatas();
        fetchDatased();
    }, []);



    const fetchDatas = async () => {
        try {
            const response = await axios.get(apiUrl + "/Branch");
            console.log("Response from server:", response.data);
            setResponseDatas(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const fetchDatased = async () => {
        try {
            const response = await axios.get(apiUrl + "/role_2");
            console.log("Response from server :", response.data);
            setrole(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const handleNewButtonClick = () => {
        setShowModal(true);
    };

    const handlesubmit = async (e) => {
        e.preventDefault();
        try {

            console.log("addd data", addData)
            const formData = {

                // branch_id:'',
                // username: '',
                // email: '',
                // mobile: '',
                // password: '',
                // status: '',
                // role:''

                branch_id: addData.branch_id,
                username: addData.username,
                email: addData.email,
                mobile: addData.mobile,
                designation: addData.designation[1],
                password: addData.password,
                status: addData.status,
                role: addData.role,


            }

            console.log("the addData is : ", addData);
            console.log("the form data is : ", formData);
            const response = await axios.post(apiUrl + "/user", formData);
            console.log("Updated:", response);
            setShowModal(false);
            toast.success("User added successfully!");
            setAddData({

                branch_id: '',
                username: '',
                email: '',
                mobile: '',
                designation: '',
                password: '',
                status: '',
                role: ''
            });
            fetchData();
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };


    const [updateid, setUpdateId] = useState("");

    const handleUpdate = (_id, data) => {
        setUpdateId(_id)
        console.log(" the data to updata", data)
        setUpdateShowModal(true);
        setUpdateData({


            branch_id: data.branch_id,
            username: data.username,
            email: data.email,
            mobile: data.mobile,
            designation: data.designation,
            password: data.password,
            status: data.status,
            role: data.role
        })
    }





    const handleupdatesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {


                // branch_id:'',
                // username: '',
                // email: '',
                // mobile: '',
                // password: '',
                // status: '',
                // role:''


                branch_id: updateData.branch_id,
                username: updateData.username,
                email: updateData.email,
                mobile: updateData.mobile,
                password: updateData.password,
                status: updateData.status,
                role: updateData.role
            }
            console.log("the update submit data", formData)
            const response = await axios.put(apiUrl + `/user/${updateid}`, formData)
            console.log("response for update", response)
            setUpdateShowModal(false)
            toast.success("Update successfully!");
            fetchData();
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    }




    const handleCloseModal = () => {
        setShowModals(false);
    }

    const [getdeleteid, setdeleteid] = useState('')
    const [getdeletename, setdeletename] = useState('')
    const handleOpenModal = (_id, name) => {
        console.log(" the id", _id)
        console.log(" the name", name)
        setdeleteid(_id);
        setdeletename(name);
        setShowModals(true);
    }


    const handleDelete = async () => {
        try {
            await axios.delete(apiUrl + `/user/${getdeleteid}`);
            toast.error("Deleted successfully!");
            setShowModals(false);
            fetchData();
        } catch (error) {
            console.error("Error deleting role:", error.message);
        }
    }


    // the pagination ---------------------------------------------------------------------
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState("");
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Search
    const filtered = currentItems.filter((item) =>
        Object.values(item).some(
            (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (typeof value === "number" && value.toString().includes(searchQuery))
        )
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(responseData.length / itemsPerPage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage === totalPages ? prevPage : prevPage + 1
        );
    };

    const goToPrevPage = () => {
        setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
    };


    // the pagination  end ---------------------------------------------------------------------




    const handleAdditionalChange = async (e) => {
        const selectedBranchId = e.target.value; // Get the selected value from the dropdown
        console.log("Additional change function called with value:", selectedBranchId);
        try {
            const response = await axios.get(apiUrl + `/staff_based_on_branch/${selectedBranchId}`);
            console.log("Response from server selectedBranchId:", response.data);
            setempdata(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }


    };



    const handleAdditionalChanges = async (e) => {
        const selectedBranchIded = e.target.value; // Get the selected value from the dropdown
        console.log("Additional change function called with value:", selectedBranchIded);
        try {
            const response = await axios.put(apiUrl + `/staff_based_on_branch/${selectedBranchIded}`);
            console.log("Response from server selectedBranchId:", response.data);
            setempdata(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }


    };



    const handleChange = async (e) => {
        const selectemp = e.target.value;
        console.log("thw selectemp ", selectemp)

        try {
            const response = await axios.get(apiUrl + `/staff/${selectemp}`);
            const empdatased = response.data
            setempdata(response.data);
            console.log(" the setadddata is after ", empdatased);
            setAddData(prevState => ({
                ...prevState,
                username: `${empdatased[0]?.empid} - ${empdatased[0]?.name}`,
                email: empdatased[0]?.email || '',
                mobile: empdatased[0]?.mobile || '',
                designation: [
                    empdatased[0]?.designation[0]?.designation || '',
                    empdatased[0]?.designation[0]?._id || ''
                ]
            }));
            console.log(addData)

            // setUpdateData({
            //     ...updateData,
            //     email:empdatased[0]?.email,
            //     mobile:empdatased[0]?.mobile,
            //     designation:empdatased[0]?.designation[0].designation

            // })

        } catch (error) {
            console.error("Error fetching data:", error.message);
        }

    }






    const handleChangedd = async (e) => {
        const selectemp = e.target.value;
        console.log("thw selectemp ", selectemp)

        try {
            const response = await axios.put(apiUrl + `/staff/${selectemp}`);
            const empdatased = response.data
            setempdata(response.data);
            console.log(" the setadddata is after ", empdatased);
            setUpdateData(prevState => ({
                ...prevState,
                username: `${empdatased[0]?.empid} - ${empdatased[0]?.name}`,
                email: empdatased[0]?.email || '',
                mobile: empdatased[0]?.mobile || '',
                designation: [
                    empdatased[0]?.designation[0]?.designation || '',
                    empdatased[0]?.designation[0]?._id || ''
                ]
            }));
            console.log(addData)

            // setUpdateData({
            //     ...updateData,
            //     email:empdatased[0]?.email,
            //     mobile:empdatased[0]?.mobile,
            //     designation:empdatased[0]?.designation[0].designation

            // })

        } catch (error) {
            console.error("Error fetching data:", error.message);
        }

    }






    const [isStaffChecked, setIsStaffChecked] = useState(false);
    const [isManagementChecked, setIsManagementChecked] = useState(false);


    const handleStaffCheckboxChange = (e) => {
        setIsStaffChecked(e.target.checked);
        setIsManagementChecked(false); // Uncheck management if staff is checked
    };


    const handleManagementCheckboxChange = (e) => {
        setIsManagementChecked(e.target.checked);
        setIsStaffChecked(false); // Uncheck staff if management is checked
    };

    return (
        <div className='top'>


            <div className='row '>
                <div className=' col-lg-9 d-flex'>
                    <h5 className='mt-2'>User Details</h5>
                    <div className='mx-3'><button className='btn  add ' onClick={handleNewButtonClick}>Add </button></div>
                </div>
            </div>



            {/* ------------ search and per page ----------------------------*/}

            <div className="mb-3 mt-3">
                <div className="row">

                    <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                        <label className='form-label text-dark mt-2'>Show : </label>
                        <div>
                            <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='20'>20</option>
                                <option value='50'>50</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-4 col-sm-1"></div>
                    <div className="col-lg-3 col-md-4 col-sm-7">

                        <div className="w-full flex-1">
                            <form>
                                <div className="relative">

                                    <input
                                        className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                                        placeholder="Search ..."
                                        type="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* --------------------------------end------------------------------------------- */}


            {/* Table */}
            <div className='card'>
                <div className="mt-2">
                    <table className="table table-striped responsive">
                        <thead>
                            <tr>


                                <th>S.No</th>
                                <th>Branch </th>
                                <th>User Name</th>
                                <th>Email</th>
                                <th>Mobile No</th>
                                <th>Designation</th>
                                {/* <th>password</th>
                                <th>Role</th> */}
                                <th>Status</th>

                                <th className='text-center'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtered.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1} </td>
                                    <td>{item.branch_id[0].branch_name}</td>
                                    <td>{item.username}</td>
                                    <td>{item.email}</td>
                                    <td>{item.mobile}</td>
                                    <td>{item.designation[0]?.designation}</td>
                                    {/* <td>aaa</td> */}
                                    {/* <td>{item.password}</td>
                                    <td>{item.role}</td> */}
                                    {/* <td  ><p className={item.status === 'Active' ? 'active-row' : 'inactive-row'}>{item.status}</p></td> */}

                                    {(item.status == "Active") ?
                                        <td className='text-center hideres '>
                                            <p className=' text-white green-label' >{item.status}</p>
                                        </td>
                                        : <td className='text-center hideres'  ><p className='text-white red-label'>{item.status}</p></td>
                                    }




                                    <td className='text-center'>
                                        <button className='btn updelete mx-2' onClick={() => handleUpdate(item._id, item)}><i class="fa-solid fa-pen-to-square"></i></button>
                                        <button className='btn delete'
                                            onClick={() => handleOpenModal(item._id, item.username)}
                                        ><i class="fa-solid fa-trash-can"></i></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>


            {/* Modal for adding a new user */}

            <Modal show={showModal} onHide={() => setShowModal(false)} className="custom-modal" >
                <Modal.Header closeButton>
                    <Modal.Title>Add New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* Other input fields */}
                    <div className='row   '>
                        <div className='col-lg-6'>

                            <div className='row mb-3'>

                                <div className='col-lg-6'>
                                    <td className='mangement'><input type='checkbox' onChange={handleManagementCheckboxChange}disabled={isStaffChecked}></input></td>
                                    <th>Mangement</th>
                                </div>


                                <div className='col-lg-5'>

                                    <td className='Staff'><input type='checkbox' onChange={handleStaffCheckboxChange}
                                      disabled={isManagementChecked}></input></td>
                                    <th>Staff</th>
                                </div>

                            </div>


                            <div className="form-group">

                                <div className="floating-label">
                                    <label className="form-label">
                                        <b></b>
                                    </label>

                                    <select
                                        className="form-select"
                                        value={addData.branch_id}
                                        onChange={(e) => {
                                            setAddData({ ...addData, branch_id: e.target.value });
                                            handleAdditionalChange(e); // Call the additional function here
                                        }}
                                        required
                                    >
                                        <option value="">Select Branch</option>
                                        {responseDatas.map((item, index) => (
                                            <option key={index} value={item._id}>
                                                {item.branch_name}
                                            </option>
                                        ))}
                                    </select>
                                    <label className={addData.branch_id ? "filled" : ""}>

                                    </label>
                                </div>


                                <div className="floating-label">
                                    {isManagementChecked ? (
                                        <input
                                            className="form-control"
                                            type="email"
                                            placeholder="User Name"
                                            value={addData.username}
                                            onChange={(e) =>
                                                setAddData({ ...addData, username: e.target.value })
                                            }
                                            required
                                        />
                                    ) :  (
                                        <select
                                            className="form-select"
                                            value={addData.username}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">Select emp</option>
                                            {empdata.map((item, index) => (
                                                <option key={index} value={item._id}>
                                                    {item.empid} - {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                    <label className={addData.username ? "filled" : ""}>
                                        User Name
                                    </label>
                                </div>



                                {/* <div className="floating-label">
                                    <label className="form-label">
                                        <b></b>
                                    </label>

                                    <select
                                        className="form-select"
                                        value={addData.username}
                                        onChange={(e) => {
                                            setAddData({ ...addData, username: e.target.value });
                                            // handleChange(e)
                                        }}
                                        required
                                    >
                                        <option value="">Select emp</option>
                                        {empdata.map((item, index) => (
                                            <option key={index} value={item._id}>
                                                {item.empid} - {item.name}
                                            </option>
                                        ))}
                                    </select>

                                    <label className={addData.username ? "filled" : ""}>

                                    </label>
                                </div>




                                <div className="floating-label">
                                    <input className="form-control" type="email" placeholder='' value={addData.username} onChange={(e) => setAddData({ ...addData, username: e.target.value })} required />
                                    
                                    <label className={addData.username ? "filled" : ""}>
                                        User Name
                                    </label>
                                </div> */}


                                <div className="floating-label">
                                    <input className="form-control" type="email" placeholder='' value={addData.email} onChange={(e) => setAddData({ ...addData, email: e.target.value })} required />
                                    <label className={addData.email ? "filled" : ""}>
                                        Email
                                    </label>
                                </div>



                                <div className="floating-label">
                                    <input className="form-control" type="tel" placeholder='' value={addData.mobile} onChange={(e) => setAddData({ ...addData, mobile: e.target.value })} required />
                                    <label className={addData.mobile ? "filled" : ""}>
                                        Mobile No
                                    </label>
                                </div>


                            </div>
                        </div>

                        {console.log(addData, "llllll--90")}

                        <div className='col-lg-6 '>



                            <div className="form-group">
                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder=''
                                        value={addData.designation[0]}
                                        onChange={(e) =>
                                            setAddData({ ...addData, designation: e.target.value })} required />
                                            
                                    <label className={addData.designation[0]? "filled" : ""}>
                                        Designation
                                    </label>
                                </div>


                                <div className="floating-label">
                                    <input className="form-control" type="password" placeholder='' value={addData.password} onChange={(e) => setAddData({ ...addData, password: e.target.value })} required />
                                    <label className={addData.password ? "filled" : ""}>
                                        password
                                    </label>
                                </div>


                                <div className="floating-label">
                                    {/* 
                                    <input className="form-control" type="text" placeholder='' value={addData.role} onChange={(e) => setAddData({ ...addData, role: e.target.value })} required /> */}
                                    <select
                                        className="form-select"
                                        value={addData.role}
                                        onChange={(e) => {
                                            setAddData({ ...addData, role: e.target.value });
                                        }}
                                        required
                                    >
                                        <option value="">Select role </option>
                                        {getrole.map((item, index) => (
                                            <option key={index} value={item._id}>
                                                {item.rolename}
                                            </option>
                                        ))}
                                    </select>
                                    <label className={addData.role ? "filled" : ""}>

                                    </label>
                                </div>




                                <div className="floating-label">
                                    <select className="form-control" placeholder='' value={addData.status} onChange={(e) => setAddData({ ...addData, status: e.target.value })}>
                                        <option value="">Select status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                    <label className={addData.status ? "filled" : ""}>

                                    </label>
                                </div>




                            </div>

                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handlesubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>


            {/* ----------------update */}


            <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)} className="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Update User</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='row'>
                        <div className='col-lg-6'>

                            <div className='row mb-3'>

                                <div className='col-lg-6'>
                                    <td className='mangement'><input type='checkbox' ></input></td>

                                    <th>Mangement</th>
                                </div>
                                <div className='col-lg-5'>

                                    <td className='Staff'><input type='checkbox' onClick={handleManagementCheckboxChange}></input></td>
                                    <th>Staff</th>
                                </div>
                            </div>
                            <div className="form-group">


                                <div className="floating-label">
                                    <label className="form-label">
                                        <b></b>
                                    </label>
                                    <select
                                        className="form-select"
                                        value={updateData.branch_id}
                                        onChange={(e) => {
                                            setUpdateData({ ...updateData, branch_id: e.target.value }); // Update the state
                                            handleAdditionalChanges(e); // Call the additional function here
                                        }}
                                        required
                                    >
                                        {/* <option value="">Select Branch </option> */}
                                        {responseDatas.map((item, index) => (
                                            <option key={index} value={item.branch_id}>
                                                {item.branch_id}
                                            </option>
                                        ))}
                                    </select>
                                    <label className={updateData.branch_id ? "filled" : ""}>

                                    </label>
                                </div>






                                <div className="floating-label">


                                    <input className="form-control" type="text"
                                        placeholder='' value={updateData.username} onChange={(e) => setUpdateData({ ...updateData, username: e.target.value })} required />

                                    {/* <select
                                        className="form-select"
                                        value={updateData.username}
                                        onChange={(e) => {
                                            setUpdateData({ ...updateData, username: e.target.value }); // Update the state
                                            handleChangedd(e); // Call the additional function here
                                        }}
                                        required
                                    >
                                        <option value="">Select emp </option>
                                        {empdata.map((item, index) => (
                                            <option key={index} value={item._id}>
                                                {item.empid} - {item.name}
                                            </option>
                                        ))}
                                    </select> */}

                                    <label className={updateData.username ? "filled" : ""}>
                                        User Name
                                    </label>
                                </div>


                                <div className="floating-label">
                                    <input className="form-control" type="email" placeholder='' value={updateData.email} onChange={(e) => setUpdateData({ ...updateData, email: e.target.value })} required />
                                    <label className={updateData.email ? "filled" : ""}>
                                        Email
                                    </label>
                                </div>


                                <div className="floating-label">
                                    <input className="form-control" placeholder='' type="tel" value={updateData.mobile} onChange={(e) => setUpdateData({ ...updateData, mobile: e.target.value })} required />
                                    <label className={addData.mobile ? "filled" : ""}>
                                        Mobile
                                    </label>
                                </div>


                            </div>

                        </div>
                        <div className='col-lg-6'>
                            <div className="form-group">







                                <div className="floating-label">
                                    <input className="form-control" placeholder='' type="text" value={updateData.designation[0]?.designation} onChange={(e) => setUpdateData({ ...updateData, designation: e.target.value })} required />
                                    <label className={updateData.designation ? "filled" : ""}>
                                        Designation
                                    </label>
                                </div>


                                <div className="floating-label">
                                    <input className="form-control" type="password" placeholder='' value={updateData.password} onChange={(e) => setUpdateData({ ...updateData, password: e.target.value })} required />
                                    <label className={updateData.password ? "filled" : ""}>
                                        Password
                                    </label>
                                </div>


                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={updateData.role} onChange={(e) => setUpdateData({ ...updateData, role: e.target.value })} required />
                                    <label className={updateData.role ? "filled" : ""}>
                                        Role
                                    </label>
                                </div>


                                <div className="floating-label">
                                    <select className="form-control" value={updateData.status} onChange={(e) => setUpdateData({ ...updateData, status: e.target.value })}>
                                        <option value="">Select status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                    <label className={updateData.status ? "filled" : ""}>

                                    </label>
                                </div>



                            </div>
                        </div>
                    </div>


                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handleupdatesubmit}>Update</Button>
                </Modal.Footer>
            </Modal>

            {/* ----------------delete */}

            <Modal className="modal" show={showModals} onHide={handleCloseModal}>
                <Modal.Header>
                    <p>Are you sure you want to delete?</p>
                </Modal.Header>
                <Modal.Body>
                    <label className="form-label d-flex">
                        <h5 className='mx-1'> User Name :</h5> <h5>{getdeletename} </h5>
                    </label>

                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
                </Modal.Footer>
            </Modal>


            {/* _------------------------------pagination button -------------------------------- */}
            <div className="mt-3 pb-5">
                <div className="row">
                    <div className="col-lg-10 col-sm-12">
                        <nav className="d-flex float-right">
                            <ul className="pagination justify-content-center">
                                <li
                                    className={` page-item ${currentPage === 1 ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link" onClick={goToPrevPage}>
                                        Previous
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <li
                                        key={i}
                                        className={`mx-2 page-item ${currentPage === i + 1 ? "actived" : ""
                                            }`}
                                    >
                                        <button
                                            className="page-link "
                                            onClick={() => paginate(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link " onClick={goToNextPage}>
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>{" "}
                    </div>
                    <div className="col-lg-2 col-sm-12 mt-1">
                        <div className="float-end">
                            <span className="text-dark ">
                                Page {currentPage} of {totalPages}
                            </span>
                        </div>
                    </div>
                </div>
            </div>


            {/* ---------------------------------------end ---------------------------------- */}
        </div>
    );
}

export default User;
