import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import '../../../assets/css/Attendance.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import apiUrl from '../../../environment/Api';
import { Modal, Button } from 'react-bootstrap';
function Attendancestaff() {
    const localizer = momentLocalizer(moment);
    const [responseData, setResponseData] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);
    const [showModals, setShowModals] = useState(false);
    const [updateData, setUpdateData] = useState({
        empid: '',
        name: '',
        date: '',
        login: '',
        lunchout: '',
        lunchin: '',
        logout: '',
        workhour: ''
    });
    const [selectedOption, setSelectedOption] = useState(""); // Track selected option
    const [isLoggedOut, setIsLoggedOut] = useState(false); // Track if the user has logged out

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const fetchData = async () => {
        const localid = localStorage.getItem("id");
        try {
            const response = await axios.get(apiUrl + `/attendance/${localid}`);
            console.log("attendance get login",response.data)
            setResponseData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const preprocessTime = (timeString) => {
        if (timeString && (timeString.includes("PM") || timeString.includes("AM"))) {
            const [hours, minutes, seconds] = timeString.replace(" PM", "").replace(" AM", "").split(":");
            const adjustedHours = (parseInt(hours) % 12) || 12;
            const period = parseInt(hours) >= 12 ? "PM" : "AM";
            return `${adjustedHours}:${minutes}:${seconds} ${period}`;
        } else {
            return timeString;
        }
    };

    const calculateWorkHours = (login, logout, lunchout, lunchin) => {
        // Parse the time strings to Date objects
        const loginTime = new Date(`1970-01-01T${login}:00`);
        const logoutTime = new Date(`1970-01-01T${logout}:00`);
        const lunchoutTime = new Date(`1970-01-01T${lunchout}:00`);
        const lunchinTime = new Date(`1970-01-01T${lunchin}:00`);

        console.log("login", login)
        console.log("lunchout", lunchout)
        console.log("lunchin", lunchin)
        console.log("logout", logout)
        // Calculate the total work time in milliseconds
        let totalWorkTime = logoutTime - loginTime;

        // Deduct the lunch break time if applicable
        if (lunchout && lunchin) {
            totalWorkTime -= lunchinTime - lunchoutTime;
        }

        // Calculate hours and minutes
        const hours = Math.floor(totalWorkTime / (1000 * 60 * 60));
        const minutes = Math.floor((totalWorkTime % (1000 * 60 * 60)) / (1000 * 60));

        // Return the formatted work hours
        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    };


    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
    
        // Check if there's a selected option
        if (!selectedOption) {
            toast.error("Please select an action first.");
            return;
        }
    
        // Update the time based on the selected option
        const updatedTime = new Date();
        const hours = String(updatedTime.getHours()).padStart(2, '0');
        const minutes = String(updatedTime.getMinutes()).padStart(2, '0');
        const currentTimeString = `${hours}:${minutes}`;
    
        const localid = localStorage.getItem("id");
        const currentDate = new Date().toISOString().split('T')[0];
    
        const lastIndex = responseData.length - 1;
        const lastEntry = responseData[lastIndex];
    
        // Update state based on selected option
        setUpdateData((previous) => ({
            ...previous,
            [selectedOption]: currentTimeString
        }));
    
        try {
            const formData = {
                name: lastEntry.name,
                date: currentDate,
                login: lastEntry.login,
                lunchout: selectedOption === 'lunchout' ? currentTimeString : updateData.lunchout || lastEntry.lunchout,
                lunchin: selectedOption === 'lunchin' ? currentTimeString : updateData.lunchin || lastEntry.lunchin,
                logout: selectedOption === 'logout' ? currentTimeString : updateData.logout || lastEntry.logout,
                workhour: calculateWorkHours(
                    lastEntry.login,
                    selectedOption === 'logout' ? currentTimeString : updateData.logout || lastEntry.logout,
                    selectedOption === 'lunchout' ? currentTimeString : updateData.lunchout || lastEntry.lunchout,
                    selectedOption === 'lunchin' ? currentTimeString : updateData.lunchin || lastEntry.lunchin
                )
            };
    
            const response = await axios.put(`${apiUrl}/attendance_date/${localid}`, formData);
            toast.success("Update successfully!");
            fetchData(); // Refresh data after update
            setSelectedOption(""); // Reset selected option
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };
    




    const handleCloseModal = () => {
        setShowModals(false);
    }
    const [getdeleteid , setdeleteid] = useState('')
    const [getdeletename , setdeletename] = useState('')
    const handleOpenModal =(id, empid )=>{
        console.log(" the id", id)
        console.log(" the name", empid)
        setdeleteid(id);
        setdeletename(empid);
        setShowModals(true);
    }


    const handleDelete = async (id) => {
        try {
            await axios.delete(apiUrl + `/attendance/${getdeleteid}`);
            toast.success("Delete successfully!");
            setShowModals(false);
            fetchData();
        } catch (error) {
            console.error("Error deleting data:", error.message);
        }
    };

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };


    const railwayToNormalTime = (railwayTime) => {
        if (!railwayTime) return '';
        const [hour, minute] = railwayTime.split(':');
        const parsedHour = parseInt(hour);
        const parsedMinute = parseInt(minute);
        if (parsedHour < 12) {
            return `${parsedHour}:${minute} AM`;
        } else if (parsedHour === 12) {
            return `${parsedHour}:${minute} PM`;
        } else {
            return `${parsedHour - 12}:${minute} PM`;
        }
    };

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState("");
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Search
    const filtered = currentItems.filter((item) =>
        Object.values(item).some(
            (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (typeof value === "number" && value.toString().includes(searchQuery))
        )
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(responseData.length / itemsPerPage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage === totalPages ? prevPage : prevPage + 1
        );
    };

    const goToPrevPage = () => {
        setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
    };

    const parseRailwayTimeToDate = (dateString, timeString) => {
        const [hours, minutes] = timeString.split(':').map(Number);
        const date = new Date(dateString);
        date.setHours(hours);
        date.setMinutes(minutes);
        return date;
    };

    const events = responseData.flatMap((item) => {
        const convertRailwayTimeToNormalTime = (timeString) => {
            if (!timeString) return '';
            const [hours, minutes] = timeString.split(':').map(Number);
            const period = hours >= 12 ? 'PM' : 'AM';
            const hours12 = hours % 12 || 12;
            return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
        };

        return [
            {
                id: `${item.id}-login`,
                title: convertRailwayTimeToNormalTime(item.login),
                start: parseRailwayTimeToDate(item.date, item.login),
                end: parseRailwayTimeToDate(item.date, item.login),
                allDay: false,
                type: 'login'
            },
            // {
            //     id: `${item.id}-lunchout`,
            //     title: convertRailwayTimeToNormalTime(item.lunchout),
            //     start: parseRailwayTimeToDate(item.date, item.lunchout),
            //     end: parseRailwayTimeToDate(item.date, item.lunchout),
            //     allDay: false,
            //     type: 'lunchout'
            // },
            // {
            //     id: `${item.id}-lunchin`,
            //     title: convertRailwayTimeToNormalTime(item.lunchin),
            //     start: parseRailwayTimeToDate(item.date, item.lunchin),
            //     end: parseRailwayTimeToDate(item.date, item.lunchin),
            //     allDay: false,
            //     type: 'lunchin'
            // },
            {
                id: `${item.id}-logout`,
                title: convertRailwayTimeToNormalTime(item.logout),
                start: parseRailwayTimeToDate(item.date, item.logout),
                end: parseRailwayTimeToDate(item.date, item.logout),
                allDay: false,
                type: 'logout'
            }
        ];
    });


    const eventPropGetter = (event) => {
        let backgroundColor = '';
        let borderRadius = '0%'; // Default border radius
        let className = '';
        switch (event.type) {
            case 'login':
                const loginHour = event.start.getHours();
                if (loginHour < 9.5) {
                    backgroundColor = 'green';
                } else {
                    backgroundColor = 'red';
                }
                borderRadius = '100px'; // Set border radius for login events
                className = 'login-event';
                break;
            case 'lunchout':
            case 'lunchin':
                backgroundColor = 'orange';
                borderRadius = '100px';
                className = 'login-event';
                break;
            case 'logout':
                backgroundColor = 'green';
                borderRadius = '100px';
                className = 'login-event';
                break;
            default:
                backgroundColor = 'grey';
        }
        return {
            style: { backgroundColor, borderRadius, height: '15px', textAlign: 'center', width: '60px' },
            className
        };
    };



    return (
        <>
            <div className='container'>
                <h5>Attendance</h5>
                <div className='row mt-3'>
                    <div className='col-lg-4 viewbtn'>
                        <button className='btn btn-secondary view' onClick={() => setShowCalendar(true)}>Calendar View</button>
                        <button className='btn btn-secondary view mx-2' onClick={() => setShowCalendar(false)}>Table View</button>
                    </div>
                    <div className='col-lg-1'></div>
                    <div className='col-lg-2'>
                        <h5 className='mx-4 time'>Time</h5>
                        <p className='currenttime'><b>{currentTime.toLocaleTimeString()}</b></p>
                    </div>
                    <div className='col-lg-1'></div>
                    <div className='col-lg-4'>
                        <form onSubmit={handleUpdateSubmit}>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <select className='form-select' onChange={(e) => handleOptionChange(e.target.value)} disabled={isLoggedOut}>
                                        <option value="">Select Action</option>
                                        <option value="lunchout">Lunchout</option>
                                        <option value="lunchin">Lunchin</option>
                                        <option value="logout">Logout</option>
                                    </select>
                                </div>
                                <div className='col-lg-6 d-flex justify-content-end'>
                                    <button className='btn btn-success custom-width' type='submit'>Submit</button>
                                </div>
                            </div>
                        </form>

                    </div>

                </div>
            </div>

            {!showCalendar ? (
                <>
                    <div className="mb-3 mt-3">
                        <div className="row">
                            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                                <label className='form-label text-dark mt-2'>Show : </label>
                                <div>
                                    <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='20'>20</option>
                                        <option value='50'>50</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-4 col-sm-1"></div>
                            <div className="col-lg-3 col-md-4 col-sm-7">

                                <div className="w-full flex-1">
                                    <form>
                                        <div className="relative">
                                            <input
                                                className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                                                placeholder="Search ..."
                                                type="search"
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-2">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>S.NO</th>
                                    <th>Employee Id</th>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Log In</th>
                                    <th>Lunch Out</th>
                                    <th>Lunch In</th>
                                    <th>Log Out</th>
                                    <th>Working hrs</th>
                                    {/* <th className='text-center'>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {filtered.map((item, index) => {
                                    const loginTime = moment(item.login, 'HH:mm');
                                    const thresholdTime = moment('09:30', 'HH:mm');
                                    const isOnTime = loginTime.isSameOrBefore(thresholdTime);

                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.empid}</td>
                                            <td>{item.name}</td>
                                            <td>{item.date}</td>
                                            <td>
                                                {isOnTime ? (
                                                    <i className="fas fa-check-circle mx-1" style={{ color: 'green' }}></i>
                                                ) : (
                                                    <i className="fas fa-times-circle mx-1" style={{ color: 'red' }}></i>
                                                )}
                                                {railwayToNormalTime(item.login)}

                                            </td>
                                            <td>{railwayToNormalTime(item.lunchout)}</td>
                                            <td>{railwayToNormalTime(item.lunchin)}</td>
                                            <td>{railwayToNormalTime(item.logout)}</td>
                                            <td>{item.workhour} hrs</td>
                                            {/* <td className='text-center'>
                                                <button className='btn btn-danger'  onClick={() =>  handleOpenModal(item.id, item.empid) }>
                                                    <i className="fa-solid fa-trash-can"></i>
                                                </button>
                                            </td> */}
                                        </tr>
                                    );
                                })}

                            </tbody>
                        </table>
                    </div>

                    <div className="mt-3 pb-5">
                        <div className="row">
                            <div className="col-lg-10 col-sm-12">
                                <nav className="d-flex float-right">
                                    <ul className="pagination justify-content-center">
                                        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                            <button className="page-link" onClick={goToPrevPage}>
                                                Previous
                                            </button>
                                        </li>
                                        {Array.from({ length: totalPages }, (_, i) => (
                                            <li
                                                key={i}
                                                className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""}`}
                                            >
                                                <button className="page-link" onClick={() => paginate(i + 1)}>
                                                    {i + 1}
                                                </button>
                                            </li>
                                        ))}
                                        <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                                            <button className="page-link" onClick={goToNextPage}>
                                                Next
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="col-lg-2 col-sm-12 mt-1">
                                <div className="float-end">
                                    <span className="text-dark">Page {currentPage} of {totalPages}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="calendar-container mt-0">
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 400 }}
                        eventPropGetter={eventPropGetter}
                    />
                </div>
            )}



            {/* ----------------delete */}

<Modal className="modal" show={showModals} onHide={handleCloseModal}>
                <Modal.Header>
                    <p>Are you sure you want to delete?</p>
                </Modal.Header>
                <Modal.Body>
                <label className="form-label d-flex">
                <h5 className='mx-1'> Employee Id:</h5> <h5>{getdeletename} </h5>
                    </label>
                   
                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Attendancestaff;
