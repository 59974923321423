import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import apiUrl from '../../environment/Api';
import { toast } from 'react-toastify';


function Branch() {

    const [responseData, setResponseData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isUpdateshowModal, setUpdateShowModal] = useState(false);

    const [role_id, setroleid] = useState('');

    const [previllages, setprevillages] = useState({})

    const [addData, setAddData] = useState({
        branch_id: '',
        branch_name: '',
        status: ''
    });


    const [updateData, setUpdateData] = useState({
        branch_id: '',
        branch_name: '',
        status: ''
    });

    useEffect(() => {
        fetchData();

        gettoken();
        //console.log("The role id in branch is : ",localStorage.getItem('role_id'));
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + "/Branch");
            console.log("Response from server:", response.data);
            setResponseData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const gettoken = async () => {
        const token = localStorage.getItem('token');
        // console.log("the toekn in the localStorage is : ",token);
        try {
            const response = await axios.post(apiUrl + "/get_detail", { "token": token });
            console.log("token detail from server:", response.data);
            // setResponseData(response.data);

            console.log("The role id is : ", response.data.detail.rolename);
            setroleid(response.data.detail.rolename);
            console.log("the role_id is : ", role_id);

            localStorage.setItem('role_id', response.data.detail.rolename);

            const id = response.data.detail.rolename

            // getpages();

            if (id) {
                try {
                    const response = await axios.get(apiUrl + `/role_2/${id}`);

                    console.log("the reponse list in brach is : ", response.data.modules);

                    const modules = response.data.modules;

                    const filtered_access = modules.filter(item => item.name == "Branch")

                    console.log("the modules is : ", filtered_access[0].actions);

                    setprevillages(filtered_access[0].actions);

                    console.log("the assigned previllages is : ", previllages.add);

                } catch (error) {
                    console.error("Error fetching data:", error.message);
                }
            }

        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    }



    const handleNewButtonClick = () => {
        if (previllages.add || previllages.all) {
            setShowModal(true);
        } else {
            toast.error("You don't have the permission");
        }

    };

    const handlesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {


                branch_id: addData.branch_id,
                branch_name: addData.branch_name,
                status: addData.status

            }
            const response = await axios.post(apiUrl + "/Branch", formData);
            console.log("Updated:", response);
            setShowModal(false);
            toast.success("added successfully!");
            setAddData({
                name: ''
            });
            fetchData();
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };


    const [updateid, setUpdateId] = useState("");

    const handleUpdate = (_id, data) => {
        if (previllages.edit || previllages.all) {
            setUpdateId(_id)
            console.log(" the data to updata", data)
            setUpdateShowModal(true);
            setUpdateData({
                branch_id: data.branch_id,
                branch_name: data.branch_name,
                status: data.status
            })
        } else {
            toast.error("You don't have the permission");
        }
    }



    const handleupdatesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {

                branch_id: updateData.branch_id,
                branch_name: updateData.branch_name,
                status: updateData.status
            }
            console.log("the update submit data", formData)
            const response = await axios.put(apiUrl + `/Branch/${updateid}`, formData)
            console.log("response for update", response)
            setUpdateShowModal(false)
            toast.success("Update successfully!");
            fetchData();
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    }



    const handleDelete = async (id) => {
        try {

            const response = await axios.delete(apiUrl + `/Branch/${getdeleteid}`)
            console.log("delete data ", response)
            toast.error("Delete successfully!");
            setDeleteShowModals(false);
            fetchData();
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    };


    const [deleteShowModals, setDeleteShowModals] = useState(false);
    const handleCloseModal = () => {
        setDeleteShowModals(false);
    }

    const [getdeleteid, setdeleteid] = useState('')
    const [getdeletename, setdeletename] = useState('')
    
    const handleOpenModal = (_id, branch_name) => {

        if(previllages.all || previllages.delete){
            console.log(" the id", _id)
            console.log(" the name", branch_name)
            setdeleteid(_id);
            setdeletename(branch_name);
            setDeleteShowModals(true);
        }else{
            toast.error("You don't have the permission");
        }
    }


    // the pagination ---------------------------------------------------------------------
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState("");
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Search
    const filtered = currentItems.filter((item) =>
        Object.values(item).some(
            (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (typeof value === "number" && value.toString().includes(searchQuery))
        )
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(responseData.length / itemsPerPage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage === totalPages ? prevPage : prevPage + 1
        );
    };

    const goToPrevPage = () => {
        setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
    };
    // the pagination  end ---------------------------------------------------------------------
    return (
        <div className='top'>
            {/* Table */}
            <div className='d-flex'>
                <h5 className='mt-2'>Branch</h5>
                <div className='mx-3'><button className='btn  add' onClick={handleNewButtonClick}>Add</button></div>
            </div>

            {/* ------------ search and per page ----------------------------*/}

            <div className="mb-3 mt-3">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                        <label className='form-label text-dark mt-2'>Show : </label>
                        <div>
                            <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='20'>20</option>
                                <option value='50'>50</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-4 col-sm-1"></div>
                    <div className="col-lg-3 col-md-4 col-sm-7">

                        <div className="w-full flex-1">
                            <form>
                                <div className="relative">

                                    <input
                                        className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                                        placeholder="Search ..."
                                        type="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* --------------------------------end------------------------------------------- */}
            <div className='card'>
                <div className="mt-1">
                    <table className="table table-striped "  >
                        <thead >
                            <tr>
                                <th>S.No</th>
                                <th>Branch</th>
                                <th>Branch Name</th>
                                <th>Status</th>
                                <th className='text-center'>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {filtered.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.branch_id}</td>
                                    <td>{item.branch_name}</td>

                                    {/* <td  ><p className={item.status === 'Active' ? 'active-row' : 'inactive-row'}>{item.status}</p></td> */}

                                    {(item.status == "Active") ?
                                        <td className='text-center hideres '>
                                            <p className=' text-white green-label' >{item.status}</p>
                                        </td>
                                        : <td className='text-center hideres'  ><p className='text-white red-label'>{item.status}</p></td>
                                    }


                                    <td className='text-center'>

                                        <button className='btn updelete mx-2' onClick={() => handleUpdate(item._id, item)}><i class="fa-solid fa-pen-to-square"></i></button>
                                        <button className='btn delete'
                                            onClick={() => handleOpenModal(item._id, item.branch_name)}
                                        > <i class="fa-solid fa-trash-can"></i></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Modal for adding a new user */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add branch </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="form-group">

                        <div className="floating-label">
                            <input
                                className="form-control"
                                type="text"
                                placeholder=''
                                value={addData.branch_id}
                                onChange={(e) => setAddData({ ...addData, branch_id: e.target.value })}
                                required
                            />
                            <label className={addData.branch_id ? "filled" : ""}>
                                Branch
                            </label>
                        </div>


                        <div className="floating-label">
                            <input
                                className="form-control"
                                type="text"
                                placeholder=''
                                value={addData.branch_name}
                                onChange={(e) => setAddData({ ...addData, branch_name: e.target.value })}
                                required
                            />
                            <label className={addData.branch_name ? "filled" : ""}>
                                Branch Name
                            </label>
                        </div>

                        {/* Status Select Dropdown */}
                        <div className="floating-label">
                            <select
                                className="form-control"
                                value={addData.status}
                                placeholder='Select status'
                                onChange={(e) => setAddData({ ...addData, status: e.target.value })}
                            >
                                <option value=""></option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                            <label className={addData.status ? "filled" : ""}>
                                Select status
                            </label>
                        </div>

                    </div>


                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setShowModal(false)}>cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handlesubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Branch </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* Other input fields */}

                    <div className="form-group">

                        <div className="floating-label">
                            <input className="form-control" type="text" placeholder='' value={updateData.branch_id} onChange={(e) => setUpdateData({ ...updateData, branch_id: e.target.value })} required />
                            <label className={updateData.branch_id ? "filled" : ""}>
                                Branch Code
                            </label>
                        </div>

                        <div className="floating-label">
                            <input className="form-control" type="text" placeholder='' value={updateData.branch_name} onChange={(e) => setUpdateData({ ...updateData, branch_name: e.target.value })} required />
                            <label className={updateData.branch_name ? "filled" : ""}>
                                Branch Name
                            </label>
                        </div>



                        <div className="floating-label">
                            <select className="form-control" value={updateData.status} onChange={(e) => setUpdateData({ ...updateData, status: e.target.value })}>
                                <option value="">Select status</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                            <label className={updateData.status ? "filled" : ""}>
                                Select status
                            </label>
                        </div>



                    </div>







                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handleupdatesubmit}>Update</Button>
                </Modal.Footer>
            </Modal>





            {/* ----------------delete */}

            <Modal className="modal" show={deleteShowModals} onHide={handleCloseModal}>
                <Modal.Header>
                    <p>Are you sure you want to delete?</p>
                </Modal.Header>
                <Modal.Body>


                    <label className="form-label d-flex">
                        <h5 className='mx-1'>branch Name :</h5> <h5>{getdeletename} </h5>
                    </label>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
                </Modal.Footer>
            </Modal>
            {/* _------------------------------pagination button -------------------------------- */}


            <div className="mt-3 pb-5">
                <div className="row">

                    <div className="col-lg-10 col-sm-12">
                        <nav className="d-flex float-right">
                            <ul className="pagination justify-content-center">
                                <li
                                    className={` page-item ${currentPage === 1 ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link" onClick={goToPrevPage}>
                                        Previous
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <li
                                        key={i}
                                        className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                                            }`}
                                    >
                                        <button
                                            className="page-link "
                                            onClick={() => paginate(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link " onClick={goToNextPage}>
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>{" "}
                    </div>
                    <div className="col-lg-2 col-sm-12 mt-1">
                        <div className="float-end">
                            <span className="text-dark ">
                                Page {currentPage} of {totalPages}
                            </span>
                        </div>
                    </div>

                </div>
            </div>


            {/* ---------------------------------------end ---------------------------------- */}


        </div>
    )
}

export default Branch;