import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import apiUrl from '../../environment/Api';
import { toast } from 'react-toastify';
function Leave() {

    const [responseData, setResponseData] = useState([]);
    const [responseDatas, setResponseDatas] = useState([]);
    const [branchDatas, setBranchDatas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isUpdateshowModal, setUpdateShowModal] = useState(false);
    const [deleteShowModals, setDeleteShowModals] = useState(false);

    const [role_id, setroleid] = useState('');
    const [previllages, setprevillages] = useState({})

    // current date//////////////////////////////
    const formatDate = (date) => {
        if (!(date instanceof Date)) {
            date = new Date(date); // Convert input to Date if it's not already
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    const [addData, setAddData] = useState({

        // branch_id : req.body.branch_id,
        // empid : req.body.empid,
        // date : req.body.date,
        // reason : req.body.reason,
        // status : req.body.status

        branch_id: '',
        empid: '',
        date: formatDate(new Date()),
        reason: '',
        status: ''

    });


    const [updateData, setUpdateData] = useState({

        branch_id: '',
        empid: '',
        date: formatDate(new Date()),
        reason: '',
        status: ''


    })



    const handleDateChange = (e) => {
        setAddData(prevData => ({
            ...prevData,
            date: e.target.value // Input type=date handles the format
        }));
    };


    useEffect(() => {
        fetchData();

    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + "/leave");
            console.log("leave data:", response.data);
            setResponseData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        fetchDatas();
        fetchDat();
        gettoken();
    }, []);



    const fetchDat = async () => {
        try {
            const response = await axios.get(apiUrl + "/Branch");
            console.log("Responseee from server:", response.data);
            setBranchDatas(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const fetchDatas = async () => {
        try {
            const response = await axios.get(apiUrl + "/staff");
            console.log("Response from server:", response.data);
            setResponseDatas(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };


    const gettoken = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(apiUrl + "/get_detail", { "token": token });
            console.log("token detail from server:", response.data);
     
       
            setroleid(response.data.detail.rolename);
         
            localStorage.setItem('role_id', response.data.detail.rolename);

            const id = response.data.detail.rolename

            if (id) {
                try {
                    const response = await axios.get(apiUrl + `/role_2/${id}`);


                    const modules = response.data.modules;

                    const filtered_access = modules.filter(item => item.name == "Branch")

                   
                    setprevillages(filtered_access[0].actions);

                  

                } catch (error) {
                    console.error("Error fetching data:", error.message);
                }
            }

        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    }









    const handleNewButtonClick = () => {
        if (previllages.add || previllages.all) {
        setShowModal(true);
    }else{
        toast.error("You don't have the permission to add");
    }
}


    const handlesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {


                branch_id: addData.branch_id,
                empid: addData.empid,
                date: addData.date,
                reason: addData.reason,
                status: addData.status


            }
            const response = await axios.post(apiUrl + "/leave", formData);
            console.log("Updated:", response);
            setShowModal(false);
            toast.success("User added successfully!");
            fetchData();
            setAddData({


                branch_id: '',
                empid: '',
                date: '',
                reason: '',
                status: ''

            });
         
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };



    const [updateid, setUpdateId] = useState("");

    const handleUpdate = (_id, data) => {
        if (previllages.edit || previllages.all) {
        setUpdateId(_id)
        console.log(" the data to updata", data)
        setUpdateShowModal(true);
        setUpdateData({

            branch_id: data.branch_id,
            empid: data.empid,
            date: data.date,
            reason: data.reason,
            status: data.status
        })
    }else{
        toast.error("You don't have the permission to update");
    }
 }
    const handleupdatesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {

                branch_id: updateData.branch_id,
                empid: updateData.empid,
                date: updateData.date,
                reason: updateData.reason,
                status: updateData.status
            }
            console.log("the update submit data", formData)
            const response = await axios.put(apiUrl + `/leave/${updateid}`, formData)
            console.log("response for update", response)
            setUpdateShowModal(false)
            toast.success("Update successfully!");
            fetchData();
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    }



    const handleDelete = async (id) => {
        try {
            await axios.delete(apiUrl + `/leave/${getdeleteid}`);
            toast.error("Deleted successfully!");
            setDeleteShowModals(false);
            fetchData();
        } catch (error) {
            console.error("Error deleting role:", error.message);
        }
    };



    const handleCloseModal = () => {
        setDeleteShowModals(false);
    }

    const [getdeleteid, setdeleteid] = useState('')
    const [getdeletename, setdeletename] = useState('')
    const handleOpenModal = (_id, empid) => {
        
        if(previllages.all || previllages.delete){
        console.log(" the id", _id)
        console.log(" the name", empid)
        setdeleteid(_id);
        setdeletename(empid);
        setDeleteShowModals(true);
    }else{
        toast.error("You don't have the permission to delete");
    }
}

    // the pagination ---------------------------------------------------------------------
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState("");
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Search
    const filtered = currentItems.filter((item) =>
        Object.values(item).some(
            (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (typeof value === "number" && value.toString().includes(searchQuery))
        )
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(responseData.length / itemsPerPage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage === totalPages ? prevPage : prevPage + 1
        );
    };

    const goToPrevPage = () => {
        setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
    };
    // the pagination  end ---------------------------------------------------------------------
    return (
        <div className='top'>


            <div className='row'>
                <div className=' col-lg-9 d-flex'>
                    <h5 className='mt-2'>Leave Details</h5>
                    <div className='mx-3'><button className='btn add' onClick={handleNewButtonClick}>Add </button></div>
                </div>
            </div>


            {/* ------------ search and per page ----------------------------*/}

            <div className="mb-3 mt-3">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                        <label className='form-label text-dark mt-2'>Show : </label>
                        <div>
                            <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='20'>20</option>
                                <option value='50'>50</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-4 col-sm-1"></div>
                    <div className="col-lg-3 col-md-4 col-sm-7">

                        <div className="w-full flex-1">
                            <form>
                                <div className="relative">

                                    <input
                                        className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                                        placeholder="Search ..."
                                        type="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* --------------------------------end------------------------------------------- */}


            {/* Table */}
            <div className='card'>
                <div className="mt-1">
                    <table className="table table-striped">
                        <thead>


                            {/* branch_id: data.branch_id,
            empid: data.empid,
            date: data.date,
            reason: data.reason,
            status: data.status */}
                            <tr>
                                <th>S.No</th>
                                <th>Branch</th>
                                <th>Employee Id</th>
                                <th>Date</th>
                                <th>Reason</th>
                                <th>Status</th>
                                <th className='text-center'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtered.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.branch_id.branch_name}</td>
                                    <td>{item.empid}</td>
                                    <td>{new Date(item.date).toLocaleDateString()}</td>
                                    <td>{item.reason}</td>
                                    {/* <td>
                                        <p className={
                                            item.status === 'Approved' ? 'approved-row' :
                                                (item.status === 'Rejected' ? 'rejected-row' :
                                                    (item.status === 'Pending' ? 'pending-row' : 'default-row'))
                                        }>
                                            {item.status}
                                        </p>

                                    </td> */}

                                    <td className='text-center hideres'>
                                        {item.status === "Approved" ? (
                                            <p className='text-white green-label'>{item.status}</p>
                                        ) : item.status === "pending" ? (
                                            <p className='text-white yellow-label'>{item.status}</p>
                                        ) : (
                                            <p className='text-white red-label'>{item.status}</p>
                                        )}
                                    </td>


                                    <td className='text-center'>
                                        <button className='btn updelete mx-2' onClick={() => handleUpdate(item._id, item)}><i class="fa-solid fa-pen-to-square"></i></button>
                                        <button className='btn delete'
                                            onClick={() => handleOpenModal(item._id, item.empid)}
                                        ><i class="fa-solid fa-trash-can"></i></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Modal for adding a new user */}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Leave</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* branch id */}
                    <div className="form-group">

                        <div className="floating-label">
                            <label className="form-label">
                                <b></b>
                            </label>
                            <select className="form-select" value={addData.branch_id._id} placeholder='' onChange={(e) => setAddData({ ...addData, branch_id: e.target.value })}>
                                <option value="">Select Brand</option>
                                {branchDatas.map((item, index) => (
                                    <option key={index} value={item._id}>
                                        {item.branch_id} - {item.branch_name}
                                    </option>
                                ))}
                            </select>

                            <label className={addData.branch_id ? "filled" : ""}>
                                Select Branch 
                            </label>
                        </div>


                        {/* EMP ID */}
                        <div className="floating-label">
                            <label className="form-label">
                                <b></b>
                            </label>
                            <select className="form-select" value={addData.empid} onChange={(e) => setAddData({ ...addData, empid: e.target.value })}>
                                <option value="">Select Employee Id</option>
                                {responseDatas.map((item, index) => (
                                    <option key={index} value={item.empid}>
                                        {item.empid} - {item.name}
                                    </option>
                                ))}
                            </select>

                            <label className={addData.empid ? "filled" : ""}>
                                Select Employee Id
                            </label>
                        </div>


                        {/* Other input fields */}
                        <div className="floating-label">
                            <input className="form-control" type="date" placeholder='' value={addData.date} onChange={(e) => setAddData({ ...addData, date: e.target.value })} required />
                            <label className={addData.date ? "filled" : ""}>
                                date
                            </label>
                        </div>

                        <div className="floating-label">
                            <input className="form-control" type="text" placeholder='' value={addData.reason} onChange={(e) => setAddData({ ...addData, reason: e.target.value })} required />
                            <label className={addData.reason ? "filled" : ""}>
                                Reason
                            </label>
                        </div>


                        <div className="floating-label">
                            <select className="form-control" value={addData.status} onChange={(e) => setAddData({ ...addData, status: e.target.value })}>
                                <option value="">Select status</option>
                                <option value="Approved">Approved</option>
                                <option value="Pending">Pending</option>
                                <option value="Rejected">Rejected</option>
                            </select>
                            <label className={addData.status ? "filled" : ""}>
                                Select status
                            </label>
                        </div>





                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handlesubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>


            {/* ----------------update */}


            <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Leave</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="form-group">

                        <div className="floating-label">
                            <select className="form-select" value={updateData.branch_id._id} onChange={(e) => setUpdateData({ ...updateData, branch_id: e.target.value })}>
                                <option value="">Select Brand Id</option>
                                {branchDatas.map((item, index) => (
                                    <option key={index} value={item._id}>
                                        {item.branch_id} - {item.branch_name}
                                    </option>
                                ))}
                            </select>
                            <label className={updateData.branch_id.branch_name ? "filled" : ""}>
                            
                            </label>

                        </div>

                     


                        <div className="floating-label ">
                            <select className="form-select" value={updateData.empid} onChange={(e) => setUpdateData({ ...updateData, empid: e.target.value })}>
                                <option value="">Select Emp Id</option>
                                {responseDatas.map((item, index) => (
                                    <option key={index} value={item.empid}>{item.empid}- {item.name}</option>
                                ))}
                            </select>
                            <label className={updateData.empid ? "filled" : ""}>
                                Select Employee Id
                            </label>

                        </div>

                        {/* emp ID */}

                        <div className="floating-label">
                            <input className="form-control" type="date" placeholder='' value={updateData.date} onChange={(e) => setUpdateData({ ...updateData, date: e.target.value })} required />
                            <label className={updateData.date ? "filled" : ""}>
                                Date
                            </label>
                        </div>



                        <div className="floating-label">
                            <input className="form-control" type="text" placeholder='' value={updateData.reason} onChange={(e) => setUpdateData({ ...updateData, reason: e.target.value })} required />
                            <label className={updateData.reason ? "filled" : ""}>
                                Reason
                            </label>
                        </div>


                        <div className="floating-label">
                            <select className="form-control" value={updateData.status} onChange={(e) => setUpdateData({ ...updateData, status: e.target.value })}>
                                <option value="">Select status</option>
                                <option value="Approved">Approved</option>
                                <option value="Pending">Pending</option>
                                <option value="Rejected">Rejected</option>
                            </select>
                            <label className={updateData.status ? "filled" : ""}>
                                Select status
                            </label>
                        </div>





                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handleupdatesubmit}>Update</Button>
                </Modal.Footer>
            </Modal>

            {/* ----------------delete */}

            <Modal className="modal" show={deleteShowModals} onHide={handleCloseModal}>
                <Modal.Header>
                    <p>Are you sure you want to delete?</p>
                </Modal.Header>
                <Modal.Body>
                    <label className="form-label d-flex">
                        <h5 className='mx-1'>Employee Id:</h5> <h5>{getdeletename} </h5>
                    </label>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
                </Modal.Footer>
            </Modal>



            {/* _------------------------------pagination button -------------------------------- */}
            <div className="mt-3 pb-5">
                <div className="row">
                    <div className="col-lg-10 col-sm-12">
                        <nav className="d-flex float-right">
                            <ul className="pagination justify-content-center">
                                <li
                                    className={` page-item ${currentPage === 1 ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link" onClick={goToPrevPage}>
                                        Previous
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <li
                                        key={i}
                                        className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                                            }`}
                                    >
                                        <button
                                            className="page-link "
                                            onClick={() => paginate(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link " onClick={goToNextPage}>
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>{" "}
                    </div>
                    <div className="col-lg-2 col-sm-12 mt-1">
                        <div className="float-end">
                            <span className="text-dark ">
                                Page {currentPage} of {totalPages}
                            </span>
                        </div>
                    </div>
                </div>
            </div>


            {/* ---------------------------------------end ---------------------------------- */}
        </div>
    )
}
export default Leave;