import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiUrl from '../../environment/Api';
import '../../assets/css/Addstudent.css';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

function Addstudent() {
    const [responseDatas, setResponseDatas] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [branch, setbranch] = useState([]);


    const navigate = useNavigate();
    const handlecancel = () => {
        navigate("/student");
    };



    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };



    const [addData, setAddData] = useState({



        branch_id: '',
        selectcourse: '',
        student_name: '',
        father_name: '',
        timings: '',
        date: formatDate(new Date()),
        dateofbirth: '',
        qualification: '',
        selectcourse: '',
        mobile_no: '',
        email: '',
        notes: '',
        status: '',
        total_fees: '',
        paid: '',
        balance: '',
        student_type: '',
        source_type: '',
        location: '',
        callback_date: '',
        converted_date: '',
        completed_date: '',
        completed: '',
        // converted_status: '',
        roll_no: '',
        photo_upload: '',
        id_proof: '',
    });

    const handlesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {
                branch_id: addData.branch_id,
                student_name: addData.student_name,
                father_name: addData.father_name,
                qualification: addData.qualification,
                selectcourse: addData.selectcourse,
                timings: addData.timings,
                date: addData.date,
                dateofbirth: addData.dateofbirth,
                mobile_no: addData.mobile_no,
                email: addData.email,
                notes: addData.notes,
                location: addData.location,
                student_type: addData.student_type,
                source_type: addData.source_type,
                status: addData.status,
                callback_date: addData.callback_date,
                converted_date: addData.converted_date,
                completed_date: addData.completed_date,
                completed: addData.completed,
                // converted_status: addData.converted_status,
                totalfees: '',
                paid: '',
                balance: '',
                roll_no: addData.roll_no,
                photo_upload: addData.photo_upload,
                id_proof: addData.id_proof,

            }
            console.log("formdata", formData)


            if (addData.photo_upload) formData.append('photo_upload', addData.photo_upload);
            if (addData.id_proof) formData.append('id_proof', addData.id_proof);

            const response = await axios.post(apiUrl + "/student", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log("Updated:", response);


            toast.success("added successfully!");

            setAddData({
                branch_id: '',
                selectcourse: '',
                student_name: '',
                father_name: '',
                timings: '',
                date: formatDate(new Date()),
                dateofbirth: '',
                qualification: '',
                mobile_no: '',
                email: '',
                notes: '',
                status: '',
                total_fees: '',
                paid: '',
                balance: '',
                student_type: '',
                source_type: '',
                location: '',
                callback_date: '',
                // converted_status: '',
                completed: '',
                roll_no: '',
                photo_upload: null,
                id_proof: null,
            });
            fetchData();
            navigate("/student");
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };




    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + "/student");
            console.log("Response from server:", response.data);
            if (response && response.data) {
                const data = response.data;
                const filteredData = data.filter(item => item.status !== 'Converted');
                setResponseData(filteredData);
            }
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };
    useEffect(() => {
        fetchDatas();
        fetchDatased();

    }, []);


    const fetchDatas = async () => {
        try {
            const response = await axios.get(apiUrl + "/course");


            if (response && response.data) {
                const data = response.data;

                setResponseDatas(data);
                console.log("Response from servers63s3s:", data);
            } else {
                console.error("No data received from server.");
            }
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };


    const handleStatusChange = (e) => {
        const selectedStatus = e.target.value;
        console.log("the select status", selectedStatus)
        setAddData({ ...addData, status: selectedStatus });

        if (selectedStatus === "Callback") {
            setShowCallbackDate(true);
        }
        else if (selectedStatus === "Converted") {
            setShowCallbackDate(true);
        } else {
            setShowCallbackDate(false);
            setAddData(prevData => ({
                ...prevData,
                callback_date: '',
                converted_date: '',
            }));
        }
    };


    const [studentId, setSutudentId] = useState([])
    const [stafftId, setStaffId] = useState([])
    const fetchDatased = async () => {
        try {
            const response = await axios.get(apiUrl + "/Branch");
            const responsestudent = await axios.get(apiUrl + "/student");
            const responsestaff = await axios.get(apiUrl + "/staff");
            console.log("Response from server:", response.data);
            setbranch(response.data);
            setSutudentId(responsestudent.data)
            setStaffId(responsestaff.data)
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };


    const [showCallbackDate, setShowCallbackDate] = useState(false);


    const [referaltype, setreferaltype] = useState('');


    const handlereferal = (e) => {
        console.log(e.target.value)

        setreferaltype(e.target.value);
    }




    return (

        <div className='style' style={{ marginTop: '-30px' }}>

            <div className='insermodal'>

                <div >
                    <h3>Student Details</h3>
                    {/* <form onSubmit={handlesubmit}> */}
                    {/* Other input fields */}
                    <div className='row mt-5 '>
                        <div className='col-lg-6 ' style={{ marginTop: '-25px' }}>
                            <div className='card  p-2 pb-0'>
                                <div className="form-group">

                                    <div className="floating-label mt-1">
                                        <label className="form-label">
                                            <b></b>
                                        </label>
                                        <select
                                            className="form-select"
                                            placeholder=''
                                            value={addData.branch_id}
                                            onChange={(e) => setAddData({ ...addData, branch_id: e.target.value })}
                                            required
                                        >
                                            <option value="">Select Branch </option>
                                            {branch.map((item, index) => (
                                                <option key={index} value={item._id}>
                                                    {item.branch_name}
                                                </option>
                                            ))}
                                        </select>
                                        <label className={addData.branch_id ? "filled" : ""}>

                                        </label>
                                    </div>



                                    <div className="floating-label">
                                        <input className="form-control" type="text" placeholder='' value={addData.student_name} onChange={(e) => setAddData({ ...addData, student_name: e.target.value })} required />
                                        <label className={addData.student_name ? "filled" : ""}>
                                            Student Name
                                        </label>
                                    </div>


                                    <div className="floating-label">
                                        <input className="form-control" type="tel" placeholder='' value={addData.mobile_no} onChange={(e) => setAddData({ ...addData, mobile_no: e.target.value })} required />
                                        <label className={addData.mobile_no ? "filled" : ""}>
                                            Mobile No
                                        </label>
                                    </div>

                                    <div className="floating-label">
                                        <input className="form-control" type="email" placeholder='' value={addData.email} onChange={(e) => setAddData({ ...addData, email: e.target.value })} required />
                                        <label className={addData.email ? "filled" : ""}>
                                            Email
                                        </label>
                                    </div>




                                    <div className="floating-label">
                                        <select
                                            className="form-control"
                                            onChange={(e) => setAddData({ ...addData, source_type: e.target.value })} required>
                                            <option value=""> Source Type</option>
                                            <option value="google Ad">google Ad</option>
                                            <option value="Linkedln">Linkedln</option>
                                            <option value="Facebook">Facebook</option>
                                            <option value="Naukri">Naukri</option>
                                            <option value="Referal" >Referal</option>

                                        </select>
                                        <label className={addData.source_type ? "filled" : ""}>
                                        Source Type
                                        </label>
                                    </div>


                                    <div className={`${addData.source_type == "Referal" ? "floating-label" : "d-none"}`}>
                                        <select className="form-control" onClick={handlereferal}>
                                            <option value=''>Select Referal Type</option>
                                            <option value='student'>Student</option>
                                            <option value='staff'>Staff</option>
                                        </select>
                                    </div>


                                    <div className={`${referaltype == "student" ? "floating-label" : "d-none"}`}>

                                        <select className="form-control">
                                            <option value=''>Select student</option>
                                            {studentId.map((data, index) => (
                                                <option key={index} value={data.id}>
                                                    {data.student_name} - {data.roll_no}
                                                </option>
                                            ))}
                                        </select>
                                    </div>


                                    <div className={`${referaltype == "staff" ? "floating-label" : "d-none"}`}>
                                        <select className="form-control"   >
                                            <option value=''>Select Staff </option>
                                            {
                                                stafftId.map((data,index)=>(
                                                    <option key={index} value={data._id}> 
                                                        {data.name} -{data.empid}
                                                    </option>

                                                ))}
                                        </select>
                                    </div>























                                    <div className="floating-label">
                                        <input className="form-control" type="text" placeholder='' value={addData.father_name} onChange={(e) => setAddData({ ...addData, father_name: e.target.value })} />
                                        <label className={addData.father_name ? "filled" : ""}>
                                            Father Name
                                        </label>
                                    </div>














                                    <div className="floating-label">
                                        <input className="form-control" type="text" placeholder='' value={addData.qualification} onChange={(e) => setAddData({ ...addData, qualification: e.target.value })} />
                                        <label className={addData.qualification ? "filled" : ""}>
                                            Qualification
                                        </label>
                                    </div>



                                    <div className="floating-label">
                                        <input className="form-control" type="date" placeholder='' value={addData.date} onChange={(e) => setAddData({ ...addData, date: e.target.value })} required />
                                        <label className={addData.date ? "filled" : ""}>
                                            Date
                                        </label>
                                    </div>


                                </div>
                            </div>
                        </div>

                        {/* ////////////////////////////////////////////////// */}
                        <div className='col-lg-6' style={{ marginTop: '-25px' }}>
                            <div className='card p-2 pb-0'>
                                <div className="form-group">


                                    <div className="floating-label">
                                        <input className="form-control" type="date" placeholder='' value={addData.dateofbirth} onChange={(e) => setAddData({ ...addData, dateofbirth: e.target.value })} />
                                        <label className={addData.dateofbirth ? "filled" : ""}>
                                            Date Of Birth
                                        </label>
                                    </div>

                                 

                                    <div className="floating-label">
                                        <input className="form-control" type="text" placeholder='' value={addData.location} onChange={(e) => setAddData({ ...addData, location: e.target.value })} required />
                                        <label className={addData.location ? "filled" : ""}>
                                            Location
                                        </label>
                                    </div>


                                    <div className="floating-label">
                                        <input className="form-control" type="text" placeholder='' value={addData.notes} onChange={(e) => setAddData({ ...addData, notes: e.target.value })} />
                                        <label className={addData.notes ? "filled" : ""}>
                                            Notes
                                        </label>
                                    </div>



                                    <div className="floating-label">
                                        <select className="form-select" value={addData.selectcourse} onChange={(e) => setAddData({ ...addData, selectcourse: e.target.value })} required>
                                            <option value="">Prefered Course</option>
                                            {responseDatas.map((item, index) => (
                                                <option key={index} value={item.course_name}>{item.course_name}</option>
                                            ))}
                                        </select>
                                        <label className={addData.selectcourse ? "filled" : ""}>

                                        </label>
                                    </div>


                                    <div className="floating-label">
                                        <select
                                            className="form-control"
                                            onChange={(e) => setAddData({ ...addData, timings: e.target.value })} required>
                                            <option value="">Batch Timing</option>
                                            <option value="10.00am-11.30am">10.00am - 11.30am</option>
                                            <option value="11.30am-1.00pm">11.30am - 01.00pm</option>
                                            <option value="04.30pm-06.00pm">04.30pm - 06.00pm</option>
                                            <option value="06.00pm-07.30pm">06.00pm - 07.30pm</option>
                                        </select>

                                        <label className={addData.timings ? "filled" : ""}>

                                        </label>
                                    </div>





                                

                                    <div className="floating-label">
                                        <select
                                            className="form-control"
                                            onChange={(e) => setAddData({ ...addData, student_type: e.target.value })} required>
                                            <option value=""> Select Student Type</option>
                                            <option value="Studying">Studying</option>
                                            <option value="Working">Working</option>
                                            <option value="Jobseeking">Jobseeking</option>

                                        </select>
                                        <label className={addData.student_type ? "filled" : ""}>
                                        </label>
                                    </div>



                                    <div className="floating-label">
                                        <select
                                            className="form-control"
                                            value={addData.status}
                                            onChange={handleStatusChange}
                                            required  >
                                            <option value="">Select status</option>
                                            <option value="New">New</option>
                                            <option value="contacted">Contacted</option>
                                            <option value="Followups Scheduled">Followups Scheduled</option>
                                            <option value="Converted">Converted</option>
                                        </select>
                                        <label className={addData.status ? "filled" : ""}>
                                            select status
                                        </label>
                                    </div>


<div className='floating-label'>
    <select className='form-control'>    
    <option value=''></option>
    <option value='Time&date'>Time&date </option>
    <option value='followups'>followup</option>

    </select>
</div>


                                    <div className="floating-label">
                                        {showCallbackDate && ( // Show the callback date field if 'Callback' status is selected
                                            <div className="form-group mt-1">

                                                <input className="form-control" type="date" value={addData.callback_date} onChange={(e) => setAddData({ ...addData, callback_date: e.target.value })} required />
                                            </div>
                                        )}

                                        <label className={addData.callback_date ? "filled" : ""}>
                                        </label>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5 d-flex justify-content-end mb-5">
                        <div className="col-lg-6 text-center d-flex justify-content-start">
                            <button className='Cancelbut' variant="secondary" onClick={handlecancel}>Cancel</button>
                        </div>
                        <div className="col-lg-6 text-center d-flex justify-content-end">
                            <button className='Submitbut' type="submit" variant="primary" onClick={handlesubmit} >Submit</button>
                        </div>
                    </div>
                    {/* </form> */}
                </div>

            </div>


        </div >
    )
}

export default Addstudent
