import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import apiUrl from '../../../environment/Api';
import '../../../assets/css/Profile.css'

function Profile() {
    const { id } = useParams();
    const [responseData, setResponseData] = useState([]);
    const [responseDatas, setResponseDatas] = useState([]);
    const [getEmpId, setGetEmpId] = useState('');
    const [addData, setAddData] = useState({
        empid: '',
        name: '',
        designation: ''
    });

    const [addDatas, setAddDatas] = useState([]);

    useEffect(() => {
        fetchData();
        fetchDatas();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + `/staff`);
            console.log("Response from server:", response.data);
            setResponseData(response.data);

            const selectedEmployee = response.data.find(employee => employee.empid === id);
            console.log("filter", selectedEmployee)
            localStorage.getItem("id",id)
            if (selectedEmployee) {
                setAddData({
                    empid: selectedEmployee.empid,
                    name: selectedEmployee.name,
                    designation: selectedEmployee.designation
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const fetchDatas = async () => {
        try {
            const response = await axios.get(apiUrl + "/dailytask");
            console.log("Response from server:", response.data);
            setResponseDatas(response.data);

            const selectedEmployeeTasks = response.data.filter(task => task.empid === id);
            if (selectedEmployeeTasks.length > 0) {
                setAddDatas(selectedEmployeeTasks);
            }
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    return (
        <>
            <h4> <b>Profile</b></h4>
            <div className="row mt-4  mt-4">
<div className="col-lg-3">
                <div className=" card maincard">
                    <div className="card image mt-4 mb-4">
                        <i class="fa-solid fa-user-tie " id="userimage"></i>
                    </div>
                    <div className="px-2">
                        <p className="staffdetaile">Staff Detaile</p>
                        <p className="detaile">Employee Id : {addData.empid}</p>
                        <p className="detaile">Name : {addData.name}</p>
                        <p className="detaile">Designation : {addData.designation}</p>
                    </div>
                </div>
                </div>
                <div className="col-lg-9 tasktable">
                    <h4  className="Pendingtask">Pending Task</h4>
                    <hr></hr>
                    <div className="card mt-4">
                        {/* Table */}
                        <div className="mt-2">
                            <table className="table table-striped ">
                                <thead>
                                    <tr>
                                        <th className="datas">Project Name</th>
                                        <th className="datas">Task</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addDatas.map((task, index) => (
                                        <tr key={index}>
                                            <td className="datas">{task.projectname}</td>
                                            <td className="datas">{task.task_description}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Profile;
