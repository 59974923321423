import React, { useEffect, useState } from "react";
import axios from "axios";
import apiUrl from "../../environment/Api";
import { useNavigate, useParams } from "react-router-dom";
import '../../assets/css/Addinvoice.css'



const Updateinvoice = () => {
  const { id } = useParams()
  const [projectNames, setProjectNames] = useState([]);
  const [courseNames, setCourseNames] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [dependentOptions, setDependentOptions] = useState([]);
  const [responseDatas, setResponseDatas] = useState([]);

  const [currentDate, setCurrentDate] = useState("");
  const [invoiceInputs, setInvoiceInputs] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [gst, setGst] = useState("");
  const [nettotal, setNetTotal] = useState(0);
  const [paid, setPaid] = useState("");
  const [balance, setBalance] = useState(0);
  const [tableData, setTableData] = useState([



  ]);
  const [dependent, setSelectedDependent] = useState("");
  const [selectedPayType, setSelectedPayType] = useState("");
  const [discount, setDiscount] = useState("");
  const [courseDetails, SetCourseDetails] = useState([]);
  const [paymentarray, setpaymentarray] = useState([]);
  const [responseDatased, setResponseDatased] = useState([]);
  const [selectedbranchid, setselectedbranchid] = useState("");
  const [selectedbranchname, setselectedbranchname] = useState("");
  const [overAllData, setOverAllData] = useState([])

  const navigate = useNavigate();

  const handleNewButtonClick = async () => {
    await handleSavePayment();

    navigate(`/print/${postdata.invoice_no}`)
  };
  useEffect(() => {
    fetchdata()

  }, [])
  const [listTable, setListTable] = useState([]); // Initial state as an empty array
  const [paymentTable, setpaymentTable] = useState([])
  const fetchdata = async () => {
    try {
      const responseoverall = await axios.get(apiUrl + `/invoice_details/${id}`);
      const responselist = await axios.get(apiUrl + `/invoice_list/${id}`);
      const responsePayment = await axios.get(apiUrl + `/paymenthistroy/${id}`);
      setOverAllData(responseoverall.data[0]); // Assuming this is correct
      setListTable(responselist.data); // Assuming responseList.data is the array you want
      setpaymentTable(responsePayment.data); // Assuming responsePayment.data is the array you want
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    const formattedData = listTable.map((item) => ({
      id: item._id,
      branch_id: item.branch_id,
      branch_name: item.branch_name, // Assuming selectedbranch is defined
      description: item.description,
      qty: parseInt(item.qty) || 1, // Ensure it's a number
      price: parseFloat(item.price) || 0, // Ensure it's a number
      total: (parseInt(item.qty) * parseFloat(item.price)).toFixed(2), // Calculate total
    }));

    // Set the table data with the formatted data
    const addPayment = paymentTable.map((item) => ({

      invoice_no: item.invoice_no,
      branch_id: item.branch_id,
      details: item.details,
      date: item.date,
      paid: item.paid,
      paytype: item.paytype,
    }));
    setTableData(formattedData);

    setpaymentarray(addPayment)

    setSubtotal(overAllData.subtotal);
    setGst(overAllData.gst);
    setNetTotal(overAllData.nettotal);
    setPaid(overAllData.paid);
    setBalance(overAllData.balance);




  }, [listTable]); // Add dependencies to ensure effect runs when these values change


  const [selectedbranch, setselectedbranch] = useState({
    branch_id: overAllData.branch_id,
    branch_name: ""
  });


  const handleclose = async () => {
    navigate(`/invoice`)

  }

  console.log(id, "invoiceNo--==")
  console.log(listTable, "listTable--==")
  console.log(paymentTable, "paymentTable--==")


  // const [listData, setListData] = useState({
  //   dependent: "",
  //   qty: "",
  //   price: "",
  //   total: "",
  // });

  const [postdata, setPostdata] = useState({
    invoice_no: "",
    date: "",
    type: "",
    dependent: "",
    name: "",
    email: "",
    mobile_no: "",
  });



  const handleclear = () => {
    setPostdata({
      invoice_no: "",
      date: "",
      type: "",
      dependent: "",
      name: "",
      email: "",
      mobile_no: "",
      branch_id: "",
    });
    setTableData([]);
    setPaid("");
    setSelectedPayType("");
    setDiscount("");
    setGst("");
    setNetTotal(0);
    setSubtotal(0);
    setBalance(0);
    setpaymentarray([]);
  };


  const [addData, setAddData] = useState({
    name: "",
  });

  useEffect(() => {

    // const fetchProjects = async () => {
    //   try {
    //     const response = await axios.get(apiUrl + "/enquiry");
    //     setProjectNames(response.data.map((item) => item.projectname));
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    const fetchCourses = async () => {
      try {
        const response = await axios.get(apiUrl + "/course");
        SetCourseDetails(response.data);
        setCourseNames(response.data.map((item) => item.course_name));
      } catch (error) {
        console.log(error);
      }
    };



    // fetchProjects();
    fetchCourses();
  }, []);

  useEffect(() => {
    if (overAllData.type === "course") {
      setDependentOptions(courseNames);
      console.log("tis was dependent coc");
      console.log("dependent ", courseDetails);
    } else {
      console.log("Te else is printed");
    }


  }, [selectedType, projectNames, courseNames]);

  useEffect(() => {
    fetchDatas();
  }, []);

  const [selectDataEmail, setSelectDataEmail] = useState("");
  const [selectDataName, setSelectDataName] = useState("");
  const [selectDataMobile, setSelectDataMobile] = useState("");
  const [price, setPrice] = useState(0);

  // useEffect(() => {
  //   setSelectDataEmail(overAllData?.email || "");
  //   setSelectDataName(overAllData?.name || "");
  //   setSelectDataMobile(overAllData?.mobile_no || "");
  // }, []);


  const fetchDatas = async () => {
    try {
      const response = await axios.get(apiUrl + "/studingstudent");
      console.log("Response from server type student:", response.data);
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };




  useEffect(() => {
    fetchDatased();
  }, []);



  const fetchDatased = async () => {
    try {
      const response = await axios.get(apiUrl + "/Branch");
      console.log("Response from server brach :", response.data);
      setResponseDatased(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [brachData, selectedBranchs] = useState([])
  // ===============================================================================
  const handleBranchSelection = (e) => {
    console.log(responseDatased, 'responseDatased', e.target.value);

    // Ensure the types of e.target.value and branch_id are the same
    const selectedBranch = responseDatased.find(item => item.branch_id === e.target.value.toString());


    setOverAllData({
      ...overAllData,  // Spread the existing overAllData object
      branch_id: selectedBranch.branch_id,  // Add or update branch_id
      branch_name: selectedBranch?.branch_name  // Add or update branch_name
    });


    // if (selectedBranch) {
    //   console.log("The selected branch is:", selectedBranch);
    //   setselectedbranch({
    //     branch_id: overAllData.branch_id,
    //     branch_name: selectedBranch.branch_name
    //   });
    // } else {
    //   console.log("No matching branch found.");
    // }
  };
  // ===============================================================================




  const handleNameChange = (value, id, fixedParam) => {
    console.log("Selected ID:", id);
    console.log("Fixed Parameter:", fixedParam);

    const studentData = responseDatas.find((student) => student.id === id);

    if (studentData) {
      console.log("Matching student data:", studentData);
      setSelectDataEmail(studentData.email);
      setSelectDataMobile(studentData.mobile_no);

    } else {
      console.log("No matching student found");
    }

    setAddData({ ...addData, name: value });
  };

  const handleChange = (e) => {
    console.log(e.target.value, "")
    const NameDetailes = responseDatas.filter(item => item._id == e.target.value)
    console.log("hellooo", NameDetailes);

    // setSelectDataName(NameDetailes[0]?.leads_id[0]?.student_name);
    // setSelectDataEmail(NameDetailes[0]?.leads_id[0]?.email);
    // setSelectDataMobile(NameDetailes[0]?.leads_id[0]?.mobile_no)
    setOverAllData({
      ...overAllData,
      mobile: NameDetailes[0]?.leads_id[0]?.mobile_no,
      email: NameDetailes[0]?.leads_id[0]?.email,
      dependent: NameDetailes[0]?.leads_id[0]?.Prefered_course,
      studyingstudent: e.target.value,
      fees: NameDetailes[0]?.fees,
      name: NameDetailes[0]?.leads_id[0]?.student_name,
      branch_id: NameDetailes[0]?.branch_details[0]?.branch_id,
      branch_name: NameDetailes[0]?.branch_details[0]?.branch_name

    })


    console.log(NameDetailes, "NameDetailes")


    const selectedIndex = e.target.selectedIndex;
    const selectedOption = e.target.options[selectedIndex];
    const selectedValue = selectedOption.value;
    const selectedId = parseInt(selectedOption.getAttribute("data-id"));
    handleNameChange(selectedValue, selectedId);
  };

  useEffect(() => {
    getInvoice();
  }, []);

  const getInvoice = async () => {
    try {
      const response = await axios.get(apiUrl + "/invoiceno");
      console.log("the response for get invoice ", response.data);
      setPostdata({ ...postdata, invoice_no: response.data[0].invoice_no + 1 });
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  ///////////////////////////////////////////
  useEffect(() => {
    const getCurrentDate = () => {
      const now = new Date();
      const formattedDate = now.toISOString().substr(0, 10);
      setCurrentDate(formattedDate);
    };
    getCurrentDate();
  }, []);
  // /////////////////////
  const handleRowCreate = (e) => {
    e.preventDefault();
    const isRowIncomplete = tableData.some(
      (row) => !row.qty || !row.price || !row.total
    );

    if (isRowIncomplete) {
      alert(
        "Please fill all the fields in the current row before adding a new one."
      );
      return;
    }

    const newId = Date.now();
    console.log("the set table data", tableData)
    // setTableData([
    //   ...tableData,
    //   {
    //     id: newId,
    //     // branch_id:responseDatased[0]._id,
    //     branch_id: selectedbranch.branch_id,
    //     branch_name: selectedbranch.branch_name,
    //     description: dependent,
    //     // description: "branch_id",
    //     qty: 1,
    //     price: price,
    //     total: (1 * price).toFixed(2),
    //   },
    // ]);
    console.log(overAllData,"overAllData--data")
    setTableData([
      {
        id: newId,
        // branch_id:responseDatased[0]._id,
        branch_id: overAllData.branch_id,
        branch_name: overAllData.branch_name,
        description: overAllData.dependent,
        // description: "branch_id",
        qty: 1,
        price: overAllData.fees,
        total: (1 * overAllData.fees).toFixed(2),
      },
    ]);

    setSubtotal((1 * overAllData.fees).toFixed(2));
    setNetTotal((1 * overAllData.fees).toFixed(2));
    setBalance((1 * overAllData.fees).toFixed(2));
    // setTableData([...tableData, { id: newId, description: dependent, qty: 1, price: price, total: (1 * price).toFixed(2) }]);
  };

  const handleInvoiceInputChange = (index, field, value) => {
    const updatedTableData = [...tableData];
    updatedTableData[index][field] = value;

    if (field === "qty" || field === "price") {
      let qty = parseFloat(updatedTableData[index].qty) || 0;
      let price = parseFloat(updatedTableData[index].price) || 0;
      updatedTableData[index].total = (qty * price).toFixed(2);
    }

    setTableData(updatedTableData);
    updateTotals(updatedTableData);
  };

  const updateTotals = (updatedTableData) => {
    const subtotalValue = updatedTableData.reduce((acc, curr) => {
      return acc + parseFloat(curr.total);
    }, 0);
    setSubtotal(subtotalValue.toFixed(2));

    const discountAmount = (subtotalValue * discount) / 100;
    const discountedSubtotal = subtotalValue - discountAmount;
    const gstAmount = isGstEnabled ? (discountedSubtotal * gst) / 100 : 0;
    const netTotalValue = discountedSubtotal + gstAmount;
    setNetTotal(netTotalValue.toFixed(2));
    setBalance((netTotalValue - parseFloat(paid || 0)).toFixed(2));
  };


  console.log("discount before nettotal", nettotal);

  const [isGstEnabled, setIsGstEnabled] = useState(false); // Add state for checkbox

  const handleGstCheckboxChange = (e) => {
    setIsGstEnabled(e.target.checked);
    if (!e.target.checked) {
      setGst("");
      const discountedSubtotal = subtotal - (subtotal * discount) / 100;
      setNetTotal(discountedSubtotal.toFixed(2));
      setBalance((discountedSubtotal - parseFloat(paid || 0)).toFixed(2));
    } else {
      handleGstChange(gst); // Recalculate with the current GST value
    }
  };

  // discount//////

  const handleGstChange = (value) => {
    const gstValue = parseFloat(value) || 0;
    setGst(gstValue);

    const discountedSubtotal = subtotal - (subtotal * discount) / 100;
    const gstAmount = (discountedSubtotal * gstValue) / 100;
    const netTotalValue = discountedSubtotal + gstAmount;
    setNetTotal(netTotalValue.toFixed(2));

    const balanceValue = netTotalValue - parseFloat(paid || 0);
    setBalance(balanceValue.toFixed(2));
  };

  const [paidsValues, setpaidValues] = useState([]);


  const [paymentHistory, setPaymentHistory] = useState([]);

  //////////////************************///////////////////




  ///////////////////******************///////////////////////
  console.log("overallDAtaFinal", overAllData)
  const handleSavePayment = async () => {
    const newPayment = {
      invoice_no: postdata.invoice_no,
      branch_id: responseDatased.branch_id,
      details: dependent,
      date: currentDate,
      paid: paid,
      paytype: selectedPayType,
    };
    const paybalance = paymentarray.reduce((acc, item) => acc + parseFloat(item.paid), 0);

    const formData = {
      branch_id: overAllData.branch_id,
      branch_name: overAllData.branch_name,
      invoice_no: id,
      date: currentDate,
      type: overAllData.type,
      dependent: overAllData.dependent,
      name: overAllData.name,
      email: overAllData.email,
      mobile: overAllData.mobile,
      subtotal: overAllData.subtotal,
      studyingstudent: overAllData.studyingstudent,
      gst: gst,
      discount: discount,
      nettotal: nettotal,
      paytype: selectedPayType,
      paid: paybalance,
      balance: nettotal-paybalance
      ,
    };
    console.log("submit data  nnnnnooow", formData, id);

    const finaltabledata = [...tableData];

    try {
      const response = await axios.put(apiUrl + `/invoice_details/${id}`, formData);
      console.log("the response for get invoice ", response.data);

      if (response) {
        if (response.status === 200) {
          navigate(`/invoice`);
          console.log("SUBMIT TABLE DATA", tableData);
          //
          const formdatalist = finaltabledata.map((item) => ({
            invoice_no: id,
            branch_id: overAllData.branch_id,
            description: item.description,
            qty: item.qty,
            price: item.price,
            total: item.total,
          }));
          console.log(formdatalist, "formdata141414")
          try {
            const response = await axios.post(
              apiUrl + "/invoice_list",
              formdatalist
            );
            console.log("invoice list sub,mit", response.data);
          } catch (error) {
            console.error("Error fetching data:", error.message);
          }

          // /////
          // const paymentHistory = {
          //   branch_id: paymentarray[0].branch_id,
          //   invoice_no: id,
          //   date: "2024-10-05T00:00:00.000Z",
          //   paytype: selectedPayType,
          //   paid: paid,
          //   details: paymentarray[0].details,
          // }

          try {
            const response = await axios.post(
              apiUrl + "/paymenthistroy",
              paymentarray
            );
            console.log("invoice list sub,mit", response.data);
          } catch (error) {
            console.error("Error fetching data:", error.message);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }

    setPaymentHistory([...paymentHistory, newPayment]);

    // Clear the payment inputs
    setPaid("");
    setSelectedPayType("");
  };

  const handlePaidChange = (value) => {
    const paidValue = parseFloat(value) || 0;
   
  
    setPaid(paidValue);

  };
  const [paymentHistoryArray, setPaymentHistoryArray] = useState([]);

  const handleaddpayment = () => {
    const balanceValue = balance - paid;
  
    try {
      // Set the balance first so we always work with the correct value
      setBalance(balanceValue.toFixed(2));
  
      // Check if balance is less than or equal to 0
      if (balanceValue <= 0 && paid !== 0) {
        setPaid(balance);  // Set paid to 0 if balance is less than or equal to 0
        const paybalance = paymentarray.reduce((acc, item) => acc + parseFloat(item.paid), 0);
        setBalance(subtotal-balance-paybalance)
        console.log("The balance is less than or equal to 0, skipping payment insertion.");
        const addPayment = {
          invoice_no: id,
          branch_id: overAllData.branch_id,
          details: paymentarray[0].details,
          date: currentDate,
          paid: balance,
          paytype: selectedPayType,
        };
    
        setpaymentarray((previous) => [...previous, addPayment]);
    
        console.log("The addPayment", addPayment);
        return;  // Exit the function early if the condition is met
      }
  
      // Proceed to add payment if the condition is not satisfied
      const addPayment = {
        invoice_no: id,
        branch_id: overAllData.branch_id,
        details: paymentarray[0].details,
        date: currentDate,
        paid: paid,
        paytype: selectedPayType,
      };
  
      setpaymentarray((previous) => [...previous, addPayment]);
  
      console.log("The addPayment", addPayment);
  
    } catch (error) {
      console.error("Error in handleaddpayment: ", error);
    }
  };
  
  console.log(" the array payment ", paymentarray)

  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1); // Remove the row at the specified index
    setTableData(updatedTableData);

    // Calculate subtotal after removing the row
    const subtotal = updatedTableData.reduce((acc, curr) => {
      return acc + (parseFloat(curr.total) || 0);
    }, 0);
    setSubtotal(subtotal.toFixed(2));

    // Recalculate net total with GST
    const gstAmount = (subtotal * gst) / 100;
    const netTotal = subtotal + gstAmount;
    setNetTotal(netTotal);

    // Recalculate balance
    const balanceValue = netTotal - paid;
    setBalance(balanceValue.toFixed(2));
  };

  // ///////////////////

  const handleDiscountChange = (value) => {
    const discountPercentage = parseFloat(value) || 0;
    setDiscount(discountPercentage);

    // Calculate discount amount
    const discountAmount = (subtotal * discountPercentage) / 100;

    // Calculate discounted subtotal
    let discountedSubtotal;
    if (discountPercentage > 0) {
      discountedSubtotal = subtotal - discountAmount;
    } else {
      discountedSubtotal = subtotal;
    }

    // Calculate GST amount based on the discounted subtotal
    const gstAmount = isGstEnabled ? (subtotal * gst) / 100 : 0;

    // Calculate net total considering GST
    const netTotalValue = discountedSubtotal + gstAmount;

    // Set the new net total
    setNetTotal(netTotalValue.toFixed(2));

    // Calculate balance considering the new net total
    const balanceValue = netTotalValue - parseFloat(paid || 0);
    setBalance(balanceValue.toFixed(2));
  };

  console.log("final net total", nettotal);


 

  const handleInputChange = (e, index) => {
    const updatedPayments = [...paymentarray];
    updatedPayments[index].paid = e.target.value;
    setpaymentarray(updatedPayments); // Assuming setPaymentArray is your state setter
  };


  return (
    <>
      <div className="mainsection">
        <div>
          <h5 className="head" style={{ textDecoration: 'underline' }}>InvoiceUpdate</h5>
        </div>
        <hr></hr>
        {/* invoice no */}
        <div className="card invoice_card">
          <div className="row first">
            <div className="col-lg-3">
              <label>Invoice No:</label>
              <input
                type="text"
                value={id}
                disabled
                // onChange={(e) =>
                //   setPostdata({ ...postdata, invoice_no: e.target.value })
                // }
                className="form-control"
              ></input>
            </div>
            {/* date */}
            <div className="col-lg-6"></div>

            <div className="col-lg-3">
              <label>Date:</label>
              <input
                type="date"
                className="form-control"
                value={currentDate}
                readOnly
              />
            </div>
          </div>
        </div>
        {/* type */}

        {console.log(overAllData, "overAllData_+_+_+")}
        <div className="card invoice_card">
          <div className="row first">

            <div className="col-lg-3">
              <label>Type: </label>
              {/* <label>Type: </label> */}
              <select
                className="form-select"
                value={overAllData.type || ""}
              // onChange={(e) => setSelectedType(e.target.value)}
              >
                <option disabled value="">Select Type</option>
                {/* Uncomment if needed
        <option value="projectname">Project Name</option>
        */}
                <option value="course">Course</option>
              </select>
            </div>
            {/* dependent */}
            {/* <div className="col-lg-3">
            <label>Selec Option</label>
            <select
              value={overAllData.dependent || ""}
              // value= "Full Stack Web Development"
              onChange={(e) => {
                setOverAllData({ ...overAllData, dependent: e.target.value });
              }}
              className="form-select"
            >
              <option value="">Select Option</option>
              {dependentOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div> */}

            <div className="col-lg-3">
              <label>Selec Option:</label>
              <input
                type="tel"
                value={overAllData.dependent}
                className="form-control"
                disabled
              ></input>
            </div>

            {console.log(overAllData.branch_id, "overAllData.branch_id")}
            <div className="col-lg-3">
              {/* <label>aaa</label> */}
              <div className="form-group">
                <label></label>
                <div className="floating-label">
                  {/* <label className="form-label">
                  </label> */}

                  <select
                    className="form-select"
                    value={overAllData.branch_id} // Controlled by state
                    onChange={(e) => handleBranchSelection(e)} // Handles branch selection
                    disabled
                    required
                  >
                    <option value="">Select Branch</option>
                    {responseDatased.map((item, index) => (
                      <option key={index} value={item.branch_id}>
                        {`${item.branch_id} - ${item.branch_name}`}
                      </option>
                    ))}
                  </select>

                  <label className={overAllData.branch_id ? "filled" : ""}>
                    Select Branch
                  </label>
                </div>
                {console.log("Fetched branch data:", responseDatased)}
              </div>
            </div>



          </div>
        </div>
        {/* name */}
        {console.log(overAllData, "popooverAllData.name")}

        <div className="card invoice_card">
          <div className="row first">
            <div className="col-lg-3">
              <label>Name:</label>
              <select
                className="form-select"
                disabled
                value={overAllData.studyingstudent || ""} // Ensure value is correctly handled
                onChange={handleChange} // Handle the selection change
              >
                <option value="">Select Name</option>
                {responseDatas.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.roll_no}-{item.leads_id[0]?.student_name || "Unknown"} {/* Ensure student name is displayed */}
                  </option>
                ))}
              </select>
            </div>


            {/*  email*/}
            <div className="col-lg-3">
              <label>Email:</label>
              <input
                type="text"
                value={overAllData.email}
                className="form-control"
                disabled
              ></input>
            </div>
            {/* mobile */}
            <div className="col-lg-3">
              <label>Mobile:</label>
              <input
                type="tel"
                value={overAllData.mobile}
                className="form-control"
                disabled
              ></input>
            </div>
            {/* add button */}
            <div className="col-lg-1 ">
              <button
                type="button"
                className="btn btn-secondary Add"
                onClick={handleRowCreate}
              >
                Add
              </button>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
        {/* ////////////////////////////////////////////////////////////////////////////// */}
        <div className="card invoice_card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>S.NO</th>
                    {/* <th>branch</th> */}
                    <th>Description</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>Total</th>
                    <th className="d-none">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {tableData.map((data, index) => (
                    <tr key={data.id}>
                      <td>{index + 1}</td>
                      {/* <td>
                        <input
                          type="text"
                          value={data.branch_id}
                          className="form-control"
                        />
                      </td> */}
                      <td>
                        <input
                          type="text"
                          disabled
                          value={data.description}
                          className="form-control"
                        />
                      </td>

                      <td>
                        <input
                          type="number"
                          disabled
                          className="form-control"
                          value={tableData[index]?.qty || ""}
                          onChange={(e) =>
                            handleInvoiceInputChange(index, "qty", e.target.value)
                          }
                        />
                      </td>

                      <td>
                        <input
                          type="number"
                          disabled
                          className="form-control"
                          value={tableData[index]?.price || ""}
                          onChange={(e) =>
                            handleInvoiceInputChange(
                              index,
                              "price",
                              e.target.value
                            )
                          }
                          inputMode="numeric"
                          style={{
                            "-webkit-appearance":
                              "none" /* Chrome, Safari, Edge, Opera */,
                            "-moz-appearance": "textfield" /* Firefox */,
                            appearance: "textfield" /* Standard */,
                            margin: 0 /* Ensure the spinner arrows are not replaced with extra padding */,
                          }}
                          onWheel={(e) => e.preventDefault()} // Prevent the default wheel behavior
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled
                          value={tableData[index]?.total || ""}
                          className="form-control"
                          readOnly
                        />
                      </td>
                      <td className="d-none">

                        <i class="fa-sharp-duotone fa-solid fa-circle-xmark mx-2 fs-2" style={{ color: 'red' }} onClick={() => handleDelete(index)}></i>
                        {/* <button
                          className="btn btn-danger"
                          onClick={() => handleDelete(index)}
                        >
                          Delete
                        </button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <div className="card invoice_card">
          <div className="row">
            <h5>Previous Payment History</h5>

            <div className="col-lg-6">
              <div className="card invoice_card">
                <div className="invoice">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ width: "30px" }}>S.NO</th>
                          <th style={{ width: "400px" }}>Branch</th>
                          <th style={{ width: "400px" }}>Details</th>
                          <th style={{ width: "250px" }}>Date</th>
                          <th style={{ width: "210px" }}>Paid</th>
                          <th style={{ width: "210px" }}>Paytype</th>
                        </tr>
                      </thead>
                      <tbody>
                        {console.log("the payment arrays", paymentarray)}
                        {paymentarray.map((payment, index) => (
                          <tr key={payment.id}>
                            <td>{index + 1}</td>
                            <td>{payment.branch_id}</td>
                            <td>{payment.details}</td>
                            <td>{payment.date}</td>
                            <td style={{ width: "20%" }}>
                              {/* <input
                                type="number"
                                className="form-control "
                                value={payment.paid}
                                onChange={(e) => handleInputChange(e, index)}
                              /> */}
                              {payment.paid}
                            </td>
                            <td>{payment.paytype}</td>
                          </tr>
                        ))}
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* ////////////////////////////////////subtotal to balance////////////////////////////////////// */}
            {/* subtotal */}
            <div className="col-lg-6 ">
              <div className="row">
                <div
                  className="col-lg-5 col-md-5 col-sm-12 "
                  style={{ textAlign: "center" }}
                >
                  <p>Subtotal</p>
                </div>
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <input
                    style={{ width: "80%" }}
                    placeholder="0.00"
                    type="number"
                    value={subtotal}
                    className="form-control"
                  ></input>
                </div>
                {/* gst */}
                <div
                  className="col-lg-5 col-md-5 col-sm-12"
                  style={{ textAlign: "center" }}
                >
                  <p>
                    Gst{" "}
                    <input type="checkbox" onChange={handleGstCheckboxChange} />
                  </p>
                </div>
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <input
                    style={{ width: "80%" }}
                    placeholder="0.00"
                    type="number"
                    value={gst}
                    className="form-control"
                    onChange={(e) => handleGstChange(e.target.value)}
                    disabled={!isGstEnabled} // Disable the input if GST is not enabled
                  />
                </div>
                {/* discount///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <div
                  className="col-lg-5 col-md-5 col-sm-12 "
                  style={{ textAlign: "center" }}
                >
                  <p>Discount</p>
                </div>
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <input
                    style={{ width: "80%" }}
                    placeholder="0.00"
                    type="number"
                    value={discount}
                    className="form-control"
                    onChange={(e) => handleDiscountChange(e.target.value)}
                  ></input>
                </div>
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                {/* net total */}
                <div
                  className="col-lg-5 col-md-5 col-sm-12 "
                  style={{ textAlign: "center" }}
                >
                  <p>Nettotal</p>
                </div>
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <input
                    style={{ width: "80%" }}
                    placeholder="0.00"
                    type="number"
                    value={nettotal}
                    className="form-control"
                  ></input>
                </div>
                {/* paytype */}
                <div
                  className="col-lg-5 col-md-5 col-sm-12 "
                  style={{ textAlign: "center" }}
                >
                  <p>Paytype</p>
                </div>{" "}
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <select
                    className="form-control   paytype"
                    value={selectedPayType}
                    onChange={(e) => setSelectedPayType(e.target.value)}
                  >
                    <option value="">Select Paytype</option>
                    <option value="gpay">GPay</option>
                    <option value="phonepe">PhonePe</option>
                    <option value="cash">Cash</option>
                    <option value="paytm">Paytm</option>

                  </select>


                </div>
                {/* paid */}
                <div
                  className="col-lg-5 col-md-5 col-sm-12 "
                  style={{ textAlign: "center" }}
                >
                  <p>Paid</p>
                </div>
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <input
                    style={{ width: "80%" }}
                    placeholder="0.00"
                    type="number"
                    className="form-control"
                    value={paid}
                    disabled={selectedPayType === ""}
                    onChange={(e) => handlePaidChange(e.target.value)}
                  ></input>
                  <button className="btn btn-primary gap" onClick={handleaddpayment}>Add</button>
                </div>
                {/* balance */}
                <div
                  className="col-lg-5 col-md-5 col-sm-12 "
                  style={{ textAlign: "center" }}
                >
                  <p>Balance</p>
                </div>
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <input
                    style={{ width: "80%" }}
                    placeholder="0.00"
                    type="number"
                    value={balance}
                    className="form-control"
                  ></input>
                </div>
              </div>
              {/* end */}

              <div className="row">
                <div
                  style={{ marginLeft: "75px" }}
                  className="d-flex justify-content-evenly mt-3 buttonstyle "
                >
                  <button
                    type="button"
                    className="btn btn-danger invoice-button"
                    onClick={handleclose}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-info text-white invoice-button"
                    style={{ backgroundColor: "#007BFF" }}
                    onClick={handleclear}
                  >
                    Clear
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary invoice-button"
                    onClick={handleSavePayment}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-success invoice-button"
                    onClick={handleNewButtonClick}
                  >
                    Save&Print
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Updateinvoice;
