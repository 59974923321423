import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import apiUrl from '../../environment/Api';
import { toast } from 'react-toastify';

function Course() {
    const [responseData, setResponseData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isUpdateshowModal, setUpdateShowModal] = useState(false);
    const [showModals, setShowModals] = useState(false);
    const [branch, setbranch] = useState([]);

    const [role_id, setroleid] = useState('');
    const [previllages, setprevillages] = useState({})

    const [addData, setAddData] = useState({


        branch_id: '',
        course_id: '',
        course_name: '',
        description: '',
        discount: '',
        special_offer: '',
        duration: '',
        fees: '',
        status: ''

    });

    const [updateData, setUpdateData] = useState({

        branch_id: '',
        course_id: '',
        course_name: '',
        description: '',
        discount: '',
        special_offer: '',
        duration: '',
        fees: '',
        status: ''


    });

    useEffect(() => {
        fetchData();
        fetchDataed();
        gettoken();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + "/course");
            console.log("Response from server:", response.data);
            setResponseData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };


    const fetchDataed = async () => {
        try {
            const response = await axios.get(apiUrl + "/Branch");
            console.log("Response from server:", response.data);
            setbranch(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const gettoken = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(apiUrl + "/get_detail", { "token": token });
            console.log("token detail from server:", response.data);
     
       
            setroleid(response.data.detail.rolename);
         
            localStorage.setItem('role_id', response.data.detail.rolename);

            const id = response.data.detail.rolename

            if (id) {
                try {
                    const response = await axios.get(apiUrl + `/role_2/${id}`);


                    const modules = response.data.modules;

                    const filtered_access = modules.filter(item => item.name == "Branch")

                   
                    setprevillages(filtered_access[0].actions);

                  

                } catch (error) {
                    console.error("Error fetching data:", error.message);
                }
            }

        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    }





    const handleNewButtonClick = () => {
        if (previllages.add || previllages.all) {
        setShowModal(true);
    }else{
        toast.error("You don't have the permission to add");
    }
}

    const handlesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {

                // branch_id: '',
                // course_name:'',
                // description: '',
                // duration: '',
                // fees: '',
                // status: ''

                branch_id: addData.branch_id,
                course_name: addData.course_name,
                course_id: addData.course_id,
                discount: addData.discount,
                special_offer: addData.special_offer,
                description: addData.description,
                duration: addData.duration,
                fees: addData.fees,
                status: addData.status


            }
            const response = await axios.post(apiUrl + "/course", formData);
            console.log("Updated:", response);
            toast.success("added successfully!");
            setShowModal(false);
            setAddData({



                branch_id: '',
                course_id: '',
                course_name: '',
                description: '',
                discount: '',
                special_offer: '',
                duration: '',
                fees: '',
                status: ''



            });
            fetchData();
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };


    const [updateid, setUpdateId] = useState("");

    const handleUpdate = (_id, data) => {
        if (previllages.edit || previllages.all) {
        setUpdateId(_id)
        console.log(" the data to updata", data)
        setUpdateShowModal(true);
        setUpdateData({

            branch_id: data.branch_id,
            course_id: data.course_id,
            discount: data.discount,
            special_offer: data.special_offer,
            course_name: data.course_name,
            description: data.description,
            duration: data.duration,
            fees: data.fees,
            status: data.status
        })
    }else{
        toast.error("You don't have the permission to update");
    }
}

    const handleupdatesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {
                branch_id: updateData.branch_id,
                course_id: updateData.course_id,
                discount: updateData.discount,
                special_offer: updateData.special_offer,
                course_name: updateData.course_name,
                description: updateData.description,
                duration: updateData.duration,
                fees: updateData.fees,
                status: updateData.status
            }
            console.log("the update submit data", formData)
            const response = await axios.put(apiUrl + `/course/${updateid}`, formData)
            console.log("response for update", response)
            setUpdateShowModal(false)
            toast.success("Update successfully!");
            fetchData();
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    }



    const handleDelete = async () => {
        try {
            await axios.delete(apiUrl + `/course/${getdeleteid}`);
            toast.error("Deleted successfully!");
            setShowModals(false);
            fetchData(); // Refetch data after deletion
        } catch (error) {
            console.error("Error deleting course:", error.message);
        }
    }



    const handleCloseModal = () => {
        setShowModals(false);
    }

    const [getdeleteid, setdeleteid] = useState('')
    const [getdeletename, setdeletename] = useState('')
    const handleOpenModal = (_id, course_name) => {

        if(previllages.all || previllages.delete){
        console.log(" the id", _id)
        console.log(" the coursename", course_name)
        setdeleteid(_id);
        setdeletename(course_name);
        setShowModals(true);
    }else{
        toast.error("You don't have the permission  to add");
    }
    }

    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };




    // the pagination ---------------------------------------------------------------------
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState("");
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Search
    const filtered = currentItems.filter((item) =>
        Object.values(item).some(
            (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (typeof value === "number" && value.toString().includes(searchQuery))
        )
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(responseData.length / itemsPerPage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage === totalPages ? prevPage : prevPage + 1
        );
    };

    const goToPrevPage = () => {
        setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
    };
    // the pagination  end ---------------------------------------------------------------------
    return (
        <div className='top'>

            <div className='row'>
                <div className='col-lg-9 d-flex'>
                    <h5 className='mt-2'>Course </h5>
                    <div className='mx-3'><button className='btn  add' onClick={handleNewButtonClick}>Add </button></div>
                </div>
            </div>


            {/* ------------ search and per page ----------------------------*/}

            <div className="mb-3 mt-3">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                        <label className='form-label text-dark mt-2'>Show : </label>
                        <div>
                            <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='20'>20</option>
                                <option value='50'>50</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-4 col-sm-1"></div>
                    <div className="col-lg-3 col-md-4 col-sm-7">

                        <div className="w-full flex-1">
                            <form>
                                <div className="relative">

                                    <input
                                        className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                                        placeholder="Search ..."
                                        type="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* --------------------------------end------------------------------------------- */}


            {/* Table */}
            <div className='card'>
                <div className="mt-1">
                    <table className="table table-striped">
                        <thead>
                            <tr>

                                <th>S.No</th>
                                <th>Branch</th>
                                <th>Course </th>
                                <th>Discount upto</th>
                                <th>Duration</th>
                                <th>Fees</th>
                                <th>Status</th>
                                <th className='text-center'>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {filtered.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.branch_id.branch_name}</td>
                                    <td>{item.course_name}</td>

                                    <td>{item.discount}</td>


                                    <td>{item.duration}</td>
                                    <td>{item.fees}</td>

                                    {/* <td  ><p className={item.status === 'Active' ? 'active-row' : 'inactive-row'}>{item.status}</p></td>
                                     */}

                                    {(item.status == "Active") ?
                                        <td className='text-center hideres '>
                                            <p className=' text-white green-label' >{item.status}</p>
                                        </td>
                                        : <td className='text-center hideres'  ><p className='text-white red-label'>{item.status}</p></td>
                                    }


                                    <td className='text-center'>
                                        <button className='btn updelete mx-2' onClick={() => handleUpdate(item._id, item)}><i class="fa-solid fa-pen-to-square"></i></button>
                                        <button className='btn delete'
                                            onClick={() => handleOpenModal(item._id, item.course_name)}
                                        ><i class="fa-solid fa-trash-can"></i></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>



            {/* Modal for adding a new user */}

            <Modal show={showModal} onHide={() => setShowModal(false)} className='custom-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Course</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className="form-group">


                                <div className="floating-label">
                                    <label className="form-label">
                                        <b></b>
                                    </label>
                                    <select
                                        className="form-select"
                                        value={addData.branch_id}
                                        onChange={(e) => setAddData({ ...addData, branch_id: e.target.value })}
                                        required
                                    >
                                        <option value="">Select Branch </option>
                                        {branch.map((item, index) => (
                                            <option key={index} value={item._id}>
                                                {item.branch_name}
                                            </option>
                                        ))}
                                    </select>
                                    <label className={addData.branch_id ? "filled" : ""}>

                                    </label>
                                </div>



                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={addData.course_name} onChange={(e) => setAddData({ ...addData, course_name: e.target.value })} required />
                                    <label className={addData.course_name ? "filled" : ""}>
                                        Course Name
                                    </label>
                                </div>


                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={addData.course_id} onChange={(e) => setAddData({ ...addData, course_id: e.target.value })} required />
                                    <label className={addData.course_id ? "filled" : ""}>
                                        Course Id
                                    </label>
                                </div>


                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={addData.discount} onChange={(e) => setAddData({ ...addData, discount: e.target.value })} required />
                                    <label className={addData.discount ? "filled" : ""}>
                                        Discount upto
                                    </label>
                                </div>


                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={addData.description} onChange={(e) => setAddData({ ...addData, description: e.target.value })} required />
                                    <label className={addData.description ? "filled" : ""}>
                                        Description
                                    </label>
                                </div>


                            </div>
                        </div>


                        <div className='col-lg-6'>

                            <div className="form-group">


                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={addData.special_offer} onChange={(e) => setAddData({ ...addData, special_offer: e.target.value })} required />
                                    <label className={addData.special_offer ? "filled" : ""}>
                                        special_offer
                                    </label>
                                </div>



                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={addData.duration} onChange={(e) => setAddData({ ...addData, duration: e.target.value })} required />
                                    <label className={addData.duration ? "filled" : ""}>
                                        Duration
                                    </label>
                                </div>


                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={addData.fees} onChange={(e) => setAddData({ ...addData, fees: e.target.value })} required />
                                    <label className={addData.fees ? "filled" : ""}>
                                        Fees
                                    </label>
                                </div>


                                <div className="floating-label">
                                    <select className="form-control" value={addData.status} placeholder='' onChange={(e) => setAddData({ ...addData, status: e.target.value })}>
                                        {/* <option value="">Select status</option> */}
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>

                                    <label className={updateData.status ? "filled" : ""}>
                                        Select status
                                    </label>
                                </div>


                            </div>

                        </div>
                    </div>


                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handlesubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>



            {/* ----------------update */}


            <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)} className='custom-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Update Course</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className="form-group">


                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={updateData.branch_id.branch_name} onChange={(e) => setUpdateData({ ...updateData, branch_id: e.target.value })} required />
                                    <label className={updateData.branch_id.branch_name ? "filled" : ""}>
                                        Branch
                                    </label>
                                </div>



                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={updateData.course_name} onChange={(e) => setUpdateData({ ...updateData, course_name: e.target.value })} required />
                                    <label className={updateData.course_name ? "filled" : ""}>
                                        Course Name
                                    </label>
                                </div>





                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={updateData.course_id} onChange={(e) => setUpdateData({ ...updateData, course_id: e.target.value })} required />
                                    <label className={updateData.course_id ? "filled" : ""}>
                                        Course Id
                                    </label>
                                </div>


                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={updateData.discount} onChange={(e) => setUpdateData({ ...updateData, discount: e.target.value })} required />
                                    <label className={updateData.discount ? "filled" : ""}>
                                        Discount upto
                                    </label>
                                </div>



                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={updateData.description} onChange={(e) => setUpdateData({ ...updateData, description: e.target.value })} required />
                                    <label className={updateData.description ? "filled" : ""}>
                                        Description
                                    </label>
                                </div>




                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className="form-group">



                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={updateData.special_offer} onChange={(e) => setUpdateData({ ...updateData, special_offer: e.target.value })} required />
                                    <label className={updateData.special_offer ? "filled" : ""}>
                                        Special offer
                                    </label>
                                </div>

                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={updateData.duration} onChange={(e) => setUpdateData({ ...updateData, duration: e.target.value })} required />
                                    <label className={updateData.duration ? "filled" : ""}>
                                        Duration
                                    </label>
                                </div>

                                <div className="floating-label">
                                    <input className="form-control" type="text" placeholder='' value={updateData.fees} onChange={(e) => setUpdateData({ ...updateData, fees: e.target.value })} required />
                                    <label className={updateData.fees ? "filled" : ""}>
                                        Fees
                                    </label>
                                </div>


                                <div className="floating-label">
                                    <select className="form-control" value={updateData.status} placeholder='' onChange={(e) => setUpdateData({ ...updateData, status: e.target.value })}>
                                        <option value="">Select status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                    <label className={updateData.status ? "filled" : ""}>
                                        Select status
                                    </label>
                                </div>


                            </div>
                        </div>
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handleupdatesubmit}>Update</Button>
                </Modal.Footer>
            </Modal>


            {/* ----------------delete */}

            <Modal className="modal" show={showModals} onHide={handleCloseModal}>
                <Modal.Header>
                    <p>Are you sure you want to delete?</p>
                </Modal.Header>
                <Modal.Body>
                    <label className="form-label d-flex">
                        <h5 className='mx-1'> Course Name :</h5> <h5>{getdeletename} </h5>
                    </label>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
                </Modal.Footer>
            </Modal>


            {/* _------------------------------pagination button -------------------------------- */}
            <div className="mt-3 pb-5">
                <div className="row">
                    <div className="col-lg-10 col-sm-12">
                        <nav className="d-flex float-right">
                            <ul className="pagination justify-content-center">
                                <li
                                    className={` page-item ${currentPage === 1 ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link" onClick={goToPrevPage}>
                                        Previous
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <li
                                        key={i}
                                        className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                                            }`}
                                    >
                                        <button
                                            className="page-link "
                                            onClick={() => paginate(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link " onClick={goToNextPage}>
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>{" "}
                    </div>
                    <div className="col-lg-2 col-sm-12 mt-1">
                        <div className="float-end">
                            <span className="text-dark ">
                                Page {currentPage} of {totalPages}
                            </span>
                        </div>
                    </div>
                </div>
            </div>


            {/* ---------------------------------------end ---------------------------------- */}
        </div>
    )
}

export default Course;