import React, { useState } from "react";
import axios from "axios";
import "../../assets/css/Login.css";
import apiUrl from '../../environment/Api';
import { useNavigate } from "react-router-dom";

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate()

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(apiUrl + "/loginadmin", {
                username,
                password
            });

            if (response) {
                if (response.status === 200) {
                    console.log("Login successful:", response);
                    console.log("THE TOKEN IS ", response.data.token)
                    localStorage.setItem("token", response.data.token)
                    setUsername("");
                    setPassword("");
                    setErrorMessage("");
                    navigate("/dashboard")

                }



            } else {
                console.log(" it not logined")
            }




        } catch (error) {
            console.error("Login failed:", error.response.data);
            setErrorMessage(error.response.data.message || "Invalid Username (or) Password.");
        }
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>

            <div className="row over fullimg">
                
                <div className="col-lg-12 col-md-12 col-sm-12 login_container">
                    <div className="card formcard ">
                        <div className="icon_container">
                            <h3 className="text-center mt-3"><img src={require("../../assets/img/scimitar_logo_slogan-removebg-preview.png")} alt="Company Logo" style={{ height: "100px" }}></img></h3>
                        </div>
                        <form className="m-4" onSubmit={handleFormSubmit}>
                            <div className="mb-2">
                                <label className="form-label login_txt">User Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label login_txt">Password</label>
                                <div className="input-group">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className="form-control"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                    <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? <i className="fa-regular fa-eye-slash"></i> : <i className="fa-regular fa-eye"></i>}
                                    </button>
                                </div>
                            </div>
                            <div className="mb-2 mt-2 text-center">
                                <button className="btn btn-dark sub_btn" type="submit">Login</button>
                            </div>
                            {errorMessage && <p className="text-danger text-center mt-4">{errorMessage}</p>}
                        </form>
                    </div>
                </div>



                {/* <div className="col-lg-6">
                    <div className="imgpic">
                    </div>
                </div>
                <div className="col-lg-6">

                    <div className="loginmain">
                        <div>
                            <div>
                                <h3 className="text-center mt-3"><img src={require("../../assets/img/scimitar_logo_slogan-removebg-preview.png")} alt="Company Logo" style={{ height:"100px" }}></img></h3>
                            </div>
                            <div className="card formcard ">
                                <div>
                                    <h3 className="text-center mt-3"><img src={require("../../assets/img/scimitar_logo_slogan-removebg-preview.png")} alt="Company Logo" style={{ height: "100px" }}></img></h3>
                                </div>
                                <form className="m-4" onSubmit={handleFormSubmit}>
                                    <div className="mb-2">
                                        <label className="form-label login_txt">User Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label login_txt">Password</label>
                                        <div className="input-group">
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                className="form-control"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <button
                                                className="btn btn-outline-secondary"
                                                type="button"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {showPassword ? <i className="fa-regular fa-eye-slash"></i> : <i className="fa-regular fa-eye"></i>}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mb-2 mt-2 text-center">
                                        <button className="btn btn-dark " type="submit">Submit</button>
                                    </div>
                                    {errorMessage && <p className="text-danger text-center mt-4">{errorMessage}</p>}
                                </form>
                            </div>
                        </div>
                    </div>

                </div> */}


            </div>
        </>
    );
}

export default Login;
