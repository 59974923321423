import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import apiUrl from '../../environment/Api';



export default function ProtectedRoute({ children }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const fetchProtectedData = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setIsAuthenticated(false);
                setIsLoading(false);
                return;
            }
            try {
                const response = await axios.post(apiUrl + '/protectedroute', null, {
                    headers: {
                        'x-access-token': token
                    }
                });

                console.log("token verify",response.data)

                if (response.data.status === 'success') {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                    localStorage.removeItem('token');
                }
                
            } catch (error) {
                setIsAuthenticated(false);
                localStorage.removeItem('token');
            } finally {
                setIsLoading(false);
            }
        };


        fetchProtectedData();
    }, []);

    if (isLoading) {
        return <div>Loading....</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to={'/stafflogin'} replace />;
    }

    return (
        <>
            {children}
        </>
    );
}
