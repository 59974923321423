import React from "react";
import "../../assets/css/Footer.css"; // Import the CSS file

function Footer() {

    const year = new Date();



    return (
        <footer className="footers">
            <div className="container-fuild">
                <div className="row d-flex justify-content-between">
                {/* <div className="col-lg-1"></div> */}
                    <div className="col-lg-4">
                        <p className="copy " style={{textAlign:'start' ,fontSize:"12px"}}>Copyright &copy; ATS ERP {year.getFullYear()}</p>
                    </div>
                   <div className="col-lg-4"></div>
                    <div className="col-lg-4 copy2" style={{textAlign:"end"}}>
                        <p style={{fontSize:'12px'}}>Developed By Aalan Tech Soft</p>
                    </div>
                    {/* <div className="col-lg-1"></div> */}
               
                </div>
            </div>
        </footer>
    );
}

export default Footer;
