import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../../../assets/css/Navbar.css'
// import navlogo from '../../assets/img/Admin/vas-logo1-nav2.png';

function Navbarstaff({ toggle }) {
    const navigate = useNavigate()
    const navigatehandle = () => {
        navigate('/profile/:id');
    }
    const navigatehandleinvoice = () => {
        navigate('/invoice');
    }
    const haldlenavigatedashboard = () => {
        navigate('/dashboard');
    }
    return (
        <nav className="navbar default-layout-navbar col-lg-12 p-0 fixed-top d-flex flex-row">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                <div className="navbar-brand brand-logo ">
                        {/* <img onClick={haldlenavigatedashboard} src={navlogo} alt="logo" /> */}
                        <div className='logonav'>
                        <p className='navbarroutertag' onClick={navigatehandle}>ATS ERP</p>
                        </div>
          
                </div>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-stretch">
                
                <div className='mx-5 responsive-none'>
                    {/* <p className='navbarroutertag' onClick={navigatehandle}>Projects</p> */}
                </div>
                <div className='responsive-none'>
                    {/* <p className='navbarroutertag' onClick={navigatehandleinvoice}>
                       Invoice
                    </p> */}
                </div>
                <ul className="navbar-nav navbar-nav-right responsive-none">
                    <li className="nav-item">
                        <i className="fa-regular fa-circle-user usericon mx-2"></i>
                        <b className='admin-username-nav'>admin</b>
                    </li>
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggle}>
                    <i className="fa-solid fa-bars"></i>
                </button>
            </div>
        </nav>
    );
}

export default Navbarstaff;
