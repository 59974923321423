import React, { useEffect, useState } from "react";

import "../../assets/css/Studentinfo.css";
import axios from "axios"
import apiUrl from "../../environment/Api";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

function Leadhistroy() {

  const { _id } = useParams();
  const [lead, setlead] = useState([]);
  const [studentss, setstudent] = useState([]);
  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };

  // function sample(a,b){

  //   const c= a+b;
  //   console.log(c)
  // }


  // sample (1,2)

  useEffect(() => {
    fetchDataed();
  }, []);



  const fetchDataed = async () => {
    try {
      const response = await axios.get(apiUrl + `/leadhistroys/${_id}`);
      const responsed = await axios.get(apiUrl + `/leads/${_id}`);
      console.log("Response from server   histropy 45435435:", responsed.data);
      console.log("Response from server  45435435:", response.data);
      setlead(response.data);
      setstudent(responsed.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };


  const handleDelete = async (_id) => {
    try {
      const response = await axios.delete(`${apiUrl}/leadhistroy/${_id}`);
      fetchDataed();

      console.log("Response from deleteeee:", _id);
    } catch (error) {
      console.error("Error deleting invoice:", error.message);
    }
  };

  function formatDatefitching(isoDate) {
    if (!isoDate) return ''; // Return an empty string if no date is provided
    const date = new Date(isoDate);
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  return (
    <div className="marginup ">
      <div className="row">
        <div className="col-lg-11">
          <Link to="/dashboard" className="text-secondary dashBoard_text">
            <b>Dashboard / </b>
          </Link>
          <Link to="/student" className="text-secondary dashBoard_text">
            <b>Leads / </b>
          </Link>
          <Link to="" className="text-danger dashBoard_text">
            <b>Histroy</b>
          </Link>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-lg-3">
          <h5>
            <b>Student Profile</b>
          </h5>
        </div>
        <div className="col-lg-4"></div>
        <div className="col-lg-5 ">
          <div className="row">
            {/* <div className="col-lg-3">
              <div
                className={`card cardeds ${
                  activeTab === "profile" ? "bg-success" : "bg-danger"
                }`}
                onClick={() => handleTabClick("profile")}
              >
                <div className="">
                  <button className="btn text-white timeline">
                    <i className="fa-solid fa-user mx-1"></i> Profile
                  </button>
                </div>
              </div>
            </div> */}

            {/* <div className="col-lg-5">
              <div
                className={`card cardeds ${
                  activeTab === "fees" ? "bg-success" : "bg-secondary"
                }`}
                onClick={() => handleTabClick("fees")}
              >
                <div className="">
                  <button className="btn text-white timeline">
                    <i className="fa-solid fa-indian-rupee-sign mx-1"></i> Fees
                    Details
                  </button>
                </div>
              </div>
            </div> */}
            {/* 
            <div className="col-lg-4">
              <div
                className={`card cardeds ${
                  activeTab === "timeline" ? "bg-success" : "bg-secondary"
                }`}
                onClick={() => handleTabClick("timeline")}
              >
                <div className="">
                  <button className="btn text-white timeline">
                    <i className="fa-solid fa-hourglass-start mx-1"></i>{" "}
                    Timeline
                  </button>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-lg-3 col-sm-3 col-md-3">
          <div className="card carddetails p-2">
            <div className="imgdetails">
              <img
                className="profileimg"
                src={require("../../assets/img/icon.jpg")}
                alt="Profile"
              />
            </div>
            <div className="text-center mt-2 text-white">
              <h6 className="profile-detailsed ">
                <b>{studentss[0]?.student_name}</b>
              </h6>
              <h6 className="profile-detailsed ">{studentss[0]?.mobile_no}</h6>
              <h6 className="profile-detailsed ">{studentss[0]?.Prefered_course}</h6>
              <h6 className="profile-detailsed ">{studentss[0]?.Source_type}</h6>
              <h6 className="profile-detailsed ">{formatDatefitching(studentss[0]?.Enquiry_date)}</h6>
              <h6 className="profile-detailsed "> No.of.Attempt : {lead.length}</h6>
            </div>
          </div>
        </div>

        <div className="col-lg-9 col-sm-9 col-md-9"  >

          <div className="row cardborder ">
            <div className="card cardborder p-1">
              <div className="row p-2">
                <div className="col-lg-6">
                  <b>Histroy</b>
                </div>
              </div>
            </div>
            <div className="card cardborder">
              <table className="table table-striped responsive">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date</th>
                    <th>Contact Type</th>
                    {/* <th>Source Type</th> */}

                    <th>Staff</th>
                    <th>Notes</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {lead.map((item, index) => (

                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{formatDatefitching(item.Enquiry_date)}</td>
                      <td>{item.contact_type}</td>
                      {/* <td>{item.Source_type}</td> */}
                      <td>{item.assign_staff_detail.length > 0 ? item.assign_staff_detail[0].name : "following staff"}</td>
                      <td>{item.notes}</td>
                      <td>{item.status}</td>
                      <td className='text-center'>
                        <button
                          className='btn delete change'
                          onClick={() => handleDelete(item._id)} // Correctly using an arrow function here
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      </td>
                    </tr>))}

                </tbody>
              </table>
            </div>
          </div>
        </div>





      </div>
    </div>
  );
}

export default Leadhistroy;
