import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import apiUrl from '../../../environment/Api';
import { toast } from 'react-toastify';
function Task() {

    const [responseData, setResponseData] = useState([]);
    const [responseDatas, setResponseDatas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isUpdateshowModal, setUpdateShowModal] = useState(false);
    const [showModals, setShowModals] = useState(false);
const [empid,setEmpid]=useState("")


    const [addData, setAddData] = useState({

        empid:localStorage.getItem("id"),
        projectname: '',
        task_description: '',
        notes: '',
        status:'pending',
        startdate: '',
        enddate: ''


    });

    const [updateData, setUpdateData] = useState({
        empid:localStorage.getItem("id"),
        projectname: '',
        task_description: '',
        notes: '',
        status: '',
        startdate: '',
        enddate: ''

    });



    useEffect(() => {
        fetchData();
        
        setEmpid(localStorage.getItem("id"))
    }, []);

    
    const fetchData = async () => {
        try {
            const localid = localStorage.getItem("id")
            console.log("localid",localid)
            const response = await axios.get(apiUrl + `/dailytask/${localid}`);
            console.log("Response from server:", response.data);
            setResponseData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };


    useEffect(() => {
        fetchDatas();
    }, []);

    const fetchDatas = async () => {
        try {
            const response = await axios.get(apiUrl + "/staff");
            console.log("Response from server:", response.data);
            setResponseDatas(response.data);
            
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };




    useEffect(() => {
        if (addData.status === 'Ongoing') {
            const currentDate = new Date().toISOString().split('T')[0];
            setAddData(prevData => ({ ...prevData, startdate: currentDate }));
        } else if (addData.status === 'Completed') {
            const currentDate = new Date().toISOString().split('T')[0];
            setAddData(prevData => ({ ...prevData, enddate: currentDate }));
        }
    }, [addData.status]);

    useEffect(() => {
        if (updateData.status === 'Ongoing') {
            const currentDate = new Date().toISOString().split('T')[0];
            setUpdateData(prevData => ({ ...prevData, startdate: currentDate }));
        } else if (updateData.status === 'Completed') {
            const currentDate = new Date().toISOString().split('T')[0];
            setUpdateData(prevData => ({ ...prevData, enddate: currentDate }));
        }
    }, [updateData.status]);
    const handleNewButtonClick = () => {
        setShowModal(true);
    };


    const handlesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {
                empid: addData.empid,
                projectname: addData.projectname,
                task_description: addData.task_description,
                notes: addData.notes,
                status: addData.status,
                startdate: addData.startdate,
                enddate: addData.enddate





            }
            const response = await axios.post(apiUrl + "/dailytask", formData);
            console.log("Updated:", response);
            setShowModal(false);
            toast.success("added successfully!");
            setAddData({
                empid:'',
                projectname: '',
                task_description: '',
                notes: '',
                status:'',
                startdate: '',
                enddate: ''
        
            });
            fetchData();
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };




    const [updateid, setUpdateId] = useState("");

    const handleUpdate = (id, data) => {
        setUpdateId(id)
        console.log(" the data to updata", data)
        setUpdateShowModal(true);
        setUpdateData({

            empid: data.empid,
            projectname: data.projectname,
            task_description: data.task_description,
            notes: data.notes,
            status: data.status,
            startdate: data.startdate,
            enddate: data.enddate

        })
    }



    const handleupdatesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {
                empid: updateData.empid,
                projectname: updateData.projectname,
                task_description: updateData.task_description,
                notes: updateData.notes,
                status: updateData.status,
                startdate: updateData.startdate,
                enddate: updateData.enddate,
            }

            console.log('the update form data is : ', formData);
            console.log("the update submit data", formData)
            const response = await axios.put(apiUrl + `/dailytask/${updateid}`, formData)
            console.log("response for update", response)
            setUpdateShowModal(false)
            toast.success("update successfully!");
            fetchData();
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    }



    const handleCloseModal = () => {
        setShowModals(false);
    }
    const [getdeleteid , setdeleteid] = useState('')
    const [getdeletename , setdeletename] = useState('')
    const handleOpenModal =(id, projectname )=>{
        console.log(" the id", id)
        console.log(" the name", projectname)
        setdeleteid(id);
        setdeletename(projectname);
        setShowModals(true);
    }
    const handleDelete = async (id) => {
        try {

            const response = await axios.delete(apiUrl + `/dailytask/${getdeleteid}`)
            console.log("delete data ", response)
            toast.success("Deleted successfully!");
            setShowModals(false);
            fetchData();
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    };





    
     // the pagination ---------------------------------------------------------------------
     const [currentPage, setCurrentPage] = useState(1);
     const [itemsPerPage, setItemsPerPage] = useState(5);
     const [searchQuery, setSearchQuery] = useState("");
     // Pagination
     const indexOfLastItem = currentPage * itemsPerPage;
     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
     const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);
 
     const paginate = (pageNumber) => setCurrentPage(pageNumber);
 
     // Search
     const filtered = currentItems.filter((item) =>
         Object.values(item).some(
             (value) =>
                 typeof value === "string" &&
                 value.toLowerCase().includes(searchQuery.toLowerCase()) ||
                 (typeof value === "number" && value.toString().includes(searchQuery))
         )
     );
 
     const handleSearchChange = (event) => {
         setSearchQuery(event.target.value);
         setCurrentPage(1);
     };
 
     const totalPages = Math.ceil(responseData.length / itemsPerPage);
 
     const goToNextPage = () => {
         setCurrentPage((prevPage) =>
             prevPage === totalPages ? prevPage : prevPage + 1
         );
     };
 
     const goToPrevPage = () => {
         setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
     };


     // the pagination  end ---------------------------------------------------------------------
    return (
        <>

            <div className='d-flex'>
                <h5 className='mt-2'>Daily Task </h5>
                <div className='mx-3'><button className='btn btn-success add' onClick={handleNewButtonClick}>Add <i class="fa-solid fa-plus"></i></button></div>
            </div>



 {/* ------------ search and per page ----------------------------*/}

 <div className="mb-3 mt-3">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                        <label className='form-label text-dark mt-2'>Show : </label>
                      <div>
                      <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
                            <option value='5'>5</option>
                            <option value='10'>10</option>
                            <option value='20'>20</option>
                            <option value='50'>50</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-4 col-sm-1"></div>
                    <div className="col-lg-3 col-md-4 col-sm-7">
                      
                        <div className="w-full flex-1">
                            <form>
                                <div className="relative">
                                
                                    <input
                                        className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                                        placeholder="Search ..."
                                        type="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* --------------------------------end------------------------------------------- */}



            <div className="mt-2">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>S.no</th>
                            <th>Projectname</th>
                            <th>Task Description</th>
                            <th>Notes</th>
                            <th>Status</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th className='text-center'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtered.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>

                                <td>{item.projectname}</td>
                                <td>{item.task_description}</td>
                                <td>{item.notes}</td>
                                <td>
                                    <p className={
                                        item.status === 'Completed' ? 'completed-row' :
                                            (item.status === 'Ongoing' ? 'ongoing-row' :
                                                (item.status === 'Pending' ? 'pending-row' : 'default-row'))
                                    }>
                                        {item.status}
                                    </p>
                                </td>
                                <td>{item.startdate}</td>
                                <td>{item.enddate}</td>
                                <td className='text-center'>
                                    <button className='btn btn-primary mx-3' onClick={() => handleUpdate(item.id, item)}><i class="fa-solid fa-pen-to-square"></i></button>
                                    <button className='btn btn-danger' onClick={() =>  handleOpenModal(item.id, item.projectname) }><i class="fa-solid fa-trash-can"></i></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>


            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Other input fields */}
                

                    <label className="form-label pt-2">
                        <b>Project Name :</b>
                    </label>
                    <input className="form-control" type="text" value={addData.projectname} onChange={(e) => setAddData({ ...addData, projectname: e.target.value })} required />

                    <label className="form-label pt-2">
                        <b>Task Description :</b>
                    </label>
                    <input className="form-control" type="text" value={addData.task_description} onChange={(e) => setAddData({ ...addData, task_description: e.target.value })} required />

                    <label className="form-label pt-2">
                        <b>Notes :</b>
                    </label>
                    <input className="form-control" type="text" value={addData.notes} onChange={(e) => setAddData({ ...addData, notes: e.target.value })} required />

                    <label className="form-label pt-2">
                        <b>Status :</b>
                    </label>

                    <select className="form-control" value={addData.status} onChange={(e) => setAddData({ ...addData, status: e.target.value })}>
                    <option value="">Select Status</option>
                        <option value="Completed">Completed</option>
                        <option value="Ongoing">Ongoing</option>
                        <option value="Pending">Pending</option>
                    </select>

                    <label className="form-label pt-2">
                        <b>Start Date :</b>
                    </label>
                    <input className="form-control" type="date" value={addData.startdate} onChange={(e) => setAddData({ ...addData, startdate: e.target.value })} required />


                    <label className="form-label pt-2">
                        <b>End Date :</b>
                    </label>
                    <input className="form-control" type="date" value={addData.enddate} onChange={(e) => setAddData({ ...addData, enddate: e.target.value })} required />

                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handlesubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>




            <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* Other input fields */}
                   
                    <label className="form-label pt-2">
                        <b>Project Name :</b>
                    </label>
                    <input className="form-control" type="text" value={updateData.projectname} onChange={(e) => setUpdateData({ ...updateData, projectname: e.target.value })} required />
                    <label className="form-label pt-2">
                        <b>Task Description :</b>
                    </label>
                    <input className="form-control" type="text" value={updateData.task_description} onChange={(e) => setUpdateData({ ...updateData, task_description: e.target.value })} required />
                    <label className="form-label pt-2">
                        <b>Notes :</b>
                    </label>
                    <input className="form-control" type="text" value={updateData.notes} onChange={(e) => setUpdateData({ ...updateData, notes: e.target.value })} required />

                    <label className="form-label pt-2">
                        <b>Status :</b>
                    </label>
                    <select className="form-control" value={updateData.status} onChange={(e) => setUpdateData({ ...updateData, status: e.target.value })}>
                        <option value="Completed">Completed</option>
                        <option value="Ongoing">Ongoing</option>
                        <option value="Pending">Pending</option>
                    </select>


                    <label className="form-label pt-2">
                        <b>Start Date :</b>
                    </label>
                    <input className="form-control" type='date' value={updateData.startdate} onChange={(e) => setUpdateData({ ...updateData, startdate: e.target.value })} required />

                    <label className="form-label pt-2">
                        <b>End Date :</b>
                    </label>
                    <input className="form-control" type='date' value={updateData.enddate} onChange={(e) => setUpdateData({ ...updateData, enddate: e.target.value })} required />


                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handleupdatesubmit}>Update</Button>
                </Modal.Footer>
            </Modal>



{/* ----------------delete */}

<Modal className="modal" show={showModals} onHide={handleCloseModal}>
                <Modal.Header>
                    <p>Are you sure you want to delete?</p>
                </Modal.Header>
                <Modal.Body>
                <label className="form-label d-flex">
                <h5 className='mx-1'>Project Name :</h5> <h5>{getdeletename} </h5>
                    </label>
                   
                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
                </Modal.Footer>
            </Modal>




{/* _------------------------------pagination button -------------------------------- */}
<div className="mt-3 pb-5">
                <div className="row">
                    <div className="col-lg-10 col-sm-12">
                        <nav className="d-flex float-right">
                            <ul className="pagination justify-content-center">
                                <li
                                    className={` page-item ${currentPage === 1 ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link" onClick={goToPrevPage}>
                                        Previous
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <li
                                        key={i}
                                        className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                                            }`}
                                    >
                                        <button
                                            className="page-link "
                                            onClick={() => paginate(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link " onClick={goToNextPage}>
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>{" "}
                    </div>
                    <div className="col-lg-2 col-sm-12 mt-1">
                        <div className="float-end">
                            <span className="text-dark ">
                                Page {currentPage} of {totalPages}
                            </span>
                        </div>
                    </div>
                </div>
            </div>


            {/* ---------------------------------------end ---------------------------------- */}

        </>
    );
}


export default Task;