import axios from 'axios';
import React, { useEffect, useState } from 'react';
import apiUrl from '../../environment/Api';
import { useNavigate, useParams } from 'react-router-dom';
import '../../assets/css/Addrole.css'




const modules = [
    {
        name: "Dashboard",
        id: 1,
        actions: {
            all: false,
            add: false,
            edit: false,
            delete: false,
            view: false,
            download: false
        }
    },
    {
        name: "Roles",
        id: 2,
        actions: {
            all: false,
            add: false,
            edit: false,
            delete: false,
            view: false,
            download: false
        }
    },
    {
        name: "Users",
        id: 3,
        actions: {
            all: false,
            add: false,
            edit: false,
            delete: false,
            view: false,
            download: false
        }
    },
    {
        // name: "Student Enquiry",
        name: "Course",
        id: 4,
        actions: {
            all: false,
            add: false,
            edit: false,
            delete: false,
            view: false,
            download: false
        }
    },
    {
        name: "Staff",
        id: 5,
        actions: {
            all: false,
            add: false,
            edit: false,
            delete: false,
            view: false,
            download: false
        }
    },

    {
        name: "Branch",
        id: 6,
        actions: {
            all: false,
            add: false,
            edit: false,
            delete: false,
            view: false,
            download: false
        }
    },
    {
        name: "Asset",
        id: 7,
        actions: {
            all: false,
            add: false,
            edit: false,
            delete: false,
            view: false,
            download: false
        }
    },
    {
        name: "Expense",
        id: 8,
        actions: {
            all: false,
            add: false,
            edit: false,
            delete: false,
            view: false,
            download: false
        }
    },
    {
        name: "Income",
        id: 9,
        actions: {
            all: false,
            add: false,
            edit: false,
            delete: false,
            view: false,
            download: false
        }
    },
    {
        name: "Invoice",
        id: 10,
        actions: {
            all: false,
            add: false,
            edit: false,
            delete: false,
            view: false,
            download: false
        }
    },


];




function Editrole() {
    const [rolename, setRolename] = useState('')


    const { id } = useParams();
    console.log("params id", id)

    const [moduleActions, setModuleActions] = useState(modules);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(apiUrl + `/allrole/${id}`);
                console.log("the response from allrole", response.data[0])



                const rolenames = response.data[0].rolename
                const dashboard = response.data[0].dashboard
                console.log("the dashboard", dashboard);


                setRolename(rolenames)
                if (response && response.data) {
                    const { rolename, modules } = response.data[0];
                    console.log("modules", modules)
                    setRoleData({
                        rolename: rolename,
                        modules: modules.map(module => ({
                            id: module.id,
                            name: module.name,
                            actions: { ...module.actions }
                        })),
                    });
                    setModuleActions(
                        modules.map(module => ({
                            id: module.id,
                            actions: { ...module.actions }
                        })),
                    )


                }

            } catch (error) {
                console.error("Error fetching update data:", error);
            }
        };

        if (id) {
            fetchData();
            getpermission();

        }
        else {
            setModuleActions(
                modules.map(module => ({
                    id: module.id,
                    actions: { ...module.actions }
                }))
            )
        }
    }, [id]);



    const [permissionDatas, setpermissionDatas] = useState({


    })

    const [roleData, setRoleData] = useState({
        rolename: '',
        modules: modules.map(module => ({
            id: module.id,
            name: module.name,
            actions: {
                all: false,
                add: false,
                edit: false,
                delete: false,
                view: false,
                download: false
            }
        })),
    });

    const [admin_id, setAdminId] = useState("");
    const getpermission = async () => {
        try {
            const response = await axios.get(`${apiUrl}/privilages/${id}`);
            const privileges = response.data;
            setpermissionDatas(privileges);
            setAdminId(response.data[0].admin_id);
    
            // Update moduleActions based on privileges
            setModuleActions(prevModuleActions =>
                prevModuleActions.map(module => {
                    const modulePrivilege = privileges.find(p => p.page === module.name);
                    if (modulePrivilege) {
                        return {
                            ...module,
                            actions: {
                                all: modulePrivilege.all === 1,
                                add: modulePrivilege.add === 1,
                                edit: modulePrivilege.edit === 1,
                                delete: modulePrivilege.delete === 1,
                                view: modulePrivilege.view === 1,
                                download: modulePrivilege.download === 1
                            }
                        };
                    }
                    return module;
                })
            );
    
            // Correctly update roleData
            setRoleData(prevRoleData => ({
                ...prevRoleData,
                modules: prevRoleData.modules.map(module => {
                    const modulePrivilege = privileges.find(p => p.page === module.name);
                    if (modulePrivilege) {
                        return {
                            ...module,
                            actions: {
                                all: modulePrivilege.all === 1,
                                add: modulePrivilege.add === 1,
                                edit: modulePrivilege.edit === 1,
                                delete: modulePrivilege.delete === 1,
                                view: modulePrivilege.view === 1,
                                download: modulePrivilege.download === 1
                            }
                        };
                    }
                    return module;
                })
            }));
    
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    


    const navigate = useNavigate();



    console.log("the role data", roleData.modules)

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'rolename') {
            setRolename(value);
        }
    };

    
    console.log("Role data to submit  before:", roleData);

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("the inboxchnage", moduleActions)
        // const updateinput = await axios.put(`${apiUrl}/updatebox/${id}`, moduleActions)
        // console.log("the update", updateinput)
        // Debugging output



        console.log("API URL:", `${apiUrl}/role/${id || ''}`);

        try {


            const formdata = {
                ...roleData,
                admin_id: admin_id
            }

            let response;

            if (id) {
                // If an ID is present, update the role
                console.log("Updating role with ID:", id);
                response = await axios.put(`${apiUrl}/role/${id}`, formdata);
            } else {
                // If no ID, create a new role
                console.log("Creating new role");
                response = await axios.post(`${apiUrl}/role`, roleData);
            }

            // Handle successful response
            if (response) {
                // Reset form data
                setRoleData({
                    rolename: '',
                    modules: modules.map(module => ({
                        id: module.id,
                        name: module.name,
                        actions: {
                            all: false,
                            add: false,
                            edit: false,
                            delete: false,
                            view: false,
                            download: false
                        }
                    })),
                });

                // Redirect to the role list page
                navigate('/role');
            }

            // Log the server response for debugging
            console.log('Server response:', response.data);

        } catch (err) {
            // Log the error and provide feedback to the user
            console.error('Error during submit:', err);
            alert('An error occurred while processing your request. Please try again.');
        }
    };


    const handleCheckboxChange = (moduleId, action) => {
        setModuleActions(prevModuleActions =>
            prevModuleActions.map(module => {
                if (module.id === moduleId) {
                    if (action === 'all') {
                        // Update all actions in the module based on the 'All' checkbox
                        const updatedActions = Object.keys(module.actions).reduce((acc, key) => {
                            acc[key] = !module.actions.all;
                            return acc;
                        }, {});
                        return {
                            ...module,
                            actions: updatedActions
                        };
                    } else {
                        // Update individual action
                        return {
                            ...module,
                            actions: {
                                ...module.actions,
                                [action]: !module.actions[action]
                            }
                        };
                    }
                }
                return module;
            })

        );

        // Update roleData with selected module actions
        setRoleData(prevRoleData => ({
            ...prevRoleData,
            rolename : rolename,
            modules: prevRoleData.modules.map(module => {
                if (module.id === moduleId) {
                    if (action === 'all') {
                        const updatedActions = Object.keys(module.actions).reduce((acc, key) => {
                            acc[key] = !module.actions.all;
                            return acc;
                        }, {});
                        return {
                            ...module,
                            actions: updatedActions
                        };
                    } else {
                        return {
                            ...module,
                            actions: {
                                ...module.actions,
                                [action]: !module.actions[action]
                            }
                        };
                    }
                }
                return module;
            })
        }));
    };
    console.log("the hgdsfghdk", moduleActions)


    return (
        <>
            <div className="admin-roles-main">
                {
                    id ? <h1 className="text-center">Update Roles</h1> :
                        <h4 className="">Add Roles</h4>
                }

            </div>
            <div className="card p-3 mt-3" style={{ padding: '10px 10px', marginBottom: '20px' }}>
                <form onSubmit={handleSubmit}>
                    {/* <h3 style={{ fontWeight: 600 }}>Admin Roles</h3> */}

                    <div className="row mt-2">
                        <div className="form-label">
                            <b> Name:</b>

                        </div>
                        <div className="col-md-6">
                            <input
                                type="text"
                                id="role"
                                className="form-control"
                                placeholder="role name"
                                name="role"
                                value={rolename}
                                onChange={(e) => setRoleData({ ...roleData, rolename: e.target.value }, setRolename(e.target.value))}
                            />
                        </div>

                        <div className='mt-4'>
                            <div className="card-container table-responsive">
                                <table className=' admin-role-card'>
                                    <thead>
                                        <tr className='text-center'>
                                            <th colSpan="7">Privilages</th>
                                        </tr>
                                        <tr className='text-center'>
                                            <th>Module</th>
                                            <th>All</th>
                                            <th>Add</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                            <th>View</th>
                                            <th>Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {moduleActions.map((module, index) => (

                                            console.log("the console data", module.actions.all),

                                            <tr className='text-center' key={index}>
                                                <td className='role-data-name'>{modules.find(item => item.id === module.id).name}</td>
                                                <td className='role-data-all'><input type='checkbox' checked={module.actions.all} onChange={() => handleCheckboxChange(module.id, 'all')} /></td>
                                                <td className='role-data-add'><input type='checkbox' checked={module.actions.add} onChange={() => handleCheckboxChange(module.id, 'add')} /></td>
                                                <td className='role-data-edit'><input type='checkbox' checked={module.actions.edit} onChange={() => handleCheckboxChange(module.id, 'edit')} /></td>
                                                <td className='role-data-delete'><input type='checkbox' checked={module.actions.delete} onChange={() => handleCheckboxChange(module.id, 'delete')} /></td>
                                                <td className='role-data-view'><input type='checkbox' checked={module.actions.view} onChange={() => handleCheckboxChange(module.id, 'view')} /></td>
                                                <td className='role-data-download'><input type='checkbox' checked={module.actions.download} onChange={() => handleCheckboxChange(module.id, 'download')} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mt-3">
                        <button className="btn btn-secondary" onClick={() => navigate('/role')}>Back</button>
                        {id ? <button type="submit" className="btn btn-info mx-4">Update</button> :
                            <button type="submit" className="btn btn-info mx-4">Submit</button>
                        }
                    </div>
                </form>
            </div>
        </>
    )
}



export default Editrole;




