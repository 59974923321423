import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../assets/css/Login.css";
import apiUrl from '../../../environment/Api';
import { Await, useNavigate } from "react-router-dom";

function Stafflogin() {
    const [empid, setEmpid] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [responseDatas, setResponseDatas] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString()); // State for current date
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
    const navigate = useNavigate();


    useEffect(() => {
        const interval = setInterval(() => {
            const currentDateObj = new Date();
            setCurrentDate(currentDateObj.toLocaleDateString());
            setCurrentTime(currentDateObj.toLocaleTimeString());
        }, 1000);

        return () => clearInterval(interval);
    }, []);


    const fetchDatas = async () => {
        try {
            const localid = localStorage.getItem("id")
            const response = await axios.get(apiUrl + `/staff/${localid}`);
            console.log("Response from server:", response.data);
            setResponseDatas(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };



    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Get current date and time
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        // const formattedTime = currentDate.toTimeString().split(' ')[0];
        const loginhour = currentDate.getHours();
        const loginminute = currentDate.getMinutes();
        // const formattedTime = `${loginhour}:${loginminute}`;
        const formattedTime = `${loginhour}:${loginminute.toString().padStart(2, '0')}`;

        try {

            console.log("The user login function is called : ");

            // const response = await axios.post(apiUrl + "/stafflogin", {
            const response = await axios.post(apiUrl + "/stafflogin", {
                empid,
                password,
                date: formattedDate,
                login: formattedTime
            });
            console.log("RESponse", response)
            if (response) {
                if (response.status === 200) {

                }
            }
            if (response.status === 200) {
                if (response.data.message === "You have already logged in today.") {

                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("id", empid);
                    navigate(`/profile/${empid}`);
                }
                else {
                    const formData = {
                        empid: response.data.empid,
                        name: response.data.name,
                        date: formattedDate,
                        login: formattedTime,
                        lunchout: '',
                        lunchin: '',
                        logout: '',
                        workhour: ''

                    }
                    const logResponse = axios.post(apiUrl + "/attendance", formData, {
                    });

                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("id", empid);
                    setEmpid("");
                    setPassword("");
                    setErrorMessage("");
                    navigate(`/profile/${empid}`);
                }

            } else {
                console.log("Login failed");
            }
        }
        catch (error) {



            console.error("Login failed:", error.response?.data || error.message);
            setErrorMessage(error.response?.data?.message || "Invalid Employee ID or Password.");
            console.error("Login failed:", formattedDate, formattedTime);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
        <div className="row over">
        <div className="col-lg-12 col-md-12 col-sm-12 login_container">
    
                
                        <div className="card formcard">
                        <div className="icon_container">
                                <h3 className="text-center mt-3"><img src={require("../../../assets/img/scimitar_logo_slogan-removebg-preview.png")} alt="Company Logo" style={{ height: "100px"  }}></img></h3>
                      
                           <div className="text-center"> 
                                <p style={{ color: "black"}} ><b>Time:{currentTime}</b></p>
                            </div>
                            </div>
                            <form className="mx-3 mt-5" onSubmit={handleFormSubmit}>

                                <div className="mb-3">
                                    <label className="form-label" >Employee ID</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={empid}
                                        onChange={(e) => setEmpid(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" >Password</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {showPassword ? <i className="fa-regular fa-eye-slash"></i> : <i className="fa-regular fa-eye"></i>}
                                        </button>
                                    </div>
                                </div>
                                <div className="mb-2 mt-2 text-center">
                                    <button className="btn btn-dark sub_btn" type="submit">Submit</button>
                                </div>
                                {errorMessage && <p className="text-danger text-center mt-4">{errorMessage}</p>}
                            </form>
                   
               
                            </div>
            </div>
        </div>
           

              
           
        </>
    );
}

export default Stafflogin;
