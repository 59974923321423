import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../assets/css/Navbar.css'
// import navlogo from '../../assets/img/Admin/vas-logo1-nav2.png';

function Navbar({ toggle }) {
    const navigate = useNavigate()
    const navigatehandle = () => {
        navigate('/');
    }
    const navigatehandleinvoice = () => {
        navigate('/invoice');
    }
    const haldlenavigatedashboard = () => {
        navigate('/dashboard');
    }
    return (
        <div className='navborder'>
        <nav className={`${toggle ? 'toggle-active' : 'toggle-notactive'} navbar default-layout-navbar col-lg-12 p-0 fixed-top d-flex flex-row`}>
            <div className="text-center d-flex align-items-center justify-content-center">
                <div className=" brand-logo ">
                        {/* <img onClick={haldlenavigatedashboard} src={navlogo} alt="logo" /> */}
                        <div className='logonav d-flex justify-content-between'>
                        {/* <img src={require("../../src/assets/img/scimitar logo png.jpg")} alt="Company Logo" style={{ width: "150px", height:"40px", marginLeft : "50px",marginTop:"5px" }}></img> */}
                        <p className='navbarroutertag' onClick={navigatehandle} style={{color:'black',marginRight:"70px"}} ></p>
                        </div>
          
                </div>
            </div>  
            <div className="navbar-menu-wrapper d-flex align-items-stretch">
                
                <div className='mx-5 responsive-none'>
                    {/* <p className='navbarroutertag' onClick={navigatehandle}>Projects</p> */}
                </div>
                <div className='responsive-none'>
                    {/* <p className='navbarroutertag' onClick={navigatehandleinvoice}>
                       Invoice
                    </p> */}
                </div>
                <ul className="navbar-nav navbar-nav-right responsive-none" style={{height:'50px'}}>
                    {/* <li className="nav-item"> */}
                        <i className="fa-regular fa-circle-user usericon mx-2 "></i>
                        <b className='admin-username-nav' style={{color:'white'}}>admin</b>
                    {/* </li> */}
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggle}>
                
                </button>
            </div>
        </nav>
        </div>
    );
}

export default Navbar;
